// Roles...
const ROLE_TECH = 'Tech'

export const ROLES = {
  ROLE_TECH,
}

// Panel Types...
const PANELTYPE_NOPANEL = 'No Panel'
const PANELTYPE_SEMHONEYWELL_ADEMCOVISTA = 'SEM – HoneyWell/ADEMCO Vista'

export const PANELTYPES = {
  PANELTYPE_NOPANEL,
  PANELTYPE_SEMHONEYWELL_ADEMCOVISTA,
}

// User Types...
const USERTYPE_ADMIN = 'Admin'
const USERTYPE_TECH = 'Tech'
const USERTYPE_TECHMGR = 'Tech Manager'
const USERTYPE_ALL = 'All'

export const USERTYPES = {
  USERTYPE_ADMIN,
  USERTYPE_TECH,
  USERTYPE_TECHMGR,
  USERTYPE_ALL,
}

// Permissions...
const PERMISSION_MONITORINGCELLFORWARDING_CREATE = 'MonitoringCellForwardingManagement.Create'
const PERMISSION_MONITORINGCELLFORWARDING_READ = 'MonitoringCellForwardingManagement.Read'
const PERMISSION_MONITORINGCELLFORWARDING_UPDATE = 'MonitoringCellForwardingManagement.Update'

const PERMISSION_CUSTOMERDELETEDATTACHMENT_READ = 'CustomerDeletedAttachment.Read'

export const PERMISSIONS = {
  PERMISSION_MONITORINGCELLFORWARDING_CREATE,
  PERMISSION_MONITORINGCELLFORWARDING_READ,
  PERMISSION_MONITORINGCELLFORWARDING_UPDATE,
  PERMISSION_MONITORINGCELLFORWARDING_ALL: [
    PERMISSION_MONITORINGCELLFORWARDING_CREATE,
    PERMISSION_MONITORINGCELLFORWARDING_READ,
    PERMISSION_MONITORINGCELLFORWARDING_UPDATE,
  ],

  PERMISSION_CUSTOMERDELETEDATTACHMENT_READ
}

export const NOTIFICATIONSERVICETYPE = [
  'Has Access',
  'Burglary',
  'Bypass',
  'Carbon Monoxide',
  'Default',
  'Elevator',
  'Environment',
  'Fire',
  'Medical',
  'Music',
  'No Test Timer Received',
  'Open & Close',
  'Off Test',
  'Panic',
  'Restore/Cancel',
  'Tamper',
  'Test Timer',
  'Trouble',
  'Status',
  'Supervisory',
  'Video',
]

// Record Type IDs
export const RECORD_TYPE_ID = {
  CUSTOMERS: 1,
  USERS: 2,
}
