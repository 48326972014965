import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getAlarmComMonitoringAccount = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data = [] } = await HttpClient.get(`/AlarmComCustomerAccounts/${id}${buildQuery(odataParams)}`, { cancelToken })
  delete data['@odata.context']
  return data
}

export const createAlarmComMonitoringAccount = async (payload, { cancelToken } = {}) => {
  const { data } = await HttpClient.post(`/AlarmComCustomerAccounts`, payload, {
    cancelToken,
  })

  return data
}

export const updateAlarmComMonitoringAccount = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data = {} } = await HttpClient.patch(`/AlarmComCustomerAccounts/${id}${buildQuery(odataParams)}`, { cancelToken })
  delete data['@odata.context']
  return data
}

const AlarmComMonitoringAccountService = {
  createAlarmComMonitoringAccount,
  updateAlarmComMonitoringAccount,
  getAlarmComMonitoringAccount,
}

export default AlarmComMonitoringAccountService
