import { all, putResolve } from 'redux-saga/effects'
import { getMerchantsLogoThunk } from './slice'

export function* GET_DATA() {
  yield putResolve(getMerchantsLogoThunk())
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
