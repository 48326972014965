import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { IsNullOrUndefined } from './utils'

export async function getRole(payload, { cancelToken } = {}) {
  const { id, odataParams } = payload
  const { status, data = [] } = await HttpClient.get(`/roles/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  if (status !== 200) return Promise.reject(data)
  return data
}

export async function getRoles(payload, { cancelToken } = {}) {
  const { status, data = [] } = await HttpClient.get(`/roles${buildQuery(payload)}`, {
    cancelToken,
  })
  if (status !== 200) return Promise.reject(data)
  return data
}

export async function createRole(payload, { cancelToken } = {}) {
  const { values, odataParams } = payload
  const { status, data = [] } = await HttpClient.post(`/roles${buildQuery(odataParams)}`, values, {
    cancelToken,
  })
  if (status !== 201) return Promise.reject(data)
  return data
}

export async function updateRole(payload, { cancelToken } = {}) {
  const { id, values, odataParams } = payload
  const { status, data = [] } = await HttpClient.patch(
    `/roles/${id}/${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  if (status !== 200) return Promise.reject(data)
  return data
}

export async function isUniqeRoleName(payload, { cancelToken } = {}) {
  const { name } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/roles${buildQuery({
      filter: {
        name,
      },
    })}`,
    { cancelToken },
  )
  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

export async function getPermissions({ odataParams }, { cancelToken } = {}) {
  const { status, data = [] } = await HttpClient.get(`/permissions${buildQuery(odataParams)}`, {
    cancelToken,
  })
  if (status !== 200) return Promise.reject(data)
  return data
}

const RoleService = {
  getRoles,
  getRole,
  createRole,
  updateRole,
  isUniqeRoleName,
  getPermissions,
}

export default RoleService
