import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import MonitoringService from 'services/monitoring'
import { withThunkApi } from 'services/axios.utils'

export const getMonitoringProvidersThunk = createAsyncThunk(
  'hive/monitoringProviders/list',
  withThunkApi(MonitoringService.fetchMonitoringProviders),
)

const monitoringProviderAdapter = createEntityAdapter()

const monitoringProviderSlice = createSlice({
  name: 'hive/monitoringProviders',
  initialState: monitoringProviderAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [getMonitoringProvidersThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },

    [getMonitoringProvidersThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        monitoringProviderAdapter.setAll(state, action.payload)
      }
    },

    [getMonitoringProvidersThunk.rejected]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
  },
})

const selectMonitoringProviderByTypeId = createSelector(
  (state) => state.monitoringProviders,
  (_, typeId) => typeId,
  (state, typeId) => {
    if (!typeId) return undefined
    const monitoringProviders = monitoringProviderSelectors.selectAll(state)
    return monitoringProviders.filter((item) => item.typeId === typeId)
  },
)

export const monitoringProviderSelectors = {
  ...monitoringProviderAdapter.getSelectors(),
  selectMonitoringProviderByTypeId,
}

export default monitoringProviderSlice.reducer
