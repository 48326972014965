import WorkflowInstanceProducer from '.'

const workorderFlowSlice = new WorkflowInstanceProducer('hive/workorder-flow')

export const {
  createWorkflowInstanceThunk: createWorkOrderInstanceThunk,
  getWorkflowInstanceThunk: getWorkOrderInstanceThunk,
  workflowInstancesSelectors: workOrderInstancesSelectors,
} = workorderFlowSlice

export default workorderFlowSlice.reducer
