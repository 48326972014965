import actions from './actions'

const initialState = {
  items: [],
  listLoader: {
    loading: false,
    fetched: false,
  },
  page: {
    PageSize: 10,
    CurrentPage: 1,
  },

  item: {},
  added: false,
  updated: false,
  userLoader: {
    loading: false,
    fetched: false,
  },

  userTypes: [],
  typesLoader: {
    loading: false,
    fetched: false,
  },
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
