import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { mergeActionTypes } from 'services/utils'
import {
  getPaymentProfileByCustomerId,
  createPaymentProfile,
  updatePaymentProfile,
  deletePaymentProfile,
} from 'services/paymentProfile'
import { withThunkApi } from 'services/axios.utils'

export const fetchCustomerPaymentProfileThunk = createAsyncThunk(
  'hive/customer/paymentProfile/list',
  withThunkApi(getPaymentProfileByCustomerId),
)

export const createPaymentProfileThunk = createAsyncThunk(
  'hive/customer/paymentProfile/create',
  withThunkApi(createPaymentProfile),
)

export const updatePaymentProfileThunk = createAsyncThunk(
  'hive/customer/paymentProfile/update',
  withThunkApi(updatePaymentProfile),
)

export const deletePaymentProfileThunk = createAsyncThunk(
  'hive/customer/paymentProfile/delete',
  withThunkApi(deletePaymentProfile),
)

const paymentProfileAdapter = createEntityAdapter()

const paymentProfileSlice = createSlice({
  name: 'hive/paymentProfiles',
  initialState: paymentProfileAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchCustomerPaymentProfileThunk.pending,
        createPaymentProfileThunk.pending,
        updatePaymentProfileThunk.pending,
        deletePaymentProfileThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        fetchCustomerPaymentProfileThunk.rejected,
        createPaymentProfileThunk.rejected,
        updatePaymentProfileThunk.rejected,
        deletePaymentProfileThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),

    [fetchCustomerPaymentProfileThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        paymentProfileAdapter.setAll(state, action.payload)
      }
    },

    ...mergeActionTypes(
      [createPaymentProfileThunk.fulfilled, updatePaymentProfileThunk.fulfilled],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          paymentProfileAdapter.upsertOne(state, action.payload)
        }
      },
    ),

    [deletePaymentProfileThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        paymentProfileAdapter.removeOne(state, action.meta.arg)
      }
    },
  },
})

export const paymentProfileSelectors = paymentProfileAdapter.getSelectors()

export default paymentProfileSlice.reducer
