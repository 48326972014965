import HttpClient from 'services/api'
import buildQuery from 'odata-query'

export const getInvoices = async ({ odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`invoices${buildQuery(odataParams)}`, { cancelToken })
  delete data['@odata.context']
  return data
}

export const getInvoiceById = async ({ id, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`invoices/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}
export const getInvoiceByCustomerId = async ({ customerId, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `customers/${customerId}/invoices${buildQuery(odataParams)}`,
    { cancelToken },
  )
  return data.value
}

export const createInvoice = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`invoices`, payload, { cancelToken })
  delete data['@odata.context']
  return data
}

export const updateInvoice = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`invoices/${payload.id}`, payload, { cancelToken })
  delete data['@odata.context']
  return data
}

export const createRecurringBillingInvoice = async ({ recurringBillingId }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`RecurringBillings/${recurringBillingId}/invoices`, null, { cancelToken })
  delete data['@odata.context']
  return data
}
