import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import MergeFieldService from 'services/mergeField'

export const getMergeFieldsThunk = createAsyncThunk(
  'hive/mergeField/list',
  withThunkApi(MergeFieldService.getMergeFields),
)

const mergeFieldAdapter = createEntityAdapter()

const mergeFieldSlice = createSlice({
  name: 'hive/mergeFields',
  initialState: mergeFieldAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupMergeFields: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    [getMergeFieldsThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getMergeFieldsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [getMergeFieldsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      state.entities = action.payload.value
    },
  },
})

export const { cleanupMergeFields } = mergeFieldSlice.actions
export const mergeFieldSelectors = mergeFieldAdapter.getSelectors()

export default mergeFieldSlice.reducer
