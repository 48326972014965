import React, { useContext, useEffect, useState } from 'react'
import { Card, List, Tag, Typography } from 'antd'

import { useRequest } from 'ahooks'
import { useAxiosCancellation } from 'hooks'

import SearchService from 'services/search'
import SearchContext from './SearchContext'

import styles from './style.module.scss'

const { Title } = Typography

export default function SimpleSearchResult(props) {
  const { title, data = {}, renderItem = () => {}, searchIndexName = '*' } = props

  const [dataSource, setDataSource] = useState(data[searchIndexName]?.results ?? [])
  const [meta, setMeta] = useState(data[searchIndexName]?.meta ?? { page: {} })

  const { searchText } = useContext(SearchContext)
  const { cancelToken } = useAxiosCancellation()

  useEffect(() => {
    setDataSource(data[searchIndexName]?.results ?? [])
    setMeta(data[searchIndexName]?.meta ?? { page: {} })
  }, [(data[searchIndexName]?.results ?? []).map(({ id = '' }) => id.raw).join('_')])

  const { loading, run: search } = useRequest((q) => SearchService.search(q, { cancelToken }), {
    manual: true,
    onSuccess: (res) => {
      setDataSource(res[searchIndexName]?.results)
      setMeta(res[searchIndexName]?.meta)
    },
    onError: (err) => {
      console.error(err)
    },
  })

  const onPageClick = (current) => {
    search({
      index: searchIndexName,
      query: searchText,
      page: {
        current,
      },
    })
  }

  if (!dataSource.length) return null

  const { current, total_results: total, size: pageSize, total_pages: totalPages } = meta.page

  return (
    <div>
      <Title level={3}>{title}</Title>
      <List
        grid={{ column: 1 }}
        loading={loading}
        pagination={
          totalPages === 1
            ? null
            : {
                current,
                total,
                pageSize,
                size: 'small',
                showSizeChanger: false,
                onChange: onPageClick,
              }
        }
        style={{ marginBottom: 40 }}
        dataSource={dataSource}
        renderItem={(item) => {
          const { _meta: m } = item
          return (
            <List.Item>
              <Card size="small">
                <div key={item.id.raw} className={styles.resultContent}>
                  <div style={{ float: 'right' }}>
                    <Tag>Relevance score: {Number(m.score).toFixed(3)}</Tag>
                  </div>
                  <div className={styles.result}>{renderItem(item)}</div>
                </div>
              </Card>
            </List.Item>
          )
        }}
      />
    </div>
  )
}
