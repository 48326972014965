import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Layout, Button } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

export default function MenuLeft() {
  const dispatch = useDispatch()

  const menuData = useSelector((state) => state.menu.menuLeftData)
  const isLightTheme = useSelector((state) => state.settings.isLightTheme)
  const isSettingsOpen = useSelector((state) => state.settings.isSettingsOpen)
  const isMenuCollapsed = useSelector((state) => state.settings.isMenuCollapsed)
  const isMobileView = useSelector((state) => state.settings.isMobileView)
  const logoSrc = useSelector(
    (state) => state.merchant.entity.logoUrl ?? 'resources/images/logo-hive.png',
  )
  const location = useLocation()

  const [selectedKeys, setSelectedKey$] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKey$] = useState(store.get('app.menu.openedKeys') || [])

  const setSelectedKeys = useCallback(() => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), ['url', location.pathname])
    setSelectedKey$(selectedItem ? [selectedItem.key] : [])
  }, [menuData])

  useEffect(() => {
    if (isMenuCollapsed && isMobileView) {
      setOpenedKey$([])
    }
    setSelectedKeys()

    return () => {
      setSelectedKeys()
    }
  }, [menuData, isMenuCollapsed, isMobileView])

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    setOpenedKey$([])
  }

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKey$(keys)
  }

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    setSelectedKey$([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, disabled } = item
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {/* {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />} */}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url}>
                {/* {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />} */}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {/* {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />} */}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {/* {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />} */}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {/* {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />} */}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: 256,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: 256,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  const menu = generateMenuItems()

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  return (
    <Sider
      {...menuSettings}
      className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      theme="light"
    >
      <div className={styles.logo}>
        <div className={styles.logoContainer}>
          <Link to="/dashboard">
            <img src={logoSrc} alt="logo" />
          </Link>
        </div>
      </div>
      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <Menu
          theme={isLightTheme ? 'light' : 'dark'}
          onClick={handleClick}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          className={styles.navigation}
        >
          {menu}
          <Menu.Divider />

          <Menu.Item key="profile">
            <Link to="/profile">
              <FormattedMessage id="topBar.profileMenu.editProfile" />
            </Link>
          </Menu.Item>
          <Menu.Item key="logout">
            <Button type="text" onClick={logout} style={{ padding: 0 }}>
              <FormattedMessage id="topBar.profileMenu.logout" />
            </Button>
          </Menu.Item>
        </Menu>
      </Scrollbars>
    </Sider>
  )
}
