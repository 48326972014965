import React from 'react'
import { BackTop, Layout } from 'antd'

import classNames from 'classnames'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import { useSelector } from 'react-redux'

export default function MainLayout(props) {
  const { children } = props

  const isBorderless = useSelector((state) => state.settings.isBorderless)
  const isSquaredBorders = useSelector((state) => state.settings.isSquaredBorders)
  const isFixedWidth = useSelector((state) => state.settings.isFixedWidth)
  const isMenuShadow = useSelector((state) => state.settings.isMenuShadow)
  const isMenuTop = useSelector((state) => state.settings.isMenuTop)

  return (
    <Layout
      style={{ minHeight: '100%' }}
      className={classNames({
        settings__borderLess: isBorderless,
        settings__squaredBorders: isSquaredBorders,
        settings__fixedWidth: isFixedWidth,
        settings__menuShadow: isMenuShadow,
        settings__menuTop: isMenuTop,
      })}
    >
      <BackTop />
      <Menu />
      <Layout>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className="p-11">{children}</div>
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}
