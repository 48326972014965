import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const fetchAppointmentTimeSlots = async (payload, { cancelToken } = {}) =>
  HttpClient.get('timeSlots', { cancelToken })

const fetchAppointments = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`appointments${buildQuery(payload)}`, { cancelToken })
  return data
}

const fetchAppointment = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data = {} } = await HttpClient.get(`appointments/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data
}

const fetchWorkOrderAppointments = async (
  { workOrderId, odataParams = {} },
  { cancelToken } = {},
) => {
  const { data = {} } = await HttpClient.get(
    `/workorders/${workOrderId}/appointments${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data
}

const fetchWorkOrderAppointment = async (
  { workOrderId, id, odataParams = {} },
  { cancelToken } = {},
) => {
  const { data = {} } = await HttpClient.get(
    `/workorders/${workOrderId}/appointments/${id}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data
}

const fetchAppointmentStatuses = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/appointmentStatuses`, {
    cancelToken,
  })
  return data.value
}

const fetchWorkOrderAppointmentHistory = async (
  { workOrderId, id, odataParams = {} },
  { cancelToken } = {},
) => {
  const { data = {} } = await HttpClient.get(
    `/workorders/${workOrderId}/appointments/${id}/history${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return { id, history: data.value }
}

const createAppointment = async (payload, { cancelToken } = {}) => {
  const { workOrderId, startTime, ...values } = payload

  const { data = {} } = await HttpClient.post(`/workorders/${workOrderId}/appointments`, values, {
    cancelToken,
  })
  return { ...data, technician: payload.technician }
}

const updateAppointment = async (payload, { cancelToken } = {}) => {
  delete payload.startTime
  const { data = {} } = await HttpClient.patch(
    `/workorders/${payload?.workOrderId}/appointments/${payload?.id}`,
    payload,
    {
      cancelToken,
    },
  )
  return data
}

const deleteAppointment = async ({ id, workOrderId }, { cancelToken } = {}) => {
  await HttpClient.delete(`/workorders/${workOrderId}/appointments/${id}`, {
    cancelToken,
  })
  return id
}

const AppointmentService = {
  fetchAppointment,
  fetchAppointments,
  fetchWorkOrderAppointments,
  fetchWorkOrderAppointment,
  fetchAppointmentStatuses,
  fetchAppointmentTimeSlots,
  fetchWorkOrderAppointmentHistory,
  createAppointment,
  updateAppointment,
  deleteAppointment,
}

export default AppointmentService
