import HttpClient from 'services/api'
import { MONITORING_PROVIDER } from 'services/monitoring'

const getTimeZones = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AVANTGUARD}/actions/getTimeZones/execution`,
    payload,
    {
      cancelToken,
    },
  )
  return data
}

const getDispatchTypes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AVANTGUARD}/actions/getDispatchTypes/execution`,
    payload,
    {
      cancelToken,
    },
  )
  return data
}

const getAvailableAccountIDs = async (accountId, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AVANTGUARD}/actions/getSiteGroupNextXmit/execution?centralStationId=${accountId}`,
    {},
    { cancelToken },
  )

  return data
}

const getEventCodes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AVANTGUARD}/actions/getEventCodes/execution`,
    payload,
    {
      cancelToken,
    },
  )
  return data
}

const syncDeviceTypes = async ({ id, credentialsId }, { cancelToken } = {}) => {
  const params = `?credentialsId=${credentialsId}&centralStationId=${id}`
  const { data = {} } = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AVANTGUARD}/actions/importDeviceTypes/execution${params}`,
    {},
    { cancelToken },
  )
  return data
}

const setAgencies = async ({ credentialsId, customerId, addressId }, { cancelToken } = {}) => {
  const params = `?credentialsId=${credentialsId}&customerId=${customerId}&addressId=${addressId}`
  const data = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AVANTGUARD}/actions/importAgencies/execution${params}`,
    {},
    { cancelToken },
  )
  return data
}

const putOnTest = async (payload, { cancelToken } = {}) => {
  const { providerId } = payload
  const data = await HttpClient.post(
    `/providers/${providerId}/actions/putOnTest/execution`,
    payload,
    { cancelToken },
  )
  return data
}

const updateZones = async (
  { providerId, credentialsId, customerId, addressId },
  { cancelToken } = {},
) => {
  const params = `?credentialsId=${credentialsId}&customerId=${customerId}&addressId=${addressId}`

  const data = await HttpClient.post(
    `/providers/${providerId}/actions/updateZones/execution${params}`,
    {
      cancelToken,
      headers: { providerId },
    },
  )

  return data
}

const updateEmergencyContacts = async (
  { providerId, credentialsId, customerId, addressId },
  { cancelToken } = {},
) => {
  const params = `?credentialsId=${credentialsId}&customerId=${customerId}&addressId=${addressId}`
  const data = await HttpClient.post(
    `/providers/${providerId}/actions/updateContacts/execution${params}`,
    { cancelToken },
  )
  return data
}

const activateAccount = async ({ customerId, addressId }, { cancelToken } = {}) => {
  const params = `?customerId=${customerId}&addressId=${addressId}`
  const { data = {} } = await HttpClient.post(
    `/providers/${MONITORING_PROVIDER.AFFILIATED}/actions/activateAccount/execution${params}`,
    {},
    {
      cancelToken,
    },
  )
  return data
}

const importAccount = async (payload, { cancelToken } = {}) => {
  const { providerId } = payload
  const { data = {} } = await HttpClient.post(
    `/providers/${providerId}/actions/importAccount/execution`,
    payload,
    {
      cancelToken,
    },
  )
  return data
}

const MonitoringAccountActionsService = {
  getTimeZones,
  getDispatchTypes,
  syncDeviceTypes,
  getAvailableAccountIDs,
  setAgencies,
  putOnTest,
  getEventCodes,
  updateZones,
  updateEmergencyContacts,
  activateAccount,
  importAccount,
}

export default MonitoringAccountActionsService
