import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import get from 'get-value'
import { getWorkflowSchemaRender } from 'services/workflowSchemeRender'
import { withThunkApi } from 'services/axios.utils'

export const getWorkflowSchemaRenderThunk = createAsyncThunk(
  'hive/workflowSchemasRender',
  withThunkApi(getWorkflowSchemaRender),
)

const workflowStateRenderAdapter = createEntityAdapter({ selectId: (state) => state.stateName })

const workflowSchemaRenderSlice = createSlice({
  name: 'hive/workflowSchemasRender',
  initialState: workflowStateRenderAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [getWorkflowSchemaRenderThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getWorkflowSchemaRenderThunk.fulfilled]: (state, action) => {
      workflowStateRenderAdapter.addOne(state, action.payload)
      state.loading = 'idle'
    },
    [getWorkflowSchemaRenderThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    },
  },
})

const getWorkflowMapping = createSelector(
  (state) => state.workflowSchemas.entity,
  (state) => state.workflowSchemeRender,
  (ws, r) =>
    get(ws, 'statesIds', []).map((state) => ({
      name: state,
      components: get(workflowStateRenderSelectors.selectById(r, state), 'components', []),
    })),
)

export const workflowStateRenderSelectors = {
  getWorkflowMapping,
  ...workflowStateRenderAdapter.getSelectors(),
}

export default workflowSchemaRenderSlice.reducer
