import axios from 'axios'
import { authHeaderRequestInterceptor } from './interceptors'

const WorkflowHttpClient = axios.create({
  baseURL: process.env.REACT_APP_WF_URL,
})

WorkflowHttpClient.interceptors.request.use(authHeaderRequestInterceptor)

export default WorkflowHttpClient
