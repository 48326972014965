import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { mergeActionTypes } from 'services/utils'
import MonitoringStationsService from 'services/monitoringStations'
import { withThunkApi } from 'services/axios.utils'

export const getMonitoringStationsThunk = createAsyncThunk(
  'hive/monitoring/monitoringStations/list',
  withThunkApi(MonitoringStationsService.fetchMonitoringStations),
)

export const getMonitoringStationThunk = createAsyncThunk(
  'hive/monitoring/monitoringStations/get',
  withThunkApi(MonitoringStationsService.fetchMonitoringStation),
)

export const createMonitoringStationThunk = createAsyncThunk(
  'hive/monitoring/monitoringStations/create',
  withThunkApi(MonitoringStationsService.createMonitoringStation),
)

export const updateMonitoringStationThunk = createAsyncThunk(
  'hive/monitoring/monitoringStations/update',
  withThunkApi(MonitoringStationsService.updateMonitoringStation),
)

const monitoringStationAdapter = createEntityAdapter()

const monitoringStationSlice = createSlice({
  name: 'hive/monitoringStations',
  initialState: monitoringStationAdapter.getInitialState({
    loading: 'idle',
    total: 0,
  }),
  reducers: {
    setMonitoringStationInitialState: (state) => {
      monitoringStationAdapter.removeAll(state)
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getMonitoringStationsThunk.pending,
        getMonitoringStationThunk.pending,
        createMonitoringStationThunk.pending,
        updateMonitoringStationThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),

    [getMonitoringStationsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.total = action.payload['@odata.count']
        monitoringStationAdapter.upsertMany(state, action.payload.value)
      }
    },

    [getMonitoringStationThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        monitoringStationAdapter.upsertOne(state, action.payload)
      }
    },

    [createMonitoringStationThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        monitoringStationAdapter.addOne(state, action.payload)
      }
    },

    [updateMonitoringStationThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        monitoringStationAdapter.updateOne(state, action.payload)
      }
    },

    ...mergeActionTypes(
      [
        getMonitoringStationsThunk.rejected,
        getMonitoringStationThunk.rejected,
        createMonitoringStationThunk.rejected,
        updateMonitoringStationThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
  },
})

export const monitoringStationSelectors = monitoringStationAdapter.getSelectors()
export const { setMonitoringStationInitialState } = monitoringStationSlice.actions

export default monitoringStationSlice.reducer
