import { all, put, takeLatest, putResolve } from 'redux-saga/effects'

import get from 'get-value'

import { getWorkflowInstanceRenderThunk } from 'redux/workflowInstanceRender/default'
import { getWordOrderInstanceRenderThunk } from 'redux/workflowInstanceRender/workorder'

import { getWorkOrderInstanceThunk } from './workorder'
import { getWorkflowInstanceThunk, executeCommandThunk } from './default'

export const INSTANCE_TYPE = {
  CUSTOMER: 'customer',
  WORKORDER: 'workorder',
}

function* updateInstanceRender(action) {
  const {
    meta: { arg = {} },
    payload: response,
  } = action

  const { instanceId, instanceType } = arg

  switch (instanceType) {
    case INSTANCE_TYPE.CUSTOMER: {
      yield updateWorkflowInstanceRender(instanceId)
      break
    }
    case INSTANCE_TYPE.WORKORDER: {
      yield updateWorkOrderInstanceRender(instanceId)
      yield updateWorkflowInstanceRender(response?.parentInstanceId)
      break
    }
    default: {
      console.warn('updateInstanceRender: missing or wrong instanceType parameter')
    }
  }
}

function* updateWorkOrderInstanceRender(instanceId) {
  if (instanceId) {
    const { payload } = yield putResolve(getWorkOrderInstanceThunk(instanceId))

    if (payload) {
      yield put(
        getWordOrderInstanceRenderThunk({
          instanceId,
          state: get(payload, 'currentState', {}),
        }),
      )
    }
  }
}

function* updateWorkflowInstanceRender(instanceId) {
  if (instanceId) {
    const { payload } = yield putResolve(getWorkflowInstanceThunk(instanceId))

    if (payload) {
      yield put(
        getWorkflowInstanceRenderThunk({
          instanceId,
        }),
      )
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(executeCommandThunk.fulfilled, updateInstanceRender)])
}
