import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import alarmComMonitoringAccountService from 'services/alarmComMonitoringAccount'

import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getAlarmComMonitoringAccountThunk = createAsyncThunk(
  'hive/alarmComMonitoringAccount/get',
  withThunkApi(alarmComMonitoringAccountService.getAlarmComMonitoringAccount),
)

export const createAlarmComMonitoringAccountThunk = createAsyncThunk(
  'hive/alarmComMonitoringAccount/create',
  withThunkApi(alarmComMonitoringAccountService.createAlarmComMonitoringAccount),
)

export const updateAlarmComMonitoringAccountThunk = createAsyncThunk(
  'hive/alarmComMonitoringAccount/update',
  withThunkApi(alarmComMonitoringAccountService.updateAlarmComMonitoringAccount),
)

const alarmComMonitoringAccountAdapter = createEntityAdapter()

const alarmComMonitoringAccountSlice = createSlice({
  name: 'hive/alarmComMonitoringAccount',
  initialState: alarmComMonitoringAccountAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes([
      getAlarmComMonitoringAccountThunk.pending,
      createAlarmComMonitoringAccountThunk.pending,
      updateAlarmComMonitoringAccountThunk.pending
    ], (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    }),
    ...mergeActionTypes([
      getAlarmComMonitoringAccountThunk.rejected,
      createAlarmComMonitoringAccountThunk.rejected,
      updateAlarmComMonitoringAccountThunk.rejected
    ], (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    }),
    [getAlarmComMonitoringAccountThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        alarmComMonitoringAccountAdapter.addOne(state, action.payload)
      }
    },
    ...mergeActionTypes([
      createAlarmComMonitoringAccountThunk.fulfilled,
      updateAlarmComMonitoringAccountThunk.fulfilled
    ], (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
        alarmComMonitoringAccountAdapter.upsertOne(state, action.payload)
    })
  },
})

export const { cleanupLatest } = alarmComMonitoringAccountSlice.actions
export const alarmComMonitoringAccountSelectors = alarmComMonitoringAccountAdapter.getSelectors()

export default alarmComMonitoringAccountSlice.reducer
