import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import WorkOrderService from 'services/workorder'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'
import { updateMonitoringStationThunk } from 'redux/monitoringStations/slice'

export const createWorkOrderStatusThunk = createAsyncThunk(
  'hive/workOrders/status/create',
  withThunkApi(WorkOrderService.createStatus),
)

export const updateWorkOrderStatusThunk = createAsyncThunk(
  'hive/workOrders/status/update',
  withThunkApi(WorkOrderService.updateStatus),
)

export const getWorkOrderStatusThunk = createAsyncThunk(
  'hive/workOrders/status/byid',
  withThunkApi(WorkOrderService.getStatus),
)

export const getWorkOrderStatusListThunk = createAsyncThunk(
  'hive/workOrders/status/list',
  withThunkApi(WorkOrderService.getAll),
)

const workOrderStatusAdapter = createEntityAdapter()

const workOrderStatusSlice = createSlice({
  name: 'hive/workOrders/status',
  initialState: workOrderStatusAdapter.getInitialState({
    loading: 'idle',
    error: false,
    latest: null,
  }),
  reducers: {
    cleanUpStatus: (state) => {
      state.latest = null
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        createWorkOrderStatusThunk.pending,
        updateMonitoringStationThunk.pending,
        getWorkOrderStatusThunk.pending,
        getWorkOrderStatusListThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    [createWorkOrderStatusThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.latest = action.payload
        workOrderStatusAdapter.addOne(state, action.payload)
      }
    },
    [updateWorkOrderStatusThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workOrderStatusAdapter.upsertOne(state, action.payload)
      }
    },
    [getWorkOrderStatusListThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workOrderStatusAdapter.setAll(state, action.payload)
      }
    },
    [getWorkOrderStatusThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workOrderStatusAdapter.upsertOne(state, action.payload)
      }
    },

    ...mergeActionTypes(
      [
        createWorkOrderStatusThunk.rejected,
        updateMonitoringStationThunk.rejected,
        getWorkOrderStatusThunk.rejected,
        getWorkOrderStatusListThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
  },
})

export const workOrderStatusSelectors = workOrderStatusAdapter.getSelectors()

export const workOrderStatusActions = workOrderStatusSlice.actions

export default workOrderStatusSlice.reducer
