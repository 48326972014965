import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getCustomerNotes = async ({ customerId, odataParams } = {}, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(
    `/customers/${customerId}/notes${buildQuery(odataParams)}`,
    { cancelToken },
  )
  delete data['@odata.context']
  return data.value
}

const getCustomerNoteById = async ({ customerId, id }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`/customers/${customerId}/notes/${id}`, {
    cancelToken,
  })
  delete data['@odata.context']
  return data.value
}

const createCustomerNote = async ({ customerId, values, odataParams }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.post(
    `/customers/${customerId}/notes${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

const updateCustomerNote = async (
  { customerId, id, values, odataParams },
  { cancelToken } = {},
) => {
  const { data = [] } = await HttpClient.patch(
    `/customers/${customerId}/notes/${id}${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

const deleteCustomerNote = async ({ customerId, id }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.delete(`/customers/${customerId}/notes/${id}`, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}

const CustomerNotesService = {
  getCustomerNotes,
  getCustomerNoteById,
  createCustomerNote,
  updateCustomerNote,
  deleteCustomerNote,
}

export default CustomerNotesService
