import { all } from 'redux-saga/effects'
import app from './app/sagas'
import user from './user/sagas'
import users from './users/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import address from './address/sagas'
import locations from './location/sagas'
import roles from './roles/sagas'

import distributors from './distributor/sagas'
import receivedEquimpents from './receivedEquimpent/sagas'
import stockitems from './stockitem/sagas'
import contacts from './contact/sagas'
import layoutTemplates from './layoutTemplate/sagas'
import workflowSchemas from './workflowSchema/sagas'
import workflowInstances from './workflowInstance/sagas'
import workflowInstanceRender from './workflowInstanceRender/sagas'
import workorders from './workorders/sagas'
import quote from './quote/sagas'
import merchant from './merchant/sagas'

// @plop-sagas-import

export default function* rootSaga() {
  yield all([
    merchant(),
    app(),
    user(),
    users(),
    menu(),
    settings(),
    address(),
    locations(),
    roles(),
    distributors(),
    receivedEquimpents(),
    stockitems(),
    contacts(),
    layoutTemplates(),
    workflowSchemas(),
    workflowInstances(),
    workflowInstanceRender(),
    workorders(),
    quote(),
    // @plop-sagas-combine
  ])
}
