import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { mergeActionTypes } from 'services/utils'
import {
  getRecurringBillings,
  getRecurringBillingById,
  createRecurringBilling,
  updateRecurringBilling,
} from 'services/recurringPayments'

import { withThunkApi } from 'services/axios.utils'

export const fetchRecurringBillingsThunk = createAsyncThunk(
  'hive/recurringBilling/list',
  withThunkApi(getRecurringBillings),
)

export const fetchRecurringBillingsByIdThunk = createAsyncThunk(
  'hive/recurringBilling/byId',
  withThunkApi(getRecurringBillingById),
)

export const createRecurringBillingThunk = createAsyncThunk(
  'hive/recurringBilling/create',
  withThunkApi(createRecurringBilling),
)

export const updateRecurringBillingThunk = createAsyncThunk(
  'hive/recurringBilling/create',
  withThunkApi(updateRecurringBilling),
)

const recurringBillingAdapter = createEntityAdapter({
  sortComparer: (p, n) => n.id - p.id,
})

const recurringBillingsSlice = createSlice({
  name: 'hive/recurringBillings',
  initialState: recurringBillingAdapter.getInitialState({
    loading: 'idle',
    loadStatus: {},
  }),
  extraReducers: {
    [fetchRecurringBillingsThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },

    [createRecurringBillingThunk.pending]: (state, action) => {
      const { invoiceId } = action.meta.arg
      state.loadStatus = { ...state.loadStatus, [invoiceId]: true }
    },

    [fetchRecurringBillingsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },

    ...mergeActionTypes(
      [createRecurringBillingThunk.rejected, createRecurringBillingThunk.fulfilled],
      (state, action) => {
        const { invoiceId } = action.meta.arg
        delete state.loadStatus[invoiceId]
      },
    ),

    [fetchRecurringBillingsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        recurringBillingAdapter.setAll(state, action.payload)
      }
    },
  },
})

export const recurringBillingSelectors = recurringBillingAdapter.getSelectors()

export default recurringBillingsSlice.reducer
