import axios from 'axios'
import { authHeaderRequestInterceptor } from './interceptors'

const SearchHttpClient = axios.create({
  baseURL: process.env.REACT_APP_SEARCH_URL,
})

SearchHttpClient.interceptors.request.use(authHeaderRequestInterceptor)

export default SearchHttpClient
