import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { USERTYPES } from '../constants'

export const getTechPersons = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/users${buildQuery({
      ...payload,
      filter: {
        ...payload.filter,
        userTypes: {
          any: "usertypes eq 'Tech'",
        },
      },
    })}`,
    { cancelToken },
  )
  return data.value
}

export default getTechPersons


export const getTechPersonsAlarmCom = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/users${buildQuery({
      ...payload,
      filter: {
        ...payload.filter,
        userTypes: {
          any: `usertypes in ['${USERTYPES.USERTYPE_ADMIN}', '${USERTYPES.USERTYPE_TECH}', '${USERTYPES.USERTYPE_TECHMGR.replace(/ /g, '')}']`,
        },
      },
    })}`,
    { cancelToken },
  )
  return data.value
}
