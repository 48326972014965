import WorkflowInstanceProducer from '.'

const customerFlowSlice = new WorkflowInstanceProducer('hive/customer-flow')

export const {
  createWorkflowInstanceThunk,
  getWorkflowInstanceThunk,
  executeCommandThunk,
  workflowInstancesSelectors,
} = customerFlowSlice

export default customerFlowSlice.reducer
