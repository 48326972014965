import { isNull } from 'lodash'
import PaymentHttpClient from 'services/api/payment'
import { imageUrlToBase64, IsNullOrUndefined } from './utils'

export const getMerchant = async (payload, { cancelToken } = {}) => {
  const {
    data: { logoUrl, ...values },
  } = await PaymentHttpClient.get(`/merchants?tenantName=${payload}`, { cancelToken })

  if (!isNull(logoUrl)) {
    values.logoUrl = logoUrl
  }

  return values
}

export const getMerchantLogo = async (payload, { cancelToken } = {}) => {
  const {
    data: { logoUrl, ...values },
  } = await PaymentHttpClient.get(`/merchants/logo`, { cancelToken })

  if (!isNull(logoUrl)) {
    values.logoUrl = logoUrl
  }

  return values
}

export const loadMerchantLogo = async (logoUrl) => {
  if (!IsNullOrUndefined(logoUrl)) {
    try {
      logoUrl = await imageUrlToBase64(logoUrl)
    } catch (error) {
      console.warn('Unable to load merchant logo')
      logoUrl = undefined
    }
  } else {
    logoUrl = undefined
  }

  return logoUrl
}

export default { getMerchant }
