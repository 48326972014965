import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import AppointmentService from 'services/appointment'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getAppointmentTimeSlotsThunk = createAsyncThunk(
  'hive/appointment/timeSlots',
  withThunkApi(AppointmentService.fetchAppointmentTimeSlots),
)

export const getWorkOrderAppointmentsThunk = createAsyncThunk(
  'hive/workorder/appointment/list',
  withThunkApi(AppointmentService.fetchWorkOrderAppointments),
)

export const getWorkOrderAppointmentThunk = createAsyncThunk(
  'hive/workorder/appointment/get',
  withThunkApi(AppointmentService.fetchWorkOrderAppointment),
)

export const createAppointmentThunk = createAsyncThunk(
  'hive/workorder/appointment/create',
  withThunkApi(AppointmentService.createAppointment),
)

export const updateAppointmentThunk = createAsyncThunk(
  'hive/workorder/appointment/update',
  withThunkApi(AppointmentService.updateAppointment),
)

export const deleteAppointmentThunk = createAsyncThunk(
  'hive/workorder/appointment/delete',
  withThunkApi(AppointmentService.deleteAppointment),
)

const appointmentAdapter = createEntityAdapter({ sortComparer: (p, n) => n.id - p.id })

const appointmentSlice = createSlice({
  name: 'hive/appointment',
  initialState: appointmentAdapter.getInitialState({
    loading: 'idle',
    error: false,
    entity: null,
  }),
  reducers: {
    cleanupAppointment: (state) => {
      state.entity = null
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getWorkOrderAppointmentsThunk.pending,
        getWorkOrderAppointmentThunk.pending,
        createAppointmentThunk.pending,
        updateAppointmentThunk.pending,
        deleteAppointmentThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getWorkOrderAppointmentsThunk.rejected,
        getWorkOrderAppointmentThunk.rejected,
        createAppointmentThunk.rejected,
        updateAppointmentThunk.rejected,
        deleteAppointmentThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getWorkOrderAppointmentsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }

      const { value } = action.payload
      appointmentAdapter.updateMany(state, value)
    },

    [getWorkOrderAppointmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      appointmentAdapter.upsertOne(state, action.payload)
    },

    [createAppointmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        appointmentAdapter.addOne(state, action.payload)
      }
    },

    [updateAppointmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        appointmentAdapter.upsertOne(state, action.payload)
      }
    },

    [deleteAppointmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        appointmentAdapter.removeOne(state, action.payload)
      }
    },
  },
})

export const appointmentSelectors = appointmentAdapter.getSelectors()

export default appointmentSlice.reducer
