export const PermissionType = {
  0: 'UserManagement',
  1: 'RightManagement',
  2: 'Locations',
  3: 'InventoryItemList',
  4: 'Distributor',
  5: 'ContactStatus',
  6: 'Contacts',
  7: 'SetupTemplates',
  8: 'ReceiveEquipment',
  9: 'WarehouseDetails',
  10: 'WorkOrder',
  11: 'WorkflowDesign',
  12: 'ServicesManagement',
  13: 'WorkOrderStatusManagement',
  14: 'WorkOrderEquipmentAndServices',
  15: 'SchedulingAppointments',
  16: 'PaymentProfile',
  17: 'Payments',
  18: 'MonitoringStations',
  19: 'AvantGuardDeviceType',
  20: 'Quote',
  21: 'RecurringTypesManagement',
  22: 'CustomerNote',
  23: 'MonitoringAccount',
  24: 'SecurityLocations',
  25: 'EventCodesManagement',
  26: 'GenericPanelTypesManagement',
  27: 'GenericCellPlanManagement',
  28: 'InventoryTransfer',
  29: 'Reports',
  30: 'DynamicDashboard',
  31: 'InvisusAccount',
  32: 'InvisusClient',
  33: 'InvoicePermission',
  34: 'ReceiverNumberManagement',
  35: 'AccountNumberManagement',
  36: 'MonitoringCellForwardingManagement',
  37: 'CustomerAttachment',
  38: 'AttachmentTypeManagement',
  39: 'CustomerDeletedAttachment',
  40: 'CustomerPrivateAttachment',
  41: 'DocumentTemplateManagement',
}

export const PermissionActionType = {
  0: 'Create',
  1: 'Read',
  2: 'Update',
  3: 'Delete',
}

export const permissionFromId = (permission) => {
  const type = permission.slice(0, -1)
  const actionType = permission.slice(-1)
  return `${PermissionType[type]}.${PermissionActionType[actionType]}`
}
