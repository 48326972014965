import { put, call, all, takeLatest } from 'redux-saga/effects'
import HttpClient from 'services/api'
import buildQuery from 'odata-query'

import {
  ActionTypes,
  getReceivedEquimpentsSuccess,
  getReceivedEquimpentsFailure,
  getReceivedEquimpentSuccess,
  getReceivedEquimpentFailure,
  createReceivedEquimpentSuccess,
  createReceivedEquimpentFailure,
  updateReceivedEquimpentSuccess,
  updateReceivedEquimpentFailure,
  removeReceivedEquimpentSuccess,
  removeReceivedEquimpentFailure,
} from './actions'

export function* getAllReceivedEquimpents({ payload }) {
  try {
    const { data } = yield call(HttpClient.get, `/receivedequipment${buildQuery(payload)}`)
    yield put(
      getReceivedEquimpentsSuccess({
        items: data.value,
        info: data.pagePagination,
      }),
    )
  } catch (error) {
    yield put(getReceivedEquimpentsFailure(error))
  }
}

export function* getReceivedEquimpent({ id }) {
  try {
    const { data } = yield call(HttpClient.get, `/receivedequipment/${id}`)
    yield put(
      getReceivedEquimpentSuccess({
        items: [data],
      }),
    )
  } catch (error) {
    yield put(getReceivedEquimpentFailure(error))
  }
}

export function* createReceivedEquimpent({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.post, '/receivedequipment', payload)
    yield put(createReceivedEquimpentSuccess(data))
    callback()
  } catch (error) {
    yield put(createReceivedEquimpentFailure(error))
  }
}

export function* updateReceivedEquimpent({ id, payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/receivedequipment/${id}`, payload)
    yield put(updateReceivedEquimpentSuccess(data))
    callback()
  } catch (error) {
    yield put(updateReceivedEquimpentFailure(error))
  }
}

export function* removeReceivedEquimpent({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/receivedequipment/${payload.id}`)
    yield put(removeReceivedEquimpentSuccess(data))
    callback()
  } catch (error) {
    yield put(removeReceivedEquimpentFailure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.RECEIVED_EQUIMPENTS_REQUEST, getAllReceivedEquimpents),
    takeLatest(ActionTypes.READ_RECEIVED_EQUIMPENT_REQUEST, getReceivedEquimpent),
    takeLatest(ActionTypes.ADD_RECEIVED_EQUIMPENT_REQUEST, createReceivedEquimpent),
    takeLatest(ActionTypes.UPDATE_RECEIVED_EQUIMPENT_REQUEST, updateReceivedEquimpent),
    takeLatest(ActionTypes.DELETE_RECEIVED_EQUIMPENT_REQUEST, removeReceivedEquimpent),
  ])
}
