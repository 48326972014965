import HttpClient from 'services/api'
import buildQuery from 'odata-query'

export const getEmergencyContacts = async (
  { accountId, odataParams } = {},
  { cancelToken } = {},
) => {
  const {
    data = [],
  } = await HttpClient.get(
    `/monitoringAccounts/${accountId}/emergencyContacts${buildQuery(odataParams)}`,
    { cancelToken },
  )
  return data.value
}

export const getEmergencyContactById = async ({ accountId }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`/monitoringAccounts/${accountId}/emergencyContacts`, {
    cancelToken,
  })
  return data.value
}

export const getEmergencyContactPhoneTypes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/phoneTypes`, { cancelToken })
  return data
}

export const createEmergencyContact = async (
  { accountId, values, odataParams },
  { cancelToken },
) => {
  const { data = {} } = await HttpClient.post(
    `/monitoringAccounts/${accountId}/emergencyContacts${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

export const syncEmergencyContact = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(
    `/providers/${payload.id}/actions/updateContacts/execution`,
    payload,
    {
      cancelToken,
    },
  )

  return data
}

export const editEmergencyContact = async (
  { accountId, contactId, values, odataParams },
  { cancelToken } = {},
) => {
  const {
    data = {},
  } = await HttpClient.put(
    `/monitoringAccounts/${accountId}/emergencyContacts/${contactId}${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )

  return data
}

export const deleteEmergencyContact = async ({ accountId, contactId }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(
    `/monitoringAccounts/${accountId}/emergencyContacts/${contactId}`,
    {
      cancelToken,
    },
  )

  return data
}
