import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import AccountNumberService from 'services/accountNumbers'
import { mergeActionTypes } from 'services/utils'

export const getAccountNumbersThunk = createAsyncThunk(
  'hive/accountNumbers/list',
  withThunkApi(AccountNumberService.getAccountNumbers),
)

export const toggleAccountNumbersThunk = createAsyncThunk(
  'hive/accountNumbers/toggleMultiple',
  withThunkApi(AccountNumberService.toggleAccountNumbers),
)

export const refreshCsStatusThunk = createAsyncThunk(
  'hive/accountNumbers/refreshCsStatusThunk',
  withThunkApi(AccountNumberService.refreshCsStatus),
)

const accountNumbersAdapter = createEntityAdapter()

const accountNumbersSlice = createSlice({
  name: 'hive/accountNumbers',
  initialState: accountNumbersAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupAccountNumbers: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [getAccountNumbersThunk.pending, toggleAccountNumbersThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [getAccountNumbersThunk.rejected, toggleAccountNumbersThunk.rejected],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getAccountNumbersThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      accountNumbersAdapter.setAll(state, action.payload.value)
    },
  },
})

export const accountNumbersActions = accountNumbersSlice.actions
export const accountNumbersSelectors = accountNumbersAdapter.getSelectors()

export default accountNumbersSlice.reducer
