import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history } from 'index'
import { notification } from 'antd'
import store from 'store'
import AuthService from 'services/authentication'
import { initialState } from 'redux/user/reducers'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { error } = yield call(AuthService.authenticate, username, password)
  if (!error) {
    yield put({
      type: 'menu/GET_DATA',
    })

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  } else {
    notification.error({
      message: 'Failed to Log In',
      description: error.message || error,
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const data = yield call(AuthService.getCurrentUser)

    if (data) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ...data,
          authorized: true,
          loading: false,
        },
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } catch (error) {
    console.warn(error.message)
  }
}

export function* LOGOUT() {
  yield call(AuthService.logout)

  const {
    location: { pathname, search },
  } = history

  if (!/^\/user(?=\/|$)/i.test(pathname)) {
    store.set('returnUrl', `${pathname}${search}`)
  }

  history.replace('/user/login')

  yield put({
    type: 'user/SET_STATE',
    payload: initialState,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
