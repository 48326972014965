import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import importedComponent from 'react-imported-component'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/Exception/404'
import UnauthorizedPage from 'pages/Exception/403'
import ServerErrorPage from 'pages/Exception/500'
import AuthorizedRoute from 'components/AlarmHiveComponents/AuthorizedRoute/AuthorizedRoute'

const loadable = (component) =>
  importedComponent(component, {
    LoadingComponent: Loader,
  })

export const ROUTER_PERMS = {
  SETUP: [
    'UserManagement.Read',
    'RightManagement.Read',
    'SetupTemplates.Read',
    'MonitoringStations.Read',
    'AvantGuardDeviceType.Read',
    'WorkflowDesign.Read',
    'RightManagement.Read',
    'Locations.Read',
    'SetupTemplates.Read',
    'WorkflowDesign.Read',
    'Distributor.Read',
    'ServicesManagement.Read',
    'SecurityLocations.Read',
    'ContactStatus.Read',
    'WorkOrderStatusManagement.Read',
    'GenericPanelTypesManagement.Read',
    'GenericCellPlanManagement.Read',
    'EventCodesManagement.Read',
  ],
}

const routes = [
  // w/o permissions
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/resetPassword',
    component: loadable(() => import('pages/user/resetPassword')),
    exact: true,
  },
  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard')),
  },
  {
    path: '/profile',
    component: loadable(() => import('pages/profile/index')),
    exact: true,
  },

  // w/ permissions
  {
    path: '/inventory/new',
    component: loadable(() => import('pages/inventory/create')),
    exact: true,
    permissions: ['InventoryItemList.Create'],
  },
  {
    path: '/inventory/receive',
    component: loadable(() => import('pages/inventory/receive')),
    exact: true,
    permissions: ['ReceiveEquipment.Update'],
  },
  {
    path: '/inventory/transfers',
    component: loadable(() => import('pages/transfer_orders')),
    exact: true,
    permissions: ['InventoryTransfer.Read'],
  },
  {
    path: '/inventory/transfers/new',
    component: loadable(() => import('pages/transfer_orders/create')),
    exact: true,
    permissions: ['InventoryTransfer.Create'],
  },
  {
    path: '/inventory/transfers/:id',
    component: loadable(() => import('pages/transfer_orders/details')),
    exact: true,
    permissions: ['InventoryTransfer.Read'],
  },
  {
    path: '/inventory/transfers/:id/edit',
    component: loadable(() => import('pages/transfer_orders/edit')),
    exact: true,
    permissions: ['InventoryTransfer.Update'],
  },
  {
    path: '/inventory/:id/edit',
    component: loadable(() => import('pages/inventory/edit')),
    exact: true,
    permissions: ['InventoryItemList.Update'],
  },
  {
    path: '/inventory/:id',
    component: loadable(() => import('pages/inventory/details')),
    exact: true,
    permissions: ['InventoryItemList.Read'],
  },
  {
    path: '/inventory',
    component: loadable(() => import('pages/inventory/list')),
    exact: true,
    permissions: ['InventoryItemList.Read'],
  },
  {
    path: '/warehouses/:warehouseId?/inventory',
    component: loadable(() => import('pages/warehouses/inventory')),
    exact: true,
    permissions: ['WarehouseDetails.Read'],
  },
  {
    path: '/warehouses/:warehouseId/inventory/:inventoryId',
    component: loadable(() => import('pages/warehouses/inventory.details')),
    exact: true,
    permissions: ['WarehouseDetails.Read'],
  },
  {
    path: '/appointments',
    component: loadable(() => import('pages/appointments/index')),
    exact: true,
    permissions: ['SchedulingAppointments.Read'],
  },
  {
    path: '/appointments/:id',
    component: loadable(() => import('pages/appointments/details')),
    exact: true,
    permissions: ['SchedulingAppointments.Read'],
  },
  {
    path: '/contacts',
    component: loadable(() => import('pages/contacts/list')),
    exact: true,
    permissions: ['Contacts.Read'],
  },
  {
    path: '/contacts/add',
    component: loadable(() => import('pages/contacts/create')),
    exact: true,
    permissions: ['Contacts.Create'],
  },
  {
    path: '/contacts/:id',
    component: loadable(() => import('pages/contacts/edit')),
    exact: true,
    permissions: ['Contacts.Update', 'Contacts.Read'],
  },
  {
    path: '/setup',
    component: loadable(() => import('pages/setup/index')),
    permissions: ROUTER_PERMS.SETUP,
  },
  {
    path: '/workorders',
    component: loadable(() => import('pages/workorders/list')),
    exact: true,
  },
  {
    path: '/workorders/:id',
    component: loadable(() => import('pages/workorders/details')),
    exact: true,
  },
  {
    path: '/invoices',
    component: loadable(() => import('pages/invoices/list')),
    exact: true,
    permissions: ['InvoicePermission.Read'],
  },
  {
    path: '/invoices/new',
    component: loadable(() => import('pages/invoices/create')),
    exact: true,
    permissions: ['InvoicePermission.Create'],
  },
  {
    path: '/invoices/:id/:childId?',
    component: loadable(() => import('pages/invoices/details')),
    exact: true,
  },
  {
    path: '/reports/designer',
    component: loadable(() => import('pages/reports/designer')),
    exact: true,
    permissions: ['Reports.Update'],
  },
  {
    path: '/reports/:id',
    component: loadable(() => import('pages/reports/index')),
    exact: true,
    permissions: ['Reports.Read'],
  },
]

function Router(props) {
  const { history } = props

  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          {routes.map((route) => (
            <AuthorizedRoute
              key={route.path}
              permissions={route.permissions}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
          <Route exact path="/403" component={UnauthorizedPage} />
          <Route exact path="/500" component={ServerErrorPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  )
}

export default Router
