import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CustomerService from 'services/customer'
import { withThunkApi } from 'services/axios.utils'

export const getCustomerThunk = createAsyncThunk(
  'hive/customer/create',
  withThunkApi(CustomerService.fetchCustomer),
)

const customerSlice = createSlice({
  name: 'hive/customer',
  initialState: {
    loading: 'idle',
    error: false,
    entity: null,
  },
  reducers: {
    cleanupCustomer: (state) => {
      state.entity = null
    },
  },
  extraReducers: {
    [getCustomerThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },

    [getCustomerThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.entity = action.payload
      }
    },
  },
})

export const { cleanupCustomer } = customerSlice.actions

export default customerSlice.reducer
