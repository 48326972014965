import React from 'react'
import { Row, Col } from 'antd'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <Row>
        <Col span={12} />
        <Col span={12}>
          <div className={styles.copyright}>
            <img
              src="resources/images/logo-hove-square.png"
              rel="noopener noreferrer"
              alt="AlarmHive"
            />
            <span>
              &copy; {new Date().getFullYear()}{' '}
              <a href="/" rel="noopener noreferrer">
                AlarmHive
              </a>{' '}
              All rights reserved
            </span>
          </div>
        </Col>
      </Row>
    </div>
  </div>
)

export default Footer
