import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { getTechPersons, getTechPersonsAlarmCom } from 'services/techPerson'
import { withThunkApi } from 'services/axios.utils'

export const getTechPersonsThunk = createAsyncThunk(
  'hive/techPersons/get',
  withThunkApi(getTechPersons),
)

export const getTechPersonsAlarmComThunk = createAsyncThunk(
  'hive/techPersons/get',
  withThunkApi(getTechPersonsAlarmCom),
)

const techPersonsAdapter = createEntityAdapter()

const techPersonsSlice = createSlice({
  name: 'hive/techPersons',
  initialState: techPersonsAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [getTechPersonsThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getTechPersonsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      techPersonsAdapter.setAll(state, action.payload)
    },
    [getTechPersonsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
  },
})

export const techPersonsSelectors = techPersonsAdapter.getSelectors()

export default techPersonsSlice.reducer
