import dayjs from 'dayjs'
import { camelCase, isNull, isUndefined, toLower } from 'lodash'

export const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0
export const lower = (str) => str && str.toLowerCase()

export const findSection = (sections, name) => {
  let section

  for (let idx = 0; idx < sections.length; idx += 1) {
    section = sections[idx]

    if (section.name !== name && Array.isArray(section.components) && section.components.length) {
      section = findSection(section.components, name)
    }

    if (section.name === name) return section
  }

  return section
}

export const mergeActionTypes = (actionTypes, reducer) => {
  if (!Array.isArray(actionTypes)) {
    actionTypes = [actionTypes]
  }

  return actionTypes.reduce((obj, type) => {
    obj[type] = reducer
    return obj
  }, {})
}

export const lowerCharactersDigitsOnly = (str) => lower(str.replace(/[^\w]/g, ''))

export function IsValidJSONString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const lowerCaseContains = (source, str) =>
  String(source || '')
    .toLowerCase()
    .indexOf(String(str || '').toLowerCase()) >= 0

export const lowerCaseStartsWith = (source, str) =>
  String(source || '')
    .toLowerCase()
    .startsWith(String(str || '').toLowerCase())

export const sortByName = (a, b) =>
  String(a || '').toUpperCase() < String(b || '').toUpperCase() ? 1 : -1

export const capitalize = (str) => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const initDayjs = (value) => {
  if (value && !dayjs.isDayjs(value)) {
    return dayjs(value)
  }
  return null
}
export const formatError = (data) => data.message || data

export const normalizeObjectProps = (obj) =>
  Object.keys(obj).reduce((newObj, key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      newObj[camelCase(key)] = normalizeObjectProps(obj[key])
    } else {
      newObj[camelCase(key)] = obj[key]
    }
    return newObj
  }, {})

export const cutString = (state, substr) => (state || '').replace(substr, '')

export const beautifyJoin = (blocks, separator) =>
  blocks.reduce((output, block, idx, arr) => {
    if (Array.isArray(block)) {
      block = beautifyJoin(block, ' ')
    }

    if (block) {
      const next = arr[idx + 1]

      if ((idx !== arr.length - 1 && next) || (!next && idx + 2 < arr.length)) {
        block = `${block}${separator}`
      }

      output = `${output}${block}`
    }

    return output
  }, '')

export const IsNullOrUndefined = (obj) => isUndefined(obj) || isNull(obj)

export const blobToBase64 = (blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const imageUrlToBase64 = async (url) => {
  const response = await fetch(url)
  return blobToBase64(await response.blob())
}

export const rateToPercents = (v) => `${+((Number(v) || 0) * 100).toFixed(4)}`

export const hasPermission = (current, exist) => {
  if (!Array.isArray(current)) {
    current = [current]
  }
  const hasPerm = exist.some((permission) => current.includes(permission))

  return hasPerm || !current.length
}

export const applyQuerySearch = (queryObj, searchQuery, searchFields) => {
  if (searchQuery && searchQuery.length) {
    const lowercaseQuery = toLower(searchQuery)

    queryObj.or = queryObj.or || []

    searchFields.forEach((field) => {
      queryObj.or.push({ [`tolower(${field})`]: { contains: lowercaseQuery } })
    })
  }
}

export const getArrayKey = (arr = [], keyField) =>
  arr
    .map((item) => {
      if (typeof item !== 'object') return item
      if (keyField) return keyField(item)
      return item.id
    })
    .join('_')

export const getRandomKey = () => Math.random().toString()

export const formatText = (value, pattern, char) => {
  let idx = 0
  value = value.toString()

  return pattern.replace(new RegExp(char, 'g'), () => {
    idx += 1
    return value[idx - 1]
  })
}

export default {
  isEmptyArray,
  lower,
  findSection,
  mergeActionTypes,
  lowerCharactersDigitsOnly,
  initDayjs,
  capitalize,
  formatError,
  beautifyJoin,
  hasPermission,
}
