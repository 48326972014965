import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import { getRecurringTypes, createRecurringType } from 'services/recurringTypes'
import { withThunkApi } from 'services/axios.utils'

const recurringTypesAdapter = createEntityAdapter()

export const fetchRecurringTypesThunk = createAsyncThunk(
  'hive/recurringTypes',
  withThunkApi(getRecurringTypes),
)

export const createRecurringTypeThunk = createAsyncThunk(
  'hive/recurringTypes/create',
  withThunkApi(createRecurringType),
)

const RecurringTypesSlice = createSlice({
  name: 'hive/recurringTypes',
  initialState: recurringTypesAdapter.getInitialState({
    loading: 'idle',
    error: null,
    recentlyAdded: null,
  }),
  extraReducers: {
    [fetchRecurringTypesThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [fetchRecurringTypesThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      recurringTypesAdapter.setAll(state, action.payload.value)
    },
    [fetchRecurringTypesThunk.rejected]: (state) => {
      state.loading = 'idle'
    },
    [createRecurringTypeThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [createRecurringTypeThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [createRecurringTypeThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.recentlyAdded = action.payload
        recurringTypesAdapter.addOne(state, action.payload)
      }
    },
  },
})

export const recurringTypesSelector = recurringTypesAdapter.getSelectors()

export default RecurringTypesSlice.reducer
