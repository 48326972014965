import { takeEvery, put, call, all, takeLatest } from 'redux-saga/effects'
import HttpClient from 'services/api'
import { notification } from 'antd'
import buildQuery from 'odata-query'
import { parseResponse } from 'services/odata'
import actions, { getUsersSuccess, getUsersFailure } from './actions'

export function* LOAD_USER_LIST() {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      listLoader: {
        loading: true,
        fetched: false,
      },
      userLoader: {
        loading: false,
        fetched: false,
      },
      updated: false,
    },
  })

  const { items = [], page = {}, error } = yield call(async () => {
    const { data } = await HttpClient.get('/users')
    return { items: data.value, page: data.pagination, error: null }
  })

  if (!error) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        listLoader: {
          loading: false,
          fetched: true,
        },
        items,
        page,
      },
    })
  }
}

export function* GET_USERS_LIST({ payload }) {
  try {
    const { data } = yield call(HttpClient.get, `/users${buildQuery(payload)}`)

    yield put(
      getUsersSuccess({
        items: data.value,
        odata: parseResponse(data).odata,
      }),
    )
  } catch (error) {
    yield put(getUsersFailure(error))
  }
}

export function* LOAD_SINGLE_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      listLoader: {
        loading: false,
        fetched: false,
      },
      userLoader: {
        loading: true,
        fetched: false,
      },
      updated: false,
      added: false,
      item: {},
    },
  })

  const { item = {}, error } = yield call(async () => {
    const { data } = await HttpClient.get(`/users/${payload.id}`)
    return { item: data, error: null }
  })

  if (!error) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        userLoader: {
          loading: false,
          fetched: true,
        },
        item,
      },
    })
  }
}

export function* ADD_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      listLoader: {
        loading: false,
        fetched: false,
      },
      userLoader: {
        loading: true,
        fetched: false,
      },
      added: false,
      item: {},
    },
  })

  const { data, error } = yield call(async () => {
    let resp = {}
    try {
      resp = await HttpClient.post('/users', payload)
    } catch ({ response = {} }) {
      const { Description = {} } = response?.data[0]
      resp.error = Description
    }
    return resp
  })

  let added = false

  if (!error) {
    notification.success({
      message: 'User Create',
      description: 'New User created successfully',
    })
    added = true
  } else {
    notification.error({
      message: 'User Create',
      description: error,
    })
  }

  yield put({
    type: 'users/SET_STATE',
    payload: {
      userLoader: {
        loading: false,
        fetched: true,
      },
      added,
      item: data,
    },
  })
}

export function* UPDATE_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      listLoader: {
        loading: false,
        fetched: false,
      },
      userLoader: {
        loading: true,
        fetched: false,
      },
      added: false,
    },
  })

  const { data = {}, error } = yield call(async () => {
    let resp = {}

    try {
      resp = await HttpClient.put(`/users/${payload.id}`, payload)
    } catch ({ message }) {
      resp.error = message
    }
    return resp
  })

  let added = false

  if (!error) {
    notification.success({
      message: 'User Edit',
      description: 'User has been updated successfully',
    })
    added = true
  } else {
    notification.error({
      message: 'User Edit',
      description: error,
    })
  }

  yield put({
    type: 'users/SET_STATE',
    payload: {
      userLoader: {
        loading: false,
        fetched: false,
      },
      listLoader: {
        loading: false,
        fetched: false,
      },
      added,
      item: data,
    },
  })
}

export function* LOAD_USER_TYPES() {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      typesLoader: {
        loading: true,
        fetched: false,
      },
    },
  })

  const { userTypes = [], error } = yield call(async () => {
    try {
      const {
        data: { userType = [] },
      } = await HttpClient.get('/resources/enums')
      const userTypesArr = Object.values(userType)
      let ids = []
      let byId = {}
      userTypesArr.forEach((item) => {
        ids = [...ids, item.value]
        byId = { ...byId, [item.value]: item }
      })
      return { userTypes: { raw: userTypesArr, byId, ids }, error: null }
    } catch (err) {
      return err
    }
  })

  if (!error) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        typesLoader: {
          loading: false,
          fetched: true,
        },
        userTypes,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.USERS_REQUEST, GET_USERS_LIST),
    takeEvery(actions.LOAD_USER_LIST, LOAD_USER_LIST),
    takeEvery(actions.LOAD_SINGLE_USER, LOAD_SINGLE_USER),
    takeEvery(actions.ADD_USER, ADD_USER),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.LOAD_USER_TYPES, LOAD_USER_TYPES),
  ])
}
