import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import { getTemplates, createTemplate, editTemplate, getTemplate } from 'services/template'
import { withThunkApi } from 'services/axios.utils'

const templatesAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
})

export const fetchTemplatesThunk = createAsyncThunk('hive/templates', withThunkApi(getTemplates))

export const getTemplateThunk = createAsyncThunk(
  'hive/templates/getById',
  withThunkApi(getTemplate),
)

export const createTemplateThunk = createAsyncThunk(
  'hive/template/create',
  withThunkApi(createTemplate),
)

export const editTemplateThunk = createAsyncThunk('hive/template/edit', withThunkApi(editTemplate))

const layoutTemplatesSlice = createSlice({
  name: 'hive/layoutTemplates',
  initialState: templatesAdapter.getInitialState({
    loading: 'idle',
    error: null,
  }),
  extraReducers: {
    [fetchTemplatesThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [fetchTemplatesThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      templatesAdapter.setAll(state, action.payload)
    },
    [fetchTemplatesThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [createTemplateThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [createTemplateThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [createTemplateThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        templatesAdapter.addOne(state, action.payload)
      }
    },
    [editTemplateThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [editTemplateThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [editTemplateThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        templatesAdapter.upsertOne(state, action.payload)
      }
    },
    [getTemplateThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [getTemplateThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [getTemplateThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        templatesAdapter.upsertOne(state, action.payload)
      }
    },
  },
})

export const templatesSelector = templatesAdapter.getSelectors()

export default layoutTemplatesSlice.reducer
