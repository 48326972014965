import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getDocumentTemplates = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/documentTemplates${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const getDocumentTemplate = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/documentTemplates/${payload.id}${buildQuery(payload.odataParams)}`,
    {
      cancelToken,
    },
  )

  return data
}

const createDocumentTemplate = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/documentTemplates`, payload, { cancelToken })
  return data
}

const updateDocumentTemplate = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`/documentTemplates/${payload.id}`, payload, {
    cancelToken,
  })
  return data
}

const DocumentTemplateService = {
  getDocumentTemplates,
  getDocumentTemplate,
  createDocumentTemplate,
  updateDocumentTemplate,
}

export default DocumentTemplateService
