import store from 'store'
import buildQuery from 'odata-query'
import dayjs from 'dayjs'
import { permissionFromId } from 'data/permission'
import HttpClient from './api'
// import ReportHttpClient from './api/report'

function AuthenticationService() {
  const { expires_at: expAt, auth_token: atoken = null } = store.get('user') || {}

  this.user = null

  this.accessToken = atoken
  this.accessTokenExp = expAt ? dayjs(expAt) : null
  this.state = 'idle'

  let refreshTokenTimeout = null

  this.isAccessTokenValid = () => {
    if (!this.accessToken) return false
    return dayjs().isBefore(this.accessTokenExp)
  }

  this.authenticate = async (username, password) => {
    try {
      const { data, status } = await HttpClient.post('/login/token', {
        username,
        password,
      })

      if (data) {
        const { refresh_token: token, refresh_token_expires_at: expiresAt, ...user } = data

        store.set('refresh_token', { token, expiresAt })

        this.accessToken = user.auth_token
        this.accessTokenExp = dayjs(user.expires_at)

        store.set('user', user)

        await loadUser()
        // await loadReportsToken()
      }

      return { status }
    } catch ({ response }) {
      return { error: response?.data }
    }
  }

  this.refreshToken = async () => {
    const { token, expiresAt } = store.get('refresh_token') || {}

    if (token) {
      const expDiff = dayjs(expiresAt).diff(dayjs(), 'millisecond')

      if (expDiff >= 0) {
        this.state = 'pending'

        const { data } = await HttpClient.post('/login/token', {
          grandType: 'refresh_token',
          refresh_token: token,
        })

        if (data) {
          const { refresh_token: _, refresh_token_expires_at: __, ...user } = data

          this.accessToken = user.auth_token
          this.accessTokenExp = dayjs(user.expires_at)

          store.set('user', user)

          startRefreshTokenTimer()
        }

        this.state = 'idle'
      } else {
        this.logout()
      }
    }
  }

  const loadUser = async () => {
    const id = store.get('user')?.id

    if (!this.user && id) {
      const { data } = await HttpClient.get(
        `/users/${id}/profile${buildQuery({
          select: [
            'id',
            'firstName',
            'lastName',
            'email',
            'phone',
            'roleId',
            'userName',
            'userTypes',
            'isActive',
          ],
          expand: { role: { select: ['id', 'name'] } },
        })}`,
      )

      delete data['@odata.context']

      this.user = data

      const {
        data: { value: permissions },
      } = await HttpClient.get(`/users/${id}/permissions`)

      this.user.permissions = permissions.map(permissionFromId)

      startRefreshTokenTimer()
    }

    return this.user
  }

  this.getCurrentUser = async () => {
    if (!this.user) {
      return loadUser()
    }

    return this.user
  }

  // const loadReportsToken = async () => {
  //   const { permissions } = this.user
  //
  //   if (
  //     permissions.includes('Reports.Read') ||
  //     permissions.includes('Reports.Update') ||
  //     permissions.includes('DynamicDashboard.Read') ||
  //     permissions.includes('DynamicDashboard.Update')
  //   ) {
  //     const { data } = await HttpClient.get('/reportToken')
  //     const { accessToken } = data
  //     store.set('reports_token', accessToken)
  //
  //     if (permissions.includes('DynamicDashboard.Read')) {
  //       const dashboards = await ReportHttpClient.post(`/api/GraphQL?token=${accessToken}`, {
  //         query: `{ documenttypes(key: "dbd") { documents { id filename }}}`,
  //       })
  //
  //       const { documenttypes } = dashboards?.data?.data
  //       const docs = documenttypes[0]
  //       if (docs.documents && docs.documents.length > 0) {
  //         const { id } = docs?.documents[0]
  //         store.set('reports_default_dashboard_id', id)
  //       }
  //     }
  //
  //     if (permissions.includes('Reports.Read')) {
  //       const categories = await ReportHttpClient.post(`api/GraphQL?token=${accessToken}`, {
  //         query: `{tags {id parentId name documents {id type title }}}`,
  //       })
  //
  //       const { tags } = categories?.data?.data
  //
  //       const toExclude = ['PROD', 'STAGE', 'TEST', 'Favorites']
  //       const reportsMenu = tags
  //         .filter(({ name }) => !name.startsWith('$'))
  //         .filter(({ name }) => !toExclude.includes(name))
  //         .reduce((menu, item) => {
  //           if (item.parentId && (item.documents || []).length > 0) {
  //             const subMenuIndex = menu.findIndex(({ id }) => id === item.parentId)
  //             menu[subMenuIndex] = { ...menu[subMenuIndex], children: item.documents }
  //             return menu
  //           }
  //           return [...menu, item]
  //         }, [])
  //
  //       store.set('reports_menu', reportsMenu)
  //     }
  //   }
  // }

  this.logout = () => {
    stopRefreshTokenTimer()
    this.user = null
    store.remove('user')
    store.remove('refresh_token')

    store.remove('reports_token')
    store.remove('reports_default_dashboard_id')
    store.remove('reports_menu')
  }

  const startRefreshTokenTimer = () => {
    const timeout = dayjs(this.accessTokenExp).diff(dayjs(), 'millisecond')

    if (timeout) {
      refreshTokenTimeout = setTimeout(() => {
        this.refreshToken()
      }, timeout)
    }
  }

  const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout)
  }
}

const AuthService = new AuthenticationService()

export default AuthService
