import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import styled from 'styled-components'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
})

const StyledLayout = styled(Layout)``
const StyledContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
`

const FullHeightContainer = styled.div`
  height: 100%;
  overflow: hidden;

  ${StyledLayout}, & .ant-layout {
    height: 100%;
  }
`

function MainLayout({
  children,
  isBorderless,
  isSquaredBorders,
  isFixedWidth,
  isMenuShadow,
  isMenuTop,
}) {
  return (
    <FullHeightContainer>
      <StyledLayout
        className={classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
        })}
      >
        <Menu />
        <StyledLayout>
          <StyledContent>
            <FullHeightContainer>{children}</FullHeightContainer>
          </StyledContent>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </StyledLayout>
      </StyledLayout>
    </FullHeightContainer>
  )
}

export default connect(mapStateToProps)(withRouter(MainLayout))
