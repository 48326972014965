import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import WorkflowHttpClient from 'services/api/workflow'

export default function WorkflowInstanceRenderProducer(name) {
  const getWorkflowInstanceRenderThunk = createAsyncThunk(
    `${name}/fetch`,
    async ({ instanceId }) => {
      const { data } = await WorkflowHttpClient.get(`/instances/${instanceId}/state/rendering`)
      return data
    },
  )

  const workflowStateRenderAdapter = createEntityAdapter({ selectId: (state) => state.instanceId })

  const workflowInstanceRenderSlice = createSlice({
    name,
    initialState: workflowStateRenderAdapter.getInitialState({
      loading: 'idle',
      active: null,
    }),
    reducers: {
      cleanupInstanceRenderEntities: (state) => {
        state.ids = []
        state.entities = {}
      },
      setActiveInstance: (state, { payload }) => {
        state.active = payload
      },
    },
    extraReducers: {
      [getWorkflowInstanceRenderThunk.pending]: (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
      [getWorkflowInstanceRenderThunk.fulfilled]: (state, { payload, meta: { arg } = {} }) => {
        if (state.loading === 'pending') {
          payload.instanceId = arg.instanceId
          workflowStateRenderAdapter.upsertOne(state, payload)

          state.loading = 'idle'
        }
      },
      [getWorkflowInstanceRenderThunk.rejected]: (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    },
  })

  const { setActiveInstance, cleanupInstanceRenderEntities } = workflowInstanceRenderSlice.actions

  return {
    reducer: workflowInstanceRenderSlice.reducer,
    workflowStateRenderSelectors: workflowStateRenderAdapter.getSelectors(),
    getWorkflowInstanceRenderThunk,
    setActiveInstance,
    cleanupInstanceRenderEntities,
  }
}
