import React from 'react'
import { useEventEmitter } from 'ahooks'

export const EventContext = React.createContext()

export default function EventPovider({ children }) {
  const event$ = useEventEmitter()

  return <EventContext.Provider value={event$}>{children}</EventContext.Provider>
}
