import axios from 'axios'
import { authHeaderRequestInterceptor } from './interceptors'

const PaymentHttpClient = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_URL,
})

PaymentHttpClient.interceptors.request.use(authHeaderRequestInterceptor)

export default PaymentHttpClient
