import store from 'store'
import { store as reduxStore } from 'index'

export default function jqueryConfig() {
  window.$.ajaxSetup({
    beforeSend(xhr) {
      const state = reduxStore.getState()
      const token = store.get('user')
      const authorization = token ? `Bearer ${token.auth_token}` : ''
      xhr.setRequestHeader('Authorization', authorization)
      xhr.setRequestHeader(
        process.env.REACT_APP_TENANT_HTTP_HEADER,
        state?.app?.tenant || process.env.REACT_APP_DEFAULT_TENANT,
      )
    },
  })
}
