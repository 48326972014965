import React from 'react'
import { formatText } from 'services/utils'

export default function Phone(props) {
  let { value = '' } = props

  if (value && value.length && !/\W+/.test(value)) {
    value = formatText(value, '(xxx)xxx-xxxx', 'x')
  }

  return <span>{value}</span>
}
