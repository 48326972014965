import { put, call, all, takeLatest } from 'redux-saga/effects'
import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { parseResponse } from 'services/odata'

import {
  ActionTypes,
  getStockitemsSuccess,
  getStockitemsFailure,
  getStockitemSuccess,
  getStockitemFailure,
} from './actions'

export function* getAllStockitems({ payload }) {
  try {
    const { data } = yield call(HttpClient.get, `/stockitems${buildQuery(payload)}`)
    yield put(
      getStockitemsSuccess({
        items: data.value,
        odata: parseResponse(data).odata,
      }),
    )
  } catch (error) {
    yield put(getStockitemsFailure(error))
  }
}

export function* getStockitem({ id }) {
  try {
    const { data } = yield call(HttpClient.get, `/stockitems/${id}`)
    yield put(
      getStockitemSuccess({
        items: [data],
      }),
    )
  } catch (error) {
    yield put(getStockitemFailure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.STOCKITEMS_REQUEST, getAllStockitems),
    takeLatest(ActionTypes.READ_STOCKITEM_REQUEST, getStockitem),
  ])
}
