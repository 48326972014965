import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { IsNullOrUndefined } from './utils'

export const getWoServices = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`/services${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

export const getWoService = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data = {} } = await HttpClient.get(`/services/${id}${buildQuery(odataParams)}`, payload, {
    cancelToken,
  })

  return data
}

export const createWoService = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post('/services', payload, { cancelToken })
  return data
}

export const updateWoService = async (payload, { cancelToken } = {}) => {
  const { id, ...values } = payload
  const { data = {} } = await HttpClient.patch(`/services/${id}`, values, { cancelToken })
  return data
}

export const isUniqueCode = async (payload, { cancelToken } = {}) => {
  const { code } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/services${buildQuery({
      filter: {
        code,
      },
      select: ['code', 'id'],
    })}`,
    { cancelToken },
  )

  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}
const WOSService = {
  getWoServices,
  getWoService,
  createWoService,
  updateWoService,
  isUniqueCode,
}

export default WOSService
