import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import InventoryService from 'services/inventory'
import { withThunkApi } from 'services/axios.utils'
import { mergeActionTypes } from 'services/utils'

export const getAllInventoryThunk = createAsyncThunk(
  'hive/inventory/list',
  withThunkApi(InventoryService.getAllInventory),
)

export const getInventoryThunk = createAsyncThunk(
  'hive/inventory/get',
  withThunkApi(InventoryService.getInventory),
)

export const createInventoryThunk = createAsyncThunk(
  'hive/inventory/create',
  withThunkApi(InventoryService.createInventory),
)

export const updateInventoryThunk = createAsyncThunk(
  'hive/inventory/update',
  withThunkApi(InventoryService.updateInventory),
)

const inventoryAdapter = createEntityAdapter()

const inventorySlice = createSlice({
  name: 'hive/inventory',
  initialState: inventoryAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanInventoryList: (state) => inventoryAdapter.removeAll(state),
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getAllInventoryThunk.pending,
        getInventoryThunk.pending,
        createInventoryThunk.pending,
        updateInventoryThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getAllInventoryThunk.rejected,
        getInventoryThunk.rejected,
        createInventoryThunk.rejected,
        updateInventoryThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    ...mergeActionTypes(
      [getInventoryThunk.fulfilled, createInventoryThunk.fulfilled, updateInventoryThunk.fulfilled],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          inventoryAdapter.upsertOne(state, action.payload)
        }
      },
    ),
    [getAllInventoryThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        inventoryAdapter.setAll(state, action.payload.value)
      }
    },
  },
})

export const inventorySelectors = inventoryAdapter.getSelectors()
export const { cleanInventoryList } = inventorySlice.actions

export default inventorySlice.reducer
