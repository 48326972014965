import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import alarmComCellPlanService from 'services/cellPlan'

import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getAlarmComCellPlansThunk = createAsyncThunk(
  'hive/alarmComCellPlans/list',
  withThunkApi(alarmComCellPlanService.getNonGenericCellPlans),
)

const alarmComCellPlanAdapter = createEntityAdapter()

const alarmComCellPlanSlice = createSlice({
  name: 'hive/alarmComCellPlans',
  initialState: alarmComCellPlanAdapter.getInitialState({
    loading: 'idle',
    latest: null
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes([getAlarmComCellPlansThunk.pending], (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    }),
    [getAlarmComCellPlansThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }

      alarmComCellPlanAdapter.setAll(state, action.payload)
    },
    [getAlarmComCellPlansThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
  },
})

export const alarmComCellPlansActions = alarmComCellPlanSlice.actions
export const alarmComCellPlansSelectors = alarmComCellPlanAdapter.getSelectors()

export default alarmComCellPlanSlice.reducer
