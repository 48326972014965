import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { IsNullOrUndefined } from './utils'

const getPanelTypes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/GenericPanelTypes${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const getPanelType = async ({ id, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/GenericPanelTypes/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data
}

const createPanelType = async ({ values }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/GenericPanelTypes`, values, {
    cancelToken,
  })
  return data
}

const updatePanelTypes = async ({ id, values, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(
    `/GenericPanelTypes/${id}${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

const isUniquePanelTypeName = async (payload, { cancelToken } = {}) => {
  const { name } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/genericPanelTypes${buildQuery({
      filter: {
        name,
      },
    })}`,
    { cancelToken },
  )

  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

const PanelTypesService = {
  getPanelTypes,
  getPanelType,
  createPanelType,
  updatePanelTypes,
  isUniquePanelTypeName,
}

export default PanelTypesService
