export const ActionTypes = {
  LOCATIONS_REQUEST: 'hive/location/list/request',
  LOCATIONS_SUCCESS: 'hive/location/list/success',
  LOCATIONS_FAILURE: 'hive/location/list/failure',
  READ_LOCATION_REQUEST: 'hive/location/get/request',
  READ_LOCATION_SUCCESS: 'hive/location/get/success',
  READ_LOCATION_FAILURE: 'hive/location/get/failure',
  ADD_LOCATION_REQUEST: 'hive/location/add/request',
  ADD_LOCATION_SUCCESS: 'hive/location/add/success',
  ADD_LOCATION_FAILURE: 'hive/location/add/failure',
  UPDATE_LOCATION_REQUEST: 'hive/location/update/request',
  UPDATE_LOCATION_SUCCESS: 'hive/location/update/success',
  UPDATE_LOCATION_FAILURE: 'hive/location/update/failure',
  DELETE_LOCATION_REQUEST: 'hive/location/delete/request',
  DELETE_LOCATION_SUCCESS: 'hive/location/delete/success',
  DELETE_LOCATION_FAILURE: 'hive/location/delete/failure',
}

export const getLocations = (payload) => ({ type: ActionTypes.LOCATIONS_REQUEST, payload })
export const getLocationsSuccess = (response) => ({
  type: ActionTypes.LOCATIONS_SUCCESS,
  payload: response,
})
export const getLocationsFailure = (error) => ({ type: ActionTypes.LOCATIONS_FAILURE, error })

export const getLocation = (id) => ({ type: ActionTypes.READ_LOCATION_REQUEST, id })
export const getLocationSuccess = (response) => ({
  type: ActionTypes.READ_LOCATION_SUCCESS,
  payload: response,
})
export const getLocationFailure = (error) => ({ type: ActionTypes.READ_LOCATION_FAILURE, error })

export const createLocation = (data, callback) => ({
  type: ActionTypes.ADD_LOCATION_REQUEST,
  payload: data,
  callback,
})
export const createLocationSuccess = (response) => ({
  type: ActionTypes.ADD_LOCATION_SUCCESS,
  payload: response,
})
export const createLocationFailure = (error) => ({ type: ActionTypes.ADD_LOCATION_FAILURE, error })

export const removeLocation = (id, callback) => ({
  type: ActionTypes.DELETE_LOCATION_REQUEST,
  id,
  callback,
})
export const removeLocationSuccess = (response) => ({
  type: ActionTypes.DELETE_LOCATION_SUCCESS,
  payload: response,
})
export const removeLocationFailure = (error) => ({
  type: ActionTypes.DELETE_LOCATION_FAILURE,
  error,
})

export const updateLocation = (id, data, callback) => ({
  type: ActionTypes.UPDATE_LOCATION_REQUEST,
  id,
  payload: data,
  callback,
})
export const updateLocationSuccess = (response) => ({
  type: ActionTypes.UPDATE_LOCATION_SUCCESS,
  payload: response,
})
export const updateLocationFailure = (error) => ({
  type: ActionTypes.UPDATE_LOCATION_FAILURE,
  error,
})
