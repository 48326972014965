import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import english from 'locales/en-US'

const locales = {
  'en-US': english,
}
@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props
    const currentLocale = locales[locale]
    return (
      <ConfigProvider
        locale={currentLocale.antdData}
        form={{
          validateMessages: {
            required: 'Required',
            string: {
              len: '${label} must be exactly ${len} characters',
              min: '${label} must be at least ${min} characters',
              max: '${label} cannot be longer than ${max} characters',
              range: '${label} must be between ${min} and ${max} characters',
            },
          },
        }}
      >
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

export default Localization
