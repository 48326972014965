import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { mergeActionTypes } from 'services/utils'
import { getPaymentTransactions, createPaymentTransaction } from 'services/paymentTransactions'
import { withThunkApi } from 'services/axios.utils'

export const fetchPaymentTransactionsThunk = createAsyncThunk(
  'hive/customer/paymentTransaction/list',
  withThunkApi(getPaymentTransactions),
)

export const createPaymentTransactionThunk = createAsyncThunk(
  'hive/customer/paymentTransaction/create',
  withThunkApi(createPaymentTransaction),
)

const paymentTransactionAdapter = createEntityAdapter({
  sortComparer: (p, n) => n.id - p.id,
})

const paymentTransactionsSlice = createSlice({
  name: 'hive/paymentTransactions',
  initialState: paymentTransactionAdapter.getInitialState({
    loading: 'idle',
    loadStatus: {},
  }),
  extraReducers: {
    ...mergeActionTypes(
      [createPaymentTransactionThunk.pending],
      (state) => {
        if (state.loading === 'idle') state.loading = 'pending'
      },
    ),

    [fetchPaymentTransactionsThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },

    [fetchPaymentTransactionsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },

    ...mergeActionTypes(
      [createPaymentTransactionThunk.rejected, createPaymentTransactionThunk.fulfilled],
      (state, action) => {
        const { invoiceId } = action.meta.arg
        delete state.loadStatus[invoiceId]
      },
    ),

    [fetchPaymentTransactionsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        paymentTransactionAdapter.setAll(state, action.payload.value)
      }
    },

    [createPaymentTransactionThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        paymentTransactionAdapter.addOne(state, action.payload)
      }
    },
  },
})

export const paymentTransactionSelectors = paymentTransactionAdapter.getSelectors()

export default paymentTransactionsSlice.reducer
