import buildQuery from 'odata-query'
import PaymentHttpClient from 'services/api/payment'
import { IsNullOrUndefined } from './utils'

export const getRecurringTypes = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await PaymentHttpClient.get(`/recurringType${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

export const getRecurringType = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data = [] } = await PaymentHttpClient.get(
    `/recurringType/${id}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data
}

export const createRecurringType = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.post('/recurringType', payload, { cancelToken })

  return data
}

export const updateRecurringType = async (payload, { cancelToken } = {}) => {
  const { id, ...values } = payload
  const { data = {} } = await PaymentHttpClient.patch(`/recurringType/${id}`, values, {
    cancelToken,
  })

  return data
}

export const isUniqueName = async (payload, { cancelToken } = {}) => {
  const { name } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await PaymentHttpClient.get(
    `/recurringType${buildQuery({
      filter: {
        name,
      },
    })}`,
    { cancelToken },
  )
  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

const RecurringTypeService = {
  getRecurringTypes,
  getRecurringType,
  createRecurringType,
  updateRecurringType,
  isUniqueName,
}

export default RecurringTypeService
