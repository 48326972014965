import { put, all, call } from 'redux-saga/effects'
import HttpClient from 'services/api'

export function* SETUP() {
  // load settings from url on app load

  const { REACT_APP_DOMAIN, REACT_APP_DEFAULT_TENANT } = process.env

  const domain = `.${REACT_APP_DOMAIN}`
  let tenant = REACT_APP_DEFAULT_TENANT

  let subdomains = []

  const { host } = window.location

  if (~host.indexOf(domain)) {
    subdomains = host.split(domain)
    subdomains.pop() // remove empty string

    if (subdomains.length) {
      const [subdomain$] = subdomains

      tenant = subdomain$ === 'www' ? REACT_APP_DEFAULT_TENANT : subdomain$

      if (~subdomain$.indexOf('.')) {
        subdomains = subdomain$.split('.')
        tenant = subdomains.pop()
      }
    }
  }

  yield put({
    type: 'app/SET_STATE',
    payload: {
      tenant,
    },
  })

  const { data } = yield call(HttpClient.get, '/resources/enums')
  yield put({
    type: 'app/SET_STATE',
    payload: {
      enums: data,
    },
  })
}

export default function* rootSaga() {
  yield all([SETUP()]) // run once on app load to init listeners
}
