import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const createStatus = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/workOrderStatuses`, payload, { cancelToken })
  return data
}

const updateStatus = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`/workOrderStatuses/${payload.id}`, payload, {
    cancelToken,
  })
  return data
}

const getStatus = async (id, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/workOrderStatuses/${id}`, { cancelToken })
  return data
}

const getAll = async (odataParams, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/workOrderStatuses${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data.value
}

const fetchWorkorder = async ({ workOrderId, odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/workorders/${workOrderId}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )

  delete data['@odata.context']
  return data
}

const fetchWorkorders = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/workorders${buildQuery(payload)}`, {
    cancelToken,
  })

  delete data['@odata.context']
  return data
}

const WorkOrderService = {
  createStatus,
  updateStatus,
  getAll,
  getStatus,
  fetchWorkorder,
  fetchWorkorders,
}

export default WorkOrderService
