import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import {
  getSecurityZoneById,
  createSecurityZone,
  editSecurityZone,
  deleteSecurityZone,
} from 'services/securityZone'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getSecurityZonesThunk = createAsyncThunk(
  'hive/securityZones/get',
  withThunkApi(getSecurityZoneById),
)

export const createSecurityZonesThunk = createAsyncThunk(
  'hive/securityZones/create',
  withThunkApi(createSecurityZone),
)

export const updateSecurityZonesThunk = createAsyncThunk(
  'hive/securityZones/update',
  withThunkApi(editSecurityZone),
)

export const removeSecurityZonesThunk = createAsyncThunk(
  'hive/securityZones/remove',
  withThunkApi(deleteSecurityZone),
)

const securityZonesAdapter = createEntityAdapter()

const securityZonesSlice = createSlice({
  name: 'hive/securityZones',
  initialState: securityZonesAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        getSecurityZonesThunk.pending,
        createSecurityZonesThunk.pending,
        removeSecurityZonesThunk.pending,
        updateSecurityZonesThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getSecurityZonesThunk.rejected,
        createSecurityZonesThunk.rejected,
        removeSecurityZonesThunk.rejected,
        updateSecurityZonesThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getSecurityZonesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      const toRemove = action.payload.value.map((item) => item.id)
      securityZonesAdapter.removeMany(state, toRemove)
      securityZonesAdapter.addMany(state, action.payload.value)
    },
    [createSecurityZonesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        securityZonesAdapter.upsertOne(state, action.payload)
      }
    },
    [updateSecurityZonesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        securityZonesAdapter.upsertOne(state, action.payload)
      }
    },
    [removeSecurityZonesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        securityZonesAdapter.removeOne(state, action.meta.arg.zoneId)
      }
    },
  },
})

const monitoringAccountZones = createSelector(
  (state) => state.securityZones,
  (_, accountId) => accountId,
  (zones, id) =>
    (securityZonesAdapter.getSelectors().selectAll(zones) || []).filter(
      ({ monitoringAccountId }) => monitoringAccountId === id,
    ),
)

export const securityZonesSelectors = {
  monitoringAccountZones,
  isLoading: (state) => state.loading === 'pending',
  ...securityZonesAdapter.getSelectors(),
}

export default securityZonesSlice.reducer
