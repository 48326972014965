import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import QuoteService from 'services/quote'
import { withThunkApi } from 'services/axios.utils'

export const getQuoteStatusListThunk = createAsyncThunk(
  'hive/quoteStatuses/list',
  withThunkApi(QuoteService.fetchQuoteStatuses),
)

const quoteStatusAdapter = createEntityAdapter()

const quoteStatusSlice = createSlice({
  name: 'hive/quoteStatuses',
  initialState: quoteStatusAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [getQuoteStatusListThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getQuoteStatusListThunk.fulfilled]: (state, { payload }) => {
      if (state.loading === 'pending' && payload) {
        state.loading = 'idle'
        quoteStatusAdapter.setAll(state, payload)
      }
    },
    [getQuoteStatusListThunk.rejected]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
  },
})

export const quoteStatusSelectors = quoteStatusAdapter.getSelectors()

export default quoteStatusSlice.reducer
