import WorkflowHttpClient from 'services/api/workflow'

export const getTemplates = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await WorkflowHttpClient.get(`/templates`, { cancelToken })
  return data
}

export const getTemplate = async (payload, { cancelToken } = {}, { rejectWithValue }) => {
  const { data = [], status } = await WorkflowHttpClient.get(`/templates/${payload}`, {
    cancelToken,
  })

  if (status !== 200) {
    return rejectWithValue('Template is not exist')
  }

  return data
}

export const createTemplate = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.post('/templates', payload, { cancelToken })
  return data
}

export const editTemplate = async ({ template, templateName }, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.put(`/templates/${templateName}`, template, {
    cancelToken,
  })
  return data
}

const TemplateService = {
  getTemplate,
  getTemplates,
  editTemplate,
  createTemplate,
}

export default TemplateService
