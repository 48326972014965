import { omit, reject } from 'lodash'
import { ActionTypes } from './actions'

export const initialState = { byId: {}, raw: [], ids: [], odata: {}, fetching: false }

export default (state = initialState, action) => {
  const { type, error, payload = {} } = action

  switch (type) {
    case ActionTypes.STOCKITEMS_REQUEST:
    case ActionTypes.READ_STOCKITEM_REQUEST:
      return { ...state, fetching: true }
    case ActionTypes.STOCKITEMS_SUCCESS:
    case ActionTypes.READ_STOCKITEM_SUCCESS: {
      const { items, odata } = payload

      let ids = []
      let byId = {}

      items.forEach((item) => {
        ids = [...ids, item.id]
        byId = { ...byId, [item.id]: item }
      })

      return {
        byId,
        raw: items,
        ids,
        odata: { ...state.odata, ...odata },
        fetching: false,
      }
    }
    case ActionTypes.ADD_STOCKITEM_SUCCESS:
    case ActionTypes.UPDATE_STOCKITEM_SUCCESS: {
      const { id } = payload

      return {
        ...state,
        byId: { ...state.byId, [id]: payload },
        raw: [...reject(state.raw, (item) => item.id === id), payload],
        ids: [...reject(state.ids, (item) => item === id), id],
        fetching: false,
      }
    }
    case ActionTypes.DELETE_STOCKITEM_SUCCESS: {
      const { id, ...rest } = payload

      return {
        byId: { ...omit(state.byId, [id]), [id]: rest },
        raw: reject(state.raw, (item) => item.id === id),
        ids: reject(state.ids, (item) => item === id),
        fetching: false,
      }
    }

    case ActionTypes.STOCKITEMS_FAILURE:
    case ActionTypes.READ_STOCKITEM_FAILURE:
    case ActionTypes.ADD_STOCKITEM_FAILURE:
    case ActionTypes.UPDATE_STOCKITEM_FAILURE:
    case ActionTypes.DELETE_STOCKITEM_FAILURE: {
      return { ...state, fetching: false, error }
    }
    default:
      return state
  }
}
