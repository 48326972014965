import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import { getWoServices, createWoService } from 'services/woServices'
import { withThunkApi } from 'services/axios.utils'

const woServicesAdapter = createEntityAdapter()

export const fetchWoServicesThunk = createAsyncThunk('hive/woServices', withThunkApi(getWoServices))

export const createWoServiceThunk = createAsyncThunk(
  'hive/woServices/create',
  withThunkApi(createWoService),
)

const WoServicesSlice = createSlice({
  name: 'hive/woServices',
  initialState: woServicesAdapter.getInitialState({
    loading: 'idle',
    error: null,
    recentlyAdded: null,
  }),
  extraReducers: {
    [fetchWoServicesThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [fetchWoServicesThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      woServicesAdapter.setAll(state, action.payload.value)
    },
    [fetchWoServicesThunk.rejected]: (state) => {
      state.loading = 'idle'
    },
    [createWoServiceThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [createWoServiceThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [createWoServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.recentlyAdded = action.payload
        woServicesAdapter.addOne(state, action.payload)
      }
    },
  },
})

export const woServicesSelector = woServicesAdapter.getSelectors()

export default WoServicesSlice.reducer
