import React, { useMemo } from 'react'
import { Col, Empty, Row, Space } from 'antd'
import Phone from 'components/AlarmHiveComponents/Phone/Phone'
import { Link } from 'react-router-dom'
import { formatText } from 'services/utils'

import SimpleSearchResult from './SimpleSearchResult'

import styles from './style.module.scss'

const getRawValue = (obj) => obj?.raw ?? ''
const getSnippetValue = (obj) => obj?.snippet || getRawValue(obj)

function BaseSpaceView({ value, renderSn = () => {}, renderRw = () => {} }) {
  const { snippet, raw } = value

  let snippetIdx = -1

  if (snippet) {
    const rawValue = snippet.replace(/(<em>|<\/em>)/g, '')
    snippetIdx = raw.findIndex((val) => val === rawValue)
  }

  return (
    <Space wrap>
      {raw.map((v, idx, l) => (
        <span key={v}>
          {snippet && idx === snippetIdx ? renderSn(v, snippet) : renderRw(v)}
          {idx !== l.length - 1 ? ',' : null}
        </span>
      ))}
    </Space>
  )
}
function PhoneSearchResult({ value }) {
  if (Array.isArray(value.raw)) {
    const renderSn = (v) => (
      <span>
        <em>{formatText(v, '(xxx)xxx-xxxx', 'x')}</em>
      </span>
    )

    const renderRw = (v) => <Phone value={v} />

    return <BaseSpaceView value={value} renderSn={renderSn} renderRw={renderRw} />
  }

  return <Phone value={getSnippetValue(value)} />
}

function AddressSearchResult({ value }) {
  if (Array.isArray(value.raw)) {
    const renderSn = (v, snippet) => <span dangerouslySetInnerHTML={{ __html: snippet }} />
    const renderRw = (v) => v

    return <BaseSpaceView value={value} renderSn={renderSn} renderRw={renderRw} />
  }

  return <span dangerouslySetInnerHTML={{ __html: getSnippetValue(value) }} />
}

export default function SearchResult({ data = {}, executed = false, onItemClick }) {
  const any = useMemo(() => Object.keys(data).some((key) => data[key]?.results?.length > 0), [data])

  if (!executed) return null

  if (!any) {
    return <Empty image={null} description={<span>No search results found</span>} />
  }

  return (
    <div>
      <SimpleSearchResult
        title="Contacts"
        data={data}
        searchIndexName="customer"
        renderItem={(item) => (
          <>
            <div className={styles.resultText}>
              <Link
                to={`/contacts/${getRawValue(item.id)}`}
                className="text-default"
                onClick={onItemClick}
              >
                <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.customer_name) }} />
              </Link>
            </div>
            <div className={styles.resultSource}>
              <Row gutter={24} style={{ flexDirection: 'column' }}>
                <Col lg={24}>
                  <strong>Contact ID:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.id_str) }} />
                </Col>
                {item.email.raw && (
                  <Col lg={24}>
                    <strong>Email:</strong>{' '}
                    <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.email) }} />
                  </Col>
                )}
                {item.domain.raw && (
                  <Col lg={24}>
                    <strong>Website:</strong>{' '}
                    <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.domain) }} />
                  </Col>
                )}
                {item.external_contact_id.raw && (
                  <Col lg={24}>
                    <strong>External Contact ID:</strong>{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getSnippetValue(item.external_contact_id),
                      }}
                    />
                  </Col>
                )}

                {item.phones.raw && (
                  <Col lg={24}>
                    <strong>Phone:</strong> <PhoneSearchResult value={item.phones} />
                  </Col>
                )}
                {item.addresses.raw && (
                  <Col lg={24}>
                    <strong>Addresses:</strong> <AddressSearchResult value={item.addresses} />
                  </Col>
                )}
              </Row>
            </div>
          </>
        )}
      />
      <SimpleSearchResult
        title="Monitoring"
        data={data}
        searchIndexName="customermonitoring"
        renderItem={(item) => (
          <>
            <div className={styles.resultText}>
              <Link
                to={`/contacts/${getRawValue(item.customer_id)}?tab=monitoringTab`}
                className="text-default"
                onClick={onItemClick}
              >
                Account ID -{' '}
                <span
                  dangerouslySetInnerHTML={{ __html: getSnippetValue(item.central_station_id) }}
                />
              </Link>
            </div>
            <div className={styles.resultSource}>
              <Row gutter={24} style={{ flexDirection: 'column' }}>
                {item.company_name.raw ? (
                  <Col lg={24}>
                    <strong>Company Name:</strong>{' '}
                    <span
                      dangerouslySetInnerHTML={{ __html: getSnippetValue(item.company_name) }}
                    />
                  </Col>
                ) : (
                  <>
                    {item.customer_first_name && (
                      <Col lg={24}>
                        <strong>First Name:</strong>{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getSnippetValue(item.customer_first_name),
                          }}
                        />
                      </Col>
                    )}
                    {item.customer_first_name && (
                      <Col lg={24}>
                        <strong>Last Name:</strong>{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getSnippetValue(item.customer_last_name),
                          }}
                        />
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </div>
          </>
        )}
      />
      <SimpleSearchResult
        title="Work Orders"
        data={data}
        searchIndexName="workorder"
        renderItem={(item) => (
          <>
            <div className={styles.resultText}>
              <Link
                to={`/contacts/${getRawValue(item.customer_id)}?tab=workOrderTab`}
                className="text-default"
                onClick={onItemClick}
              >
                Work Order -{' '}
                <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.id_str) }} />
              </Link>
            </div>
            <div className={styles.resultSource}>
              <Space>
                <span>
                  <strong>Description: </strong>
                  {getSnippetValue(item.description).length ? (
                    <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.description) }} />
                  ) : (
                    '-'
                  )}
                </span>
              </Space>
            </div>
          </>
        )}
      />
      <SimpleSearchResult
        title="Quotes"
        data={data}
        searchIndexName="quote"
        renderItem={(item) => (
          <>
            <div className={styles.resultText}>
              <Link
                to={`/contacts/${getRawValue(item.customer_id)}?tab=quoteTab`}
                className="text-default"
                onClick={onItemClick}
              >
                Quote - <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.id_str) }} />
              </Link>
            </div>
          </>
        )}
      />
      <SimpleSearchResult
        title="Inventory"
        data={data}
        searchIndexName="inventory"
        renderItem={(item) => (
          <>
            <div className={styles.resultText}>
              <Link
                to={`/inventory/${getRawValue(item.id)}`}
                className="text-default"
                onClick={onItemClick}
              >
                Item Number -{' '}
                <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.item_number) }} />
              </Link>
            </div>
            <div className={styles.resultSource}>
              <Space>
                <span>
                  <strong>Description: </strong>
                  {getSnippetValue(item.description).length ? (
                    <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.description) }} />
                  ) : (
                    '-'
                  )}
                </span>
              </Space>
            </div>
          </>
        )}
      />
      <SimpleSearchResult
        title="Users"
        data={data}
        searchIndexName="user"
        renderItem={(item) => (
          <>
            <div className={styles.resultText}>
              <Link
                to={`/setup/users/user-list/${getRawValue(item.id)}`}
                className="text-default"
                onClick={onItemClick}
              >
                <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.first_name) }} />{' '}
                <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.last_name) }} />
              </Link>
            </div>
            <div className={styles.resultSource}>
              <Row gutter={24} style={{ flexDirection: 'column' }}>
                <Col lg={24}>
                  <strong>User ID:</strong>{' '}
                  <span dangerouslySetInnerHTML={{ __html: getSnippetValue(item.id_str) }} />
                </Col>
              </Row>
            </div>
          </>
        )}
      />
    </div>
  )
}
