import HttpClient from 'services/api'
import buildQuery from 'odata-query'

export const getSalesPersons = async (payload, { cancelToken } = {}) => {
  const query = buildQuery({
    ...payload,
    filter: {
      or: {
        ...payload.filter,
        or: { userTypes: { any: "usertypes eq 'SalesPerson'" } },
      },
    },
  })

  const { data = {} } = await HttpClient.get(`/users${query}`, { cancelToken })
  return data.value
}

export default getSalesPersons
