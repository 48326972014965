import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const fetchCustomer = async ({ id, odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/customers/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data
}

const fetchCustomers = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/customers/${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const fetchCustomerWorkorder = async (
  { id, workOrderId, odataParams = {} },
  { cancelToken } = {},
) => {
  const { data = {} } = await HttpClient.get(
    `/customers/${id}/workorders/${workOrderId}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )

  delete data['@odata.context']
  return data
}

const fetchCustomerWorkorders = async ({ id, odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/customers/${id}/workorders${buildQuery(odataParams)}`,
    { cancelToken },
  )
  return data
}

const fetchCustomerAddresses = async (id, { cancelToken } = {}) =>
  fetchCustomer(
    {
      id,
      odataParams: {
        expand: { addresses: true },
        select: ['addresses'],
      },
    },
    { cancelToken },
  )

const updateCustomer = async ({ id, values }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`/customers/${id}`, values, { cancelToken })
  return data
}

const getCustomerAttachments = async ({ id, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/customers/${id}/attachments${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data
}

const uploadCustomerAttachment = async ({ id, values }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/customers/${id}/attachments`, values, {
    cancelToken,
  })
  return data
}

const CustomerService = {
  fetchCustomer,
  fetchCustomers,
  updateCustomer,
  fetchCustomerWorkorder,
  fetchCustomerWorkorders,
  fetchCustomerAddresses,
  getCustomerAttachments,
  uploadCustomerAttachment,
}

export default CustomerService
