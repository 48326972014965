import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getEmergencyContacts,
  getEmergencyContactPhoneTypes,
  createEmergencyContact,
  syncEmergencyContact,
  editEmergencyContact,
  deleteEmergencyContact,
} from 'services/emergencyContacts'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const createEmergencyContactThunk = createAsyncThunk(
  'hive/emergencyContacts/create',
  withThunkApi(createEmergencyContact),
)

export const fetchEmergencyContactsThunk = createAsyncThunk(
  'hive/emergencyContacts/getById',
  withThunkApi(getEmergencyContacts),
)

export const fetchEmergencyContactPhoneTypesThunk = createAsyncThunk(
  'hive/emergencyContacts/get/phoneTypes',
  withThunkApi(getEmergencyContactPhoneTypes),
)

export const syncEmergencyContactThunk = createAsyncThunk(
  'hive/emergencyContacts/sync',
  withThunkApi(syncEmergencyContact),
)

export const updateEmergencyContactThunk = createAsyncThunk(
  'hive/emergencyContacts/update',
  withThunkApi(editEmergencyContact),
)

export const removeEmergencyContactThunk = createAsyncThunk(
  'hive/emergencyContacts/delete',
  withThunkApi(deleteEmergencyContact),
)

const emergencyContactsAdapter = createEntityAdapter()

const emergencyContactsSlice = createSlice({
  name: 'hive/emergencyContacts',
  initialState: emergencyContactsAdapter.getInitialState({
    loading: 'idle',
    error: false,
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchEmergencyContactsThunk.pending,

        createEmergencyContactThunk.pending,
        fetchEmergencyContactPhoneTypesThunk.pending,
        syncEmergencyContactThunk.pending,
        updateEmergencyContactThunk.pending,
        removeEmergencyContactThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') state.loading = 'pending'
      },
    ),
    ...mergeActionTypes(
      [
        fetchEmergencyContactsThunk.rejected,

        createEmergencyContactThunk.rejected,
        fetchEmergencyContactPhoneTypesThunk.rejected,
        syncEmergencyContactThunk.rejected,
        updateEmergencyContactThunk.rejected,
        removeEmergencyContactThunk.rejected,
      ],
      (state, action) => {
        state.loading = 'idle'
        if (action.payload) {
          state.error = action.payload.Message
        } else {
          state.error = action.error
        }
      },
    ),

    [fetchEmergencyContactsThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      const toRemove = action.payload.map((item) => item.id)
      emergencyContactsAdapter.removeMany(state, toRemove)
      emergencyContactsAdapter.addMany(state, action.payload)
    },
    [updateEmergencyContactThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      emergencyContactsAdapter.upsertOne(state, action.payload)
    },
    [createEmergencyContactThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        emergencyContactsAdapter.addOne(state, action.payload)
      }
    },
    [removeEmergencyContactThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        emergencyContactsAdapter.removeOne(state, action.meta.arg?.contactId)
      }
    },
  },
})

export const emergencyContactsSelectors = emergencyContactsAdapter.getSelectors()

export default emergencyContactsSlice.reducer
