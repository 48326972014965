import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Space, Divider } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import store from 'store'
import _ from 'lodash'
import ProfileMenu from 'components/LayoutComponents/TopBar/ProfileMenu'
import Toolbar from 'components/AlarmHiveComponents/Toolbar/Toolbar'

import { hasPermission } from 'services/utils'

import styles from './style.module.scss'

const { SubMenu } = Menu

export default function MenuTop() {
  const dispatch = useDispatch()

  const menuData = useSelector((state) => state.menu.menuTopData)
  const isLightTheme = useSelector((state) => state.settings.isLightTheme)
  const isSettingsOpen = useSelector((state) => state.settings.isSettingsOpen)
  const logoSrc = useSelector(
    (state) => state.merchant.entity.logoUrl ?? 'resources/images/logo-hive.png',
  )
  const user = useSelector((state) => state.user)
  const location = useLocation()

  const [selectedKeys, setSelectedKey$] = useState(store.get('app.menu.selectedKeys') || [])

  const setSelectedKeys = useCallback(() => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), ['url', location.pathname])
    setSelectedKey$(selectedItem ? [selectedItem.key] : [])
  }, [menuData])

  useEffect(() => {
    setSelectedKeys()

    return () => {
      setSelectedKeys()
    }
  }, [menuData])

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    setSelectedKey$([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, icon, disabled, permissions = [] } = item

      const authorized = hasPermission(permissions, user.permissions)

      if (!authorized) return null

      if (item.divider) {
        return <Menu.Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon}`} />}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span className={styles.menu} key={menuItem.key}>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
              <span className={styles.title}>{menuItem.title}</span>
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      const { permissions = [] } = menuItem
      const authorized = hasPermission(permissions, user.permissions)

      if (!authorized) return null

      if (menuItem.children) {
        return generateSubmenu([menuItem])
      }
      return generateItem(menuItem)
    })
  }

  return (
    <header>
      <div className={styles.logo}>
        <div className={styles.logoContainer}>
          <Link to="/dashboard">
            <img src={logoSrc} alt="logo" />
          </Link>
        </div>
      </div>
      <div className={styles.profileNoAvatar}>
        <Space split={<Divider type="vertical" style={{ height: '1.5em' }} />}>
          <Toolbar />
          <ProfileMenu />
        </Space>
      </div>
      <div id="menu-container">
        <Menu
          theme={isLightTheme ? 'light' : 'dark'}
          onClick={handleClick}
          selectedKeys={selectedKeys}
          mode="horizontal"
        >
          {generateMenuItems()}
        </Menu>
      </div>
    </header>
  )
}
