import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import MonitoringService from 'services/monitoring'
import { mergeActionTypes } from 'services/utils'

export const getEventCodesThunk = createAsyncThunk(
  'hive/eventCodes/get',
  withThunkApi(MonitoringService.getEventCodes),
)

export const createEventCodeThunk = createAsyncThunk(
  'hive/eventCodes/post',
  withThunkApi(MonitoringService.createEventCodes),
)

export const getEventCodeThunk = createAsyncThunk(
  'hive/eventCodes/get',
  withThunkApi(MonitoringService.getEventCodes),
)

const eventCodesAdapter = createEntityAdapter()

const eventCodesSlice = createSlice({
  name: 'hive/eventCodes',
  initialState: eventCodesAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [getEventCodesThunk.pending, getEventCodeThunk.pending, createEventCodeThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),

    ...mergeActionTypes(
      [getEventCodesThunk.fulfilled, getEventCodeThunk.fulfilled],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
        eventCodesAdapter.setAll(state, action.payload)
      },
    ),

    ...mergeActionTypes([getEventCodesThunk.rejected, getEventCodeThunk.rejected], (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    }),

    [createEventCodeThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [createEventCodeThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.latest = action.payload
        eventCodesAdapter.addOne(state, action.payload)
      }
    },
  },
})

export const eventCodesActions = eventCodesSlice.actions
export const eventCodesSelectors = eventCodesAdapter.getSelectors()

export default eventCodesSlice.reducer
