import buildQuery from 'odata-query'
import HttpClient from 'services/api'

export const getAttachmentTypes = async ({ odataParams = {} }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`AttachmentTypes${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data.value
}

export const getAttachmentType = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data = [] } = await HttpClient.get(`/AttachmentTypes/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data
}

export const editAttachmentType = async ({ id, ...values }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`/AttachmentTypes/${id}`, values, { cancelToken })

  return data
}

export const createAttachmentType = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post('/AttachmentTypes', payload, { cancelToken })

  return data
}

const AttachmentTypeService = {
  getAttachmentTypes,
  editAttachmentType,
  createAttachmentType,
  getAttachmentType,
}

export default AttachmentTypeService
