import { all, takeLatest, put } from 'redux-saga/effects'
import { updateWorkOrderThunk, fetchCustomerWorkorder, createWorkOrderThunk } from './slice'

function* reloadWorkOrder(action) {
  const {
    meta: { arg = {} },
    payload,
  } = action

  const { customerId, id } = payload
  const { _sagas_ } = arg

  if (_sagas_.reload) {
    yield put(
      fetchCustomerWorkorder({
        id: customerId,
        workOrderId: id,
        odataParams: {
          select: [
            'id',
            'statusId',
            'status',
            'techId',
            'tech',
            'customerId',
            'installLocationId',
            'createdBy',
            'creator',
            'workflowInstanceId',
            'workflowName',
            'salesLocationId',
            'salesLocation',
            'salesPersonId',
            'salesPerson',
            'createdAt',
            'description',
            'warehouseId',
            'installLocation',
          ],
          expand: {
            warehouse: { select: ['id', 'name'] },
            installLocation: true,
            status: { select: ['id', 'name'] },
            salesLocation: { select: ['id', 'name'] },
            tech: { select: ['id', 'firstName', 'lastName'] },
            salesPerson: { select: ['id', 'firstName', 'lastName'] },
            creator: { select: ['id', 'firstName', 'lastName'] },
          },
        },
      }),
    )
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(createWorkOrderThunk.fulfilled, reloadWorkOrder),
    takeLatest(updateWorkOrderThunk.fulfilled, reloadWorkOrder),
  ])
}
