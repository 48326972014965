export default function getSetupPageMenu(baseUrl = '') {
  return [
    {
      title: 'Users',
      key: 'users',
      icon: 'icmn icmn-location',
      permissions: ['UserManagement.Read', 'RightManagement.Read'],
      children: [
        {
          title: 'User List',
          key: 'user-list',
          url: `${baseUrl}/users/user-list`,
          icon: 'icmn icmn-location',
          permissions: ['UserManagement.Read'],
        },
        {
          title: 'Rights Templates',
          key: 'roles',
          url: `${baseUrl}/users/roles`,
          icon: 'icmn icmn-location',
          permissions: ['RightManagement.Read'],
        },
      ],
    },
    {
      title: 'Billing',
      key: 'billing',
      icon: 'icmn icmn-location',
      permissions: ['RecurringTypesManagement.Read'],
      children: [
        {
          title: 'Recurring Types',
          key: 'recurring-types',
          url: `${baseUrl}/billing/recurring-types`,
          icon: 'icmn icmn-location',
          permissions: ['RecurringTypesManagement.Read'],
        },
      ],
    },
    {
      title: 'System',
      key: 'system',
      icon: 'icmn icmn-location',
      permissions: ['ContactStatus.Read', 'WorkOrderStatusManagement.Read'],
      children: [
        {
          title: 'Contact Statuses',
          key: 'contact-status',
          url: `${baseUrl}/system/contact-status`,
          icon: 'icmn icmn-location',
          permissions: ['ContactStatus.Read'],
        },
        {
          title: 'Distributors',
          key: 'distributors',
          url: `${baseUrl}/inventory/distributors`,
          icon: 'icmn icmn-location',
          permissions: ['Distributor.Read'],
        },
        {
          title: 'Work Order Statuses',
          key: 'wo-status',
          url: `${baseUrl}/system/wo-status`,
          icon: 'icmn icmn-location',
          permissions: ['WorkOrderStatusManagement.Read'],
        },
        {
          title: 'Attachment Type',
          key: 'attachment-type',
          url: `${baseUrl}/system/attachment-type`,
          icon: 'icmn icmn-location',
          permissions: ['AttachmentTypeManagement.Read'],
        },
      ],
    },
    {
      title: 'Services',
      key: 'services',
      url: `${baseUrl}/inventory/services`,
      icon: 'icmn icmn-location',
      permissions: ['ServicesManagement.Read'],
    },
    {
      title: 'Locations',
      key: 'locations',
      url: `${baseUrl}/locations`,
      icon: 'icmn icmn-location',
      permissions: ['Locations.Read'],
    },
    {
      title: 'Layout Templates',
      key: 'layout-templates',
      url: `${baseUrl}/layout-templates`,
      icon: 'icmn icmn-location',
      permissions: ['SetupTemplates.Read'],
    },
    {
      title: 'Monitoring',
      key: 'monitoring',
      icon: 'icmn icmn-location',
      permissions: [
        'MonitoringStations.Read',
        'SecurityLocations.Read',
        'AvantGuardDeviceType.Read',
        'GenericPanelTypesManagement.Read',
        'GenericCellPlanManagement.Read',
        'EventCodesManagement.Read',
      ],
      children: [
        {
          title: 'Central Station & Cell Forwarding',
          key: 'stations',
          url: `${baseUrl}/monitoring/stations`,
          icon: 'icmn icmn-location',
          permissions: ['MonitoringStations.Read'],
        },
        {
          title: 'Security Locations',
          key: 'security-locations',
          url: `${baseUrl}/monitoring/security-locations`,
          icon: 'icmn icmn-location',
          permissions: ['SecurityLocations.Read'],
        },
        {
          title: 'AvantGuard Device Types',
          key: 'device-types',
          url: `${baseUrl}/monitoring/device-types`,
          icon: 'icmn icmn-location',
          permissions: ['AvantGuardDeviceType.Read'],
        },
        {
          title: 'Generic Panel Types',
          key: 'generic-panel-types',
          url: `${baseUrl}/monitoring/generic-panel-types`,
          icon: 'icmn icmn-location',
          permissions: ['GenericPanelTypesManagement.Read'],
        },
        {
          title: 'Generic Cell Plans',
          key: 'generic-cell-plans',
          url: `${baseUrl}/monitoring/generic-cell-plans`,
          icon: 'icmn icmn-location',
          permissions: ['GenericCellPlanManagement.Read'],
        },
        {
          title: 'Event Codes',
          key: 'event-codes',
          url: `${baseUrl}/monitoring/event-codes`,
          icon: 'icmn icmn-location',
          permissions: ['EventCodesManagement.Read'],
        },
        {
          title: 'Receiver Number Management',
          key: 'receiver-number-management',
          url: `${baseUrl}/monitoring/receiver-number-management`,
          icon: 'icmn icmn-location',
          permissions: ['ReceiverNumberManagement.Read'],
        },
        {
          title: 'Account Number Management',
          key: 'account-number-management',
          url: `${baseUrl}/monitoring/account-number-management`,
          icon: 'icmn icmn-location',
          permissions: ['AccountNumberManagement.Read'],
        },
      ],
    },
    {
      title: 'Workflow Design',
      key: 'workflow-design',
      url: `${baseUrl}/workflow-design`,
      icon: 'icmn icmn-location',
      permissions: ['WorkflowDesign.Read'],
    },
    {
      title: 'Attachment',
      key: 'attachment',
      icon: 'icmn icmn-location',
      permissions: ['DocumentTemplateManagement.Read'],
      children: [
        {
          title: 'Document Template',
          key: 'document-template',
          url: `${baseUrl}/attachment/document-template`,
          icon: 'icmn icmn-location',
          permissions: ['DocumentTemplateManagement.Read'],
        },
      ],
    },
  ]
}
