import WorkflowHttpClient from 'services/api/workflow'

export const addFieldRecord = ({ templateName, fieldName, record }, { cancelToken } = {}) =>
  WorkflowHttpClient.post(`/templates/${templateName}/fields/${fieldName}/data/records`, record, {
    cancelToken,
  })

export const executeCommand = async ({ instanceId, commandName }, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.post(
    `/instances/${instanceId}/commands/${commandName}/execution`,
    {},
    { cancelToken },
  )
  return data
}

const WorkflowService = { addFieldRecord, executeCommand }

export default WorkflowService
