import buildQuery from 'odata-query'
import PaymentHttpClient from 'services/api/payment'

export const getRecurringBillings = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.get(
    `/recurringBillings${buildQuery(payload.odataParams)}`,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

export const getRecurringBillingById = async (id, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.get(`/recurringBillings/${id}`, { cancelToken })
  delete data['@odata.context']
  return data
}

export const createRecurringBilling = async (payload, { cancelToken } = {}) => {
  const { data } = await PaymentHttpClient.post(`/recurringBillings`, payload, { cancelToken })
  delete data['@odata.context']
  return data
}

export const updateRecurringBilling = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.patch(`/recurringBillings/${payload.id}`, payload, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}

export const getRecurringPayments = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.get(
    `/recurringPayments${buildQuery(payload.odataParams)}`,
    { cancelToken },
  )
  delete data['@odata.context']
  return data.value
}

export const createRecurringPayment = async (payload, { cancelToken } = {}) => {
  const {
    data,
  } = await PaymentHttpClient.post(
    `/recurringBillings/${payload.recurringBillingId}/payments`,
    payload,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}
