import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import PanelTypesService from 'services/panelTypes'
import { mergeActionTypes } from 'services/utils'

export const getPanelTypesThunk = createAsyncThunk(
  'hive/panelTypes/list',
  withThunkApi(PanelTypesService.getPanelTypes),
)

export const getPanelTypeThunk = createAsyncThunk(
  'hive/panelTypes/get',
  withThunkApi(PanelTypesService.getPanelType),
)

export const createPanelTypeThunk = createAsyncThunk(
  'hive/panelTypes/create',
  withThunkApi(PanelTypesService.createPanelType),
)

export const updatePanelTypesThunk = createAsyncThunk(
  'hive/panelTypes/update',
  withThunkApi(PanelTypesService.updatePanelTypes),
)

const panelTypesAdapter = createEntityAdapter()

const panelTypesSlice = createSlice({
  name: 'hive/panelTypes',
  initialState: panelTypesAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getPanelTypesThunk.pending,
        getPanelTypeThunk.pending,
        createPanelTypeThunk.pending,
        updatePanelTypesThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),

    [getPanelTypesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      panelTypesAdapter.setAll(state, action.payload.value)
    },

    [createPanelTypeThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      state.latest = action.payload
      panelTypesAdapter.upsertOne(state, action.payload)
    },

    ...mergeActionTypes(
      [getPanelTypeThunk.fulfilled, updatePanelTypesThunk.fulfilled],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          panelTypesAdapter.upsertOne(state, action.payload)
        }
      },
    ),

    ...mergeActionTypes(
      [
        getPanelTypesThunk.rejected,
        getPanelTypeThunk.rejected,
        createPanelTypeThunk.rejected,
        updatePanelTypesThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
  },
})

export const panelTypesActions = panelTypesSlice.actions
export const panelTypesSelectors = panelTypesAdapter.getSelectors()

export default panelTypesSlice.reducer
