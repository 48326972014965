import React from 'react'
import { Button, Result } from 'antd'
import { createHashHistory } from 'history'

const history = createHashHistory()

const Exception403 = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you don't have access to this page."
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        Back Home
      </Button>
    }
  />
)

export default Exception403
