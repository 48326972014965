import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import { getRecurringPayments, createRecurringPayment } from 'services/recurringPayments'
import { withThunkApi } from 'services/axios.utils'
import { mergeActionTypes } from 'services/utils'

export const getRecurringPaymentsThunk = createAsyncThunk(
  'hive/recurringPayments/list',
  withThunkApi(getRecurringPayments),
)

export const createRecurringPaymentThunk = createAsyncThunk(
  'hive/recurringPayments/create',
  withThunkApi(createRecurringPayment),
)

const recurringPaymentsAdapter = createEntityAdapter()

const recurringPaymentssSlice = createSlice({
  name: 'hive/recurringPayments',
  initialState: recurringPaymentsAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    ...mergeActionTypes(
      [getRecurringPaymentsThunk.pending, createRecurringPaymentThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),

    [getRecurringPaymentsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      recurringPaymentsAdapter.setAll(state, action.payload)
    },

    [createRecurringPaymentThunk.fulfilled]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },

    ...mergeActionTypes(
      [getRecurringPaymentsThunk.pending, createRecurringPaymentThunk.pending],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
  },
})

export const recurringPaymentsSelectors = recurringPaymentsAdapter.getSelectors()

export default recurringPaymentssSlice.reducer
