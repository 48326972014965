import { useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useSelector, useDispatch } from 'react-redux'
import { notification } from 'antd'

// The componenent will be refactored a bit after we will implement refresh_token support
export default function IdleTimer() {
  const dispatch = useDispatch()

  const authorized = useSelector((state) => state.user.authorized)

  const timeoutMinutes = 20

  const timeout = 1000 * 60 * timeoutMinutes

  const handleOnIdle = () => {
    dispatch({
      type: 'user/LOGOUT',
    })

    notification.info({
      key: 'idleinfo',
      message: 'Logged out',
      description: 'You have been automatically logged out due to an idle timeout',
      duration: 0,
    })
  }

  const handleOnAction = () => {
    notification.close('idleinfo')
  }

  const { reset } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    startOnMount: false,
    stopOnIdle: true,
  })

  useEffect(() => {
    if (authorized) {
      reset()
    }
  }, [authorized])

  return null
}
