import { put, call, all, takeLatest } from 'redux-saga/effects'
import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { parseResponse } from 'services/odata'
import {
  ActionTypes,
  getLocationsSuccess,
  getLocationsFailure,
  getLocationSuccess,
  getLocationFailure,
  createLocationSuccess,
  createLocationFailure,
  updateLocationSuccess,
  updateLocationFailure,
  removeLocationSuccess,
  removeLocationFailure,
} from './actions'

export function* getAllLocations({ payload }) {
  try {
    const { data } = yield call(HttpClient.get, `/locations${buildQuery(payload)}`)
    yield put(
      getLocationsSuccess({
        items: data.value,
        odata: parseResponse(data).odata,
      }),
    )
  } catch (error) {
    yield put(getLocationsFailure(error))
  }
}

export function* getLocation({ id }) {
  try {
    const { data } = yield call(HttpClient.get, `/locations/${id}`)
    yield put(
      getLocationSuccess({
        items: [data],
      }),
    )
  } catch (error) {
    yield put(getLocationFailure(error))
  }
}

export function* createLocation({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.post, '/locations', payload)
    yield put(createLocationSuccess(data))
    callback()
  } catch (error) {
    yield put(createLocationFailure(error))
  }
}

export function* updateLocation({ id, payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/locations/${id}`, payload)
    yield put(updateLocationSuccess(data))
    callback()
  } catch (error) {
    yield put(updateLocationFailure(error))
  }
}

export function* removeLocation({ id, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/locations/${id}`)
    yield put(removeLocationSuccess(data))
    callback()
  } catch (error) {
    yield put(removeLocationFailure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.LOCATIONS_REQUEST, getAllLocations),
    takeLatest(ActionTypes.READ_LOCATION_REQUEST, getLocation),
    takeLatest(ActionTypes.ADD_LOCATION_REQUEST, createLocation),
    takeLatest(ActionTypes.UPDATE_LOCATION_REQUEST, updateLocation),
    takeLatest(ActionTypes.DELETE_LOCATION_REQUEST, removeLocation),
  ])
}
