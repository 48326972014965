import actions from './actions'

const initialState = {
  loading: false,
  fetched: false,
  items: [],
  countryList: [],
  selectedState: null,
  selectedCountry: 'US',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SELECTED_STATE:
      return { ...state, selectedState: action.payload }
    case actions.SET_SELECTED_COUNTRY:
      return { ...state, selectedCountry: action.payload }
    case actions.LOAD_COUNTRY_LIST:
      return { ...state, countryList: action.payload || [] }
    default:
      return state
  }
}
