import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getStockItemGroups = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/stockitemgroups${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const getStockItemGroup = async ({ id }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/stock-item-groups/${id}`, { cancelToken })
  return data
}

const StockItemGroupService = {
  getStockItemGroups,
  getStockItemGroup,
}

export default StockItemGroupService
