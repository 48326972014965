import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getAllInventory = async (payload, { cancelToken } = {}) => {
  const { data } = await HttpClient.get(`/inventories${buildQuery(payload)}`, { cancelToken })
  return data
}

const getInventory = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload

  const { data } = await HttpClient.get(`/inventories/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data
}

const createInventory = async (payload, { cancelToken } = {}) => {
  const { data } = await HttpClient.post(`/inventories`, payload, { cancelToken })
  return data
}

const updateInventory = async (payload, { cancelToken } = {}) => {
  const { id, values } = payload
  const { data } = await HttpClient.put(`/inventories/${id}`, values, { cancelToken })
  return data
}

const InventoryService = {
  getAllInventory,
  getInventory,
  createInventory,
  updateInventory,
}

export default InventoryService
