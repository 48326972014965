import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import {
  updateWorkflowScheme,
  getWorkflowSchemaStates,
  getWorkflowSchemaByCode,
  getWorkflowSchemas,
  createWorkflowScheme,
  getWorkflowStatesByType,
  getWorkflowCommandsByType,
} from 'services/workflowScheme'
import { withThunkApi } from 'services/axios.utils'

const workflowSchemasAdapter = createEntityAdapter()

export const fetchWorkflowSchemasThunk = createAsyncThunk(
  'hive/workflowSchemas',
  withThunkApi(getWorkflowSchemas),
)

export const fetchWorkflowSchemaThunk = createAsyncThunk(
  'hive/workflowSchemaById',
  withThunkApi(getWorkflowSchemaByCode),
)

export const getWorkflowSchemaStatesThunk = createAsyncThunk(
  'hive/workflowSchemasStates/getById',
  withThunkApi(getWorkflowSchemaStates),
)

export const updateWorkflowSchemaThunk = createAsyncThunk(
  'hive/workflowSchema/update',
  withThunkApi(updateWorkflowScheme),
)

export const createWorkflowSchemaThunk = createAsyncThunk(
  'hive/workflowSchema/create',
  withThunkApi(createWorkflowScheme),
)

export const getWorkflowStatesByTypeThunk = createAsyncThunk(
  'hive/workflowStates/getByType',
  withThunkApi(getWorkflowStatesByType),
)

export const getWorkflowCommandsByTypeThunk = createAsyncThunk(
  'hive/workflowCommands/getByType',
  withThunkApi(getWorkflowCommandsByType),
)

const workflowSchemasSlice = createSlice({
  name: 'hive/workflowSchemas',
  initialState: workflowSchemasAdapter.getInitialState({
    loading: 'idle',
    entity: {},
    wfStates: [],
    wfCommands: [],
  }),
  extraReducers: {
    [fetchWorkflowSchemasThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [fetchWorkflowSchemasThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workflowSchemasAdapter.setAll(state, action.payload)
      }
    },
    [fetchWorkflowSchemasThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [fetchWorkflowSchemaThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [fetchWorkflowSchemaThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.entity = action.payload
      }
    },
    [fetchWorkflowSchemaThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [getWorkflowSchemaStatesThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getWorkflowSchemaStatesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workflowSchemasAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: { activities: action.payload },
        })
      }
    },
    [getWorkflowSchemaStatesThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [updateWorkflowSchemaThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [updateWorkflowSchemaThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.entity = action.payload
      }
    },
    [updateWorkflowSchemaThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [createWorkflowSchemaThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [createWorkflowSchemaThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workflowSchemasAdapter.upsertOne(state, action.payload)
      }
    },
    [createWorkflowSchemaThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [getWorkflowStatesByTypeThunk.fulfilled]: (state, action) => {
      state.wfStates = action.payload
    },
    [getWorkflowCommandsByTypeThunk.fulfilled]: (state, action) => {
      state.wfCommands = action.payload
    },
  },
})

const selectAllCustomers = createSelector(
  (state) => workflowSchemasSelectors.selectAll(state.workflowSchemas),
  (schemas) => schemas.filter(({ meta }) => meta.type === 'customer'),
)

const selectAllWorkOrders = createSelector(
  (state) => workflowSchemasSelectors.selectAll(state.workflowSchemas),
  (schemas) => schemas.filter(({ meta }) => meta.type === 'workOrder'),
)

const selectAllAppointment = createSelector(
  (state) => workflowSchemasSelectors.selectAll(state.workflowSchemas),
  (schemas) => schemas.filter(({ meta }) => meta.type === 'appointment'),
)

export const workflowSchemasSelectors = {
  selectAllCustomers,
  selectAllWorkOrders,
  selectAllAppointment,
  ...workflowSchemasAdapter.getSelectors(),
}

export default workflowSchemasSlice.reducer
