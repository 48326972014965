import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { IsNullOrUndefined } from './utils'

export const getSecurityZoneById = async ({ accountId, odataParams }, { cancelToken } = {}) => {
  const {
    data = [],
  } = await HttpClient.get(
    `/monitoringAccounts/${accountId}/securityZones${buildQuery(odataParams)}`,
    { cancelToken },
  )
  return data
}

export const createSecurityZone = async (
  { accountId, values, odataParams },
  { cancelToken } = {},
) => {
  const {
    data = {},
  } = await HttpClient.post(
    `/monitoringAccounts/${accountId}/securityZones${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  return data
}

export const createSecurityLocation = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/securitylocations`, payload, { cancelToken })
  return data
}

export const getSecurityLocations = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/securitylocations${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

export const updateSecurityLocation = async (payload, { cancelToken } = {}) => {
  const { id, ...values } = payload

  const { data = {} } = await HttpClient.patch(`/securitylocations/${id}`, values, { cancelToken })
  return data
}

export const getSecurityLocation = async (payload, { cancelToken } = {}) => {
  const { id } = payload
  const { data = {} } = await HttpClient.get(`/securitylocations/${id}`, { cancelToken })
  return data
}

export const isUniqueSecurityLocationName = async (payload, { cancelToken } = {}) => {
  const { name } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/securitylocations${buildQuery({
      filter: {
        name,
      },
    })}`,
    { cancelToken },
  )

  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

export const isUniqueEventCodeName = async (payload, { cancelToken } = {}) => {
  const { name } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/eventCodes${buildQuery({
      filter: {
        name,
      },
    })}`,
    { cancelToken },
  )

  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

export const editSecurityZone = async (
  { accountId, zoneId, values, odataParams },
  { cancelToken } = {},
) => {
  const { data = {} } = await HttpClient.put(
    `/monitoringAccounts/${accountId}/securityZones/${zoneId}${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

export const deleteSecurityZone = async ({ accountId, zoneId }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(
    `/monitoringAccounts/${accountId}/securityZones/${zoneId}`,
    {
      cancelToken,
    },
  )

  return data
}
