import React from 'react'
import { Space, Tooltip } from 'antd'
import { NavLink } from 'react-router-dom'
import { SettingOutlined } from '@ant-design/icons'
import { usePermissions } from 'hooks'
import { ROUTER_PERMS } from 'router'

import HiveSearch from 'components/LayoutComponents/TopBar/HiveSearch'

export default function Toolbar() {
  const tools = [
    <Tooltip key="search" placement="bottom" title="Global Search">
      <HiveSearch />
    </Tooltip>,
  ]

  if (usePermissions(ROUTER_PERMS.SETUP)) {
    tools.push(
      <NavLink key="setup" to="/setup">
        <Tooltip placement="bottom" title="Site Setup">
          <SettingOutlined />
        </Tooltip>
      </NavLink>,
    )
  }

  return <Space>{tools}</Space>
}
