import { createSelector } from '@reduxjs/toolkit'

import WorkflowInstanceRenderProducer from '.'

const workorderFlowRenderSlice = new WorkflowInstanceRenderProducer('hive/workorder-flow-render')

const { workflowStateRenderSelectors: selectors } = workorderFlowRenderSlice

const getInstanceRender = createSelector(
  (state) => state.workOrderInstanceRender,
  (_, instanceId) => instanceId,
  (r, id) => selectors.selectById(r, id)?.components ?? [],
)

const getInstanceRenderTabs = createSelector(getInstanceRender, (sections) =>
  sections.filter(({ name }) => !~['contact', 'equipmentAndServices', 'appointment'].indexOf(name)),
)

export const workOrderStateRenderSelectors = {
  getInstanceRenderTabs,
  getInstanceRender,
  ...selectors,
}

export const {
  getWorkflowInstanceRenderThunk: getWordOrderInstanceRenderThunk,
  setActiveInstance,
} = workorderFlowRenderSlice

export default workorderFlowRenderSlice.reducer
