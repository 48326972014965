const actions = {
  SET_STATE: 'users/SET_STATE',
  LOAD_USER_LIST: 'users/LOAD_USER_LIST',
  LOAD_SINGLE_USER: 'users/LOAD_SINGLE_USER',
  SINGLE_USER_LOADED: 'users/SINGLE_USER_LOADED',
  ADD_USER: 'users/ADD_USER',
  UPDATE_USER: 'users/UPDATE_USER',
  LOAD_USER_TYPES: 'users/LOAD_USER_TYPES',
  USERS_REQUEST: 'hive/user/list/request',
  USERS_SUCCESS: 'hive/user/list/success',
  USERS_FAILURE: 'hive/user/list/failure',
}

export const getUsers = (payload) => ({
  type: actions.USERS_REQUEST,
  payload,
})

export const getUsersSuccess = (response) => ({
  type: actions.USERS_SUCCESS,
  payload: response,
})

export const getUsersFailure = (error) => ({ type: actions.USERS_FAILURE, error })

export default actions
