import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFields,
  getFieldsByTemplateType,
  createTemplateField,
  editTemplateField,
} from 'services/templateFields'
import { withThunkApi } from 'services/axios.utils'

const fieldsAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
})

export const fetchFieldsThunk = createAsyncThunk('hive/fields', withThunkApi(getFields))

export const getFieldsByTemplateTypeThunk = createAsyncThunk(
  'hive/templateFields/getByTemplateType',
  withThunkApi(getFieldsByTemplateType),
)

export const createTemplateFieldThunk = createAsyncThunk(
  'hive/templateFields/create',
  withThunkApi(createTemplateField),
)

export const editTemplateFieldThunk = createAsyncThunk(
  'hive/templateFields/edit',
  withThunkApi(editTemplateField),
)

const templateFieldsSlice = createSlice({
  name: 'hive/templateFields',
  initialState: fieldsAdapter.getInitialState({
    loading: 'idle',
    error: null,
  }),
  extraReducers: {
    [fetchFieldsThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [fetchFieldsThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      fieldsAdapter.setAll(state, action.payload)
    },
    [fetchFieldsThunk.rejected]: (state) => {
      state.loading = 'idle'
    },
    [getFieldsByTemplateTypeThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [getFieldsByTemplateTypeThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      fieldsAdapter.setAll(state, action.payload)
    },
    [getFieldsByTemplateTypeThunk.rejected]: (state) => {
      state.loading = 'idle'
    },
    [createTemplateFieldThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [createTemplateFieldThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [createTemplateFieldThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        fieldsAdapter.addOne(state, action.payload)
      }
    },
    [editTemplateFieldThunk.pending]: (state) => {
      if (state.loading === 'idle') state.loading = 'pending'
    },
    [editTemplateFieldThunk.rejected]: (state, action) => {
      state.loading = 'idle'
      if (action.payload) {
        state.error = action.payload.Message
      } else {
        state.error = action.error
      }
    },
    [editTemplateFieldThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        fieldsAdapter.upsertOne(state, action.payload)
      }
    },
  },
})

export const fieldsSelector = fieldsAdapter.getSelectors()

export default templateFieldsSlice.reducer
