import axios from 'axios'
import { authHeaderRequestInterceptor } from './interceptors'

const AlarmComtHttpClient = axios.create({
  baseURL: process.env.REACT_APP_ALARMCOM_URL,
})

AlarmComtHttpClient.interceptors.request.use(authHeaderRequestInterceptor)

export default AlarmComtHttpClient
