import { put, call, all, takeLatest } from 'redux-saga/effects'
import HttpClient from 'services/api'
import {
  ActionTypes,
  getRolesSuccess,
  getRolesFailure,
  getRoleSuccess,
  getRoleFailure,
  createRoleSuccess,
  createRoleFailure,
  updateRoleSuccess,
  updateRoleFailure,
  removeRoleSuccess,
  removeRoleFailure,
} from './actions'

export function* getAllRoles() {
  try {
    const { data } = yield call(HttpClient.get, '/roles?$select=id,name')
    yield put(
      getRolesSuccess({
        items: data.value,
        info: data.pagePagination,
      }),
    )
  } catch (error) {
    yield put(getRolesFailure(error))
  }
}

export function* getRole({ id }) {
  try {
    const { data } = yield call(HttpClient.get, `/roles/${id}`)
    yield put(
      getRoleSuccess({
        items: [data],
      }),
    )
  } catch (error) {
    yield put(getRoleFailure(error))
  }
}

export function* createRole({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.post, '/roles', payload)
    yield put(createRoleSuccess(data))
    callback()
  } catch (error) {
    yield put(createRoleFailure(error))
  }
}

export function* updateRole({ id, payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/roles/${id}`, payload)
    yield put(updateRoleSuccess(data))
    callback()
  } catch (error) {
    yield put(updateRoleFailure(error))
  }
}

export function* removeRole({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/roles/${payload.id}`)
    yield put(removeRoleSuccess(data))
    callback()
  } catch (error) {
    yield put(removeRoleFailure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.ROLES_REQUEST, getAllRoles),
    takeLatest(ActionTypes.READ_ROLE_REQUEST, getRole),
    takeLatest(ActionTypes.ADD_ROLE_REQUEST, createRole),
    takeLatest(ActionTypes.UPDATE_ROLE_REQUEST, updateRole),
    takeLatest(ActionTypes.DELETE_ROLE_REQUEST, removeRole),
  ])
}
