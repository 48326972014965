import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { mergeActionTypes } from 'services/utils'
import {
  getInvoices,
  getInvoiceById,
  createInvoice,
  updateInvoice,
  getInvoiceByCustomerId,
  createRecurringBillingInvoice,
} from 'services/invoices'
import { withThunkApi } from 'services/axios.utils'

export const fetchCustomerInvoices = createAsyncThunk(
  'hive/customer/invoice/list',
  withThunkApi(getInvoices),
)

export const fetchCustomerInvoiceById = createAsyncThunk(
  'hive/customer/invoice/byId',
  withThunkApi(getInvoiceById),
)

export const fetchCustomerInvoiceByCustomerId = createAsyncThunk(
  'hive/customer/invoice/byCustomerId',
  withThunkApi(getInvoiceByCustomerId),
)

export const createInvoiceThunk = createAsyncThunk(
  'hive/customer/invoice/create',
  withThunkApi(createInvoice),
)

export const updateInvoiceThunk = createAsyncThunk(
  'hive/customer/invoice/update',
  withThunkApi(updateInvoice),
)

export const createRecurringBillingInvoiceThunk = createAsyncThunk(
  'hive/customer/invoice/create/byRecurringBillingId',
  withThunkApi(createRecurringBillingInvoice),
)

const invoiceAdapter = createEntityAdapter()

const invoiceSlice = createSlice({
  name: 'hive/invoices',
  initialState: invoiceAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupInvoices: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchCustomerInvoices.pending,
        fetchCustomerInvoiceById.pending,
        fetchCustomerInvoiceByCustomerId.pending,
        createInvoiceThunk.pending,
        updateInvoiceThunk.pending,
        createRecurringBillingInvoiceThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        fetchCustomerInvoices.rejected,
        fetchCustomerInvoiceById.rejected,
        fetchCustomerInvoiceByCustomerId.rejected,
        createInvoiceThunk.rejected,
        updateInvoiceThunk.rejected,
        createRecurringBillingInvoiceThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),

    [fetchCustomerInvoices.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        invoiceAdapter.setAll(state, action.payload)
      }
    },

    [fetchCustomerInvoiceById.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        invoiceAdapter.upsertOne(state, action.payload)
      }
    },

    [fetchCustomerInvoiceByCustomerId.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        invoiceAdapter.upsertMany(state, action.payload)
      }
    },
  },
})

export const { cleanupInvoices } = invoiceSlice.actions

export const invoiceSelectors = invoiceAdapter.getSelectors()

export default invoiceSlice.reducer
