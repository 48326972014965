import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import StockItemGroupService from 'services/stockItemGroup'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const fetchStockItemGroupsThunk = createAsyncThunk(
  'hive/stockItemGroup/list',
  withThunkApi(StockItemGroupService.getStockItemGroups),
)

export const fetchStockItemGroupThunk = createAsyncThunk(
  'hive/stockItemGroup/get',
  withThunkApi(StockItemGroupService.getStockItemGroup),
)

const stockItemGroupAdapter = createEntityAdapter({
  selectId: (entity) => entity.inventoryId,
})

const workOrderStatusSlice = createSlice({
  name: 'hive/stockItemGroups',
  initialState: stockItemGroupAdapter.getInitialState({
    loading: 'idle',
    total: null,
  }),
  reducers: {
    cleanupStockItemGroups: (state) => stockItemGroupAdapter.removeAll(state),
  },
  extraReducers: {
    ...mergeActionTypes(
      [fetchStockItemGroupsThunk.pending, fetchStockItemGroupThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [fetchStockItemGroupsThunk.rejected, fetchStockItemGroupThunk.rejected],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [fetchStockItemGroupsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.total = action.payload['@odata.count']
        stockItemGroupAdapter.setAll(state, action.payload.value)
      }
    },
    [fetchStockItemGroupThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        stockItemGroupAdapter.upsertOne(state, action.payload)
      }
    },
  },
})

export const { cleanupStockItemGroups } = workOrderStatusSlice.actions

export const stockItemsGroupSelector = stockItemGroupAdapter.getSelectors()

export default workOrderStatusSlice.reducer
