import React from 'react'
import { usePermissions } from 'hooks'
import { Redirect, Route, useLocation } from 'react-router'

export default function AuthorizedRoute({ component: Component, permissions = [], ...restProps }) {
  const authorized = usePermissions(permissions)
  const location = useLocation()

  return (
    <Route
      {...restProps}
      render={(props) =>
        authorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )
      }
    />
  )
}
