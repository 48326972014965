import React from 'react'
import { Layout, Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styles from './style.module.scss'

function LoginLayout(props) {
  const { children } = props

  const [loading, { logoUrl = 'resources/images/logo-hive.png' }] = useSelector((state) => [
    state.merchant.loading === 'pending',
    state.merchant.entity,
  ])

  return (
    <Layout>
      <Layout.Content>
        <div className={`${styles.layout} ${styles.light}`}>
          <div className={styles.logo}>{loading ? <Spin /> : <img src={logoUrl} alt="Logo" />}</div>
          <div className={styles.content}>{children}</div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(LoginLayout)
