import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import { QueryClientProvider, QueryClient } from 'react-query'
import thunk from 'redux-thunk'
import { notification } from 'antd'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createHashHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import EventPovider from 'components/AlarmHiveComponents/EventPovider/EventPovider'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import jqueryConfig from './jquery.config'

// app styles

import './global.scss'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
  // middlewares.push(logger)
}
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  // other store enhancers if any
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: 0 },
  },
})

const store = createStore(reducers(history), enhancer)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Localization>
          <EventPovider>
            <Router history={history} />
          </EventPovider>
        </Localization>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      notification.info({
        message: `AlarmHive`,
        description: 'The new version of the app has been deployed. Please reload the page',
        placement: 'bottomRight',
        duration: 0,
      })
    }
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

jqueryConfig()
dayjs.extend(localizedFormat)

export { store, history }
