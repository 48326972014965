import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { IsNullOrUndefined } from './utils'

export const getDistributors = async (payload, { cancelToken } = {}) => {
  const { data } = await HttpClient.get(`/distributors${buildQuery(payload)}`, {
    cancelToken,
  })

  return data
}

export const getDistributor = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload
  const { data } = await HttpClient.get(`/distributors/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })

  return data
}

export const createDistributor = async (payload, { cancelToken } = {}) => {
  const { data } = await HttpClient.post(`/distributors`, payload, {
    cancelToken,
  })

  return data
}

export const updateDistributor = async (payload, { cancelToken } = {}) => {
  const { id, ...values } = payload

  const { data } = await HttpClient.patch(`/distributors/${id}`, values, {
    cancelToken,
  })

  return data
}

export const isUniqueDistributorName = async (payload, { cancelToken } = {}) => {
  const { title } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/distributors${buildQuery({
      filter: {
        title,
      },
    })}`,
    { cancelToken },
  )

  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

const DistributorService = {
  getDistributors,
  getDistributor,
  createDistributor,
  updateDistributor,
  isUniqueDistributorName,
}

export default DistributorService
