import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import QuoteService from 'services/quote'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const fetchQuotesThunk = createAsyncThunk(
  'hive/quote/list',
  withThunkApi(QuoteService.fetchQuotes),
)

export const fetchQuoteThunk = createAsyncThunk(
  'hive/quote/get',
  withThunkApi(QuoteService.fetchQuote),
)

export const createQuoteThunk = createAsyncThunk(
  'hive/quote/create',
  withThunkApi(QuoteService.createQuote),
)

export const updateQuoteThunk = createAsyncThunk(
  'hive/quote/update',
  withThunkApi(QuoteService.updateQuote),
)

export const removeQuoteThunk = createAsyncThunk(
  'hive/quote/delete',
  withThunkApi(QuoteService.deleteQuote),
)

export const addQuoteServiceThunk = createAsyncThunk(
  'hive/quote/item/add',
  withThunkApi(QuoteService.addQuoteService),
)

export const editQuoteServiceThunk = createAsyncThunk(
  'hive/quote/service/edit',
  withThunkApi(QuoteService.editQuoteService),
)

export const removeQuoteServiceThunk = createAsyncThunk(
  'hive/quote/service/delete',
  withThunkApi(QuoteService.deleteQuoteService),
)

export const addQuoteEquipmentThunk = createAsyncThunk(
  'hive/quote/equipment/add',
  withThunkApi(QuoteService.addQuoteEquipment),
)

export const editQuoteEquipmentThunk = createAsyncThunk(
  'hive/quote/equipment/edit',
  withThunkApi(QuoteService.editQuoteEquipment),
)

export const removeQuoteEquipmentThunk = createAsyncThunk(
  'hive/quote/equipment/delete',
  withThunkApi(QuoteService.deleteQuoteEquipment),
)

const quoteAdapter = createEntityAdapter({
  sortComparer: (p, n) => n.id - p.id,
})

const quoteSlice = createSlice({
  name: 'hive/quote',
  initialState: quoteAdapter.getInitialState({
    loading: 'idle',
    error: false,
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchQuotesThunk.pending,
        fetchQuoteThunk.pending,
        createQuoteThunk.pending,
        updateQuoteThunk.pending,
        removeQuoteThunk.pending,
        addQuoteEquipmentThunk.pending,
        removeQuoteEquipmentThunk.pending,
        editQuoteEquipmentThunk.pending,
        addQuoteServiceThunk.pending,
        removeQuoteServiceThunk.pending,
        editQuoteServiceThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        fetchQuotesThunk.rejected,
        fetchQuoteThunk.rejected,
        createQuoteThunk.rejected,
        updateQuoteThunk.rejected,
        removeQuoteThunk.rejected,
        addQuoteEquipmentThunk.rejected,
        removeQuoteEquipmentThunk.rejected,
        editQuoteEquipmentThunk.rejected,
        addQuoteServiceThunk.rejected,
        removeQuoteServiceThunk.rejected,
        editQuoteServiceThunk.rejected,
      ],
      (state, action) => {
        state.loading = 'idle'
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error
        }
      },
    ),
    [fetchQuotesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      action.payload = action.payload.map((quote) => {
        quote.totalDiscount = getTotalDiscount(quote)
        return quote
      })
      quoteAdapter.setAll(state, action.payload)
    },

    [fetchQuoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      action.payload.totalDiscount = getTotalDiscount(action.payload)
      quoteAdapter.upsertOne(state, action.payload)
    },

    [createQuoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        quoteAdapter.addOne(state, action.payload)
      }
    },

    [updateQuoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        quoteAdapter.upsertOne(state, action.payload)
      }
    },

    [removeQuoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        quoteAdapter.removeOne(state, action.payload)
      }
    },

    [addQuoteEquipmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { quoteId } = action.meta.arg
        const { equipment = [] } = quoteSelectors.selectById(state, quoteId) || {}

        quoteAdapter.updateOne(state, {
          id: quoteId,
          changes: {
            equipment: [...equipment, action.payload],
          },
        })
      }
    },
    [removeQuoteEquipmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, quoteId } = action.meta.arg
        const { equipment } = quoteSelectors.selectById(state, quoteId)
        const changes = {
          id: quoteId,
          changes: {
            equipment: equipment.filter((item) => item.id !== id),
          },
        }

        quoteAdapter.updateOne(state, changes)
      }
    },
    [editQuoteEquipmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, quoteId } = action.meta.arg
        let { equipment } = quoteSelectors.selectById(state, quoteId)

        equipment = equipment.map((item) => (item.id === id ? action.payload : item))
        const changes = {
          id: quoteId,
          changes: {
            equipment,
          },
        }

        quoteAdapter.updateOne(state, changes)
      }
    },
    [addQuoteServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { quoteId } = action.meta.arg
        const { serviceItems = [] } = quoteSelectors.selectById(state, quoteId) || {}

        quoteAdapter.updateOne(state, {
          id: quoteId,
          changes: {
            serviceItems: [...serviceItems, action.payload],
          },
        })
      }
    },
    [removeQuoteServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, quoteId } = action.meta.arg
        const { serviceItems } = quoteSelectors.selectById(state, quoteId)
        const changes = {
          id: quoteId,
          changes: {
            serviceItems: serviceItems.filter((item) => item.id !== id),
          },
        }

        quoteAdapter.updateOne(state, changes)
      }
    },
    [editQuoteServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, quoteId } = action.meta.arg
        let { serviceItems } = quoteSelectors.selectById(state, quoteId)

        serviceItems = serviceItems.map((item) => (item.id === id ? action.payload : item))
        const changes = {
          id: quoteId,
          changes: {
            serviceItems,
          },
        }

        quoteAdapter.updateOne(state, changes)
      }
    },
  },
})
const selectItem = createSelector(
  (state) => state.quote,
  (_, item) => item,
  (state, { quoteId, id, type }) => {
    if (!quoteId || !id || !type) return undefined
    const quote = quoteSelectors.selectById(state, quoteId)
    const { ...item } = quote[type].filter((i) => i.id === +id)[0]
    item.inventoryItemId = `${type}.${item.inventoryItemId ? item.inventoryItemId : item.serviceId}`
    return item
  },
)

const getTotalDiscount = ({ equipment, serviceItems }) =>
  [...equipment, ...serviceItems].reduce((prev, { discount }) => prev + discount, 0)

export const quoteSelectors = { selectItem, ...quoteAdapter.getSelectors() }

export default quoteSlice.reducer
