import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import LocationService from 'services/location'
import { withThunkApi } from 'services/axios.utils'

export const fetchLocationsThunk = createAsyncThunk(
  'hive/location/list',
  withThunkApi(LocationService.fetchLocations),
  {
    condition: (payload, { getState }) => {
      const { xlocations } = getState()
      return JSON.stringify(payload) !== xlocations.arg
    },
  },
)

const locationAdapter = createEntityAdapter()

const locationSlice = createSlice({
  name: 'hive/location',
  initialState: locationAdapter.getInitialState({
    loading: 'idle',
    arg: null,
  }),

  extraReducers: {
    [fetchLocationsThunk.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'

        if (meta.arg) {
          state.arg = JSON.stringify(meta.arg)
        }
      }
    },
    [fetchLocationsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.arg = null
        locationAdapter.upsertMany(state, action.payload.value)
      }
    },
    [fetchLocationsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.arg = null
      }
    },
  },
})

export const locationSelectors = locationAdapter.getSelectors()

export default locationSlice.reducer
