import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { getPaymentTypes } from 'services/paymentType'
import { withThunkApi } from 'services/axios.utils'

export const fetchCustomerPaymentTypesThunk = createAsyncThunk(
  'hive/customer/paymentTypes/list',
  withThunkApi(getPaymentTypes),
)

const paymentProfileAdapter = createEntityAdapter()

const paymentTypesSlice = createSlice({
  name: 'hive/paymentTypes',
  initialState: paymentProfileAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [fetchCustomerPaymentTypesThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },

    [fetchCustomerPaymentTypesThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },

    [fetchCustomerPaymentTypesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        paymentProfileAdapter.setAll(state, action.payload)
      }
    },
  },
})

export const paymentTypesSelectors = paymentProfileAdapter.getSelectors()

export default paymentTypesSlice.reducer
