import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { mergeActionTypes } from 'services/utils'
import HttpClient from 'services/api'
import CustomerService from 'services/customer'
import { withThunkApi } from 'services/axios.utils'
import WorkOrderService from 'services/workorder'

export const fetchWorkorders = createAsyncThunk('hive/workorders/list', async () => {
  const { data = {} } = await HttpClient.get(`/workorders`)
  return data.value
})

export const fetchCustomerWorkorders = createAsyncThunk(
  'hive/customer/workorders/list',
  withThunkApi(CustomerService.fetchCustomerWorkorders),
)

export const fetchCustomerWorkorder = createAsyncThunk(
  'hive/customer/workorders/get',
  withThunkApi(CustomerService.fetchCustomerWorkorder),
)

export const fetchWorkorder = createAsyncThunk(
  'hive/workorders/get',
  withThunkApi(WorkOrderService.fetchWorkorder),
)

export const createWorkOrderThunk = createAsyncThunk(
  'hive/customer/workorders/create',
  withThunkApi(async ({ customer, values }) => {
    const { data = {} } = await HttpClient.post(`/customers/${customer}/workorders`, values)
    return data
  }),
)

export const updateWorkOrderThunk = createAsyncThunk(
  'hive/customer/workorders/update',
  withThunkApi(async ({ workOrderId, customer, values }) => {
    const { data = {} } = await HttpClient.patch(
      `/customers/${customer}/workorders/${workOrderId}`,
      values,
    )
    return data
  }),
)

const workOrderAdapter = createEntityAdapter({
  sortComparer: (p, n) => n.id - p.id,
})

const workOrderSlice = createSlice({
  name: 'hive/workOrders',
  initialState: workOrderAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupWorkorders: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchWorkorders.pending,
        fetchCustomerWorkorder.pending,
        fetchCustomerWorkorders.pending,
        createWorkOrderThunk.pending,
        updateWorkOrderThunk.pending,
        fetchWorkorder.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        fetchWorkorders.rejected,
        fetchCustomerWorkorder.rejected,
        fetchCustomerWorkorders.rejected,
        createWorkOrderThunk.rejected,
        updateWorkOrderThunk.rejected,
        fetchWorkorder.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    ...mergeActionTypes(
      [fetchWorkorders.fulfilled, fetchCustomerWorkorders.fulfilled],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          workOrderAdapter.setAll(state, action.payload.value)
        }
      },
    ),
    ...mergeActionTypes(
      [
        createWorkOrderThunk.fulfilled,
        updateWorkOrderThunk.fulfilled,
        fetchCustomerWorkorder.fulfilled,
        fetchWorkorder.fulfilled,
      ],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          workOrderAdapter.upsertOne(state, action.payload)
        }
      },
    ),
  },
})

export const { cleanupWorkorders } = workOrderSlice.actions

export const workOrderSelectors = workOrderAdapter.getSelectors()

export default workOrderSlice.reducer
