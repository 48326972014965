import HttpClient from 'services/api'
import AlarmComHttpClient from 'services/api/alarmcom'
import buildQuery from 'odata-query'
import { IsNullOrUndefined } from './utils'

const getNonGenericCellPlans = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await AlarmComHttpClient.get(`/DealerManagement/packages${buildQuery(payload)}`, {
    cancelToken,
  })

  return data
}

const getGenericCellPlans = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/genericCellPlans${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const getGenericCellPlan = async (payload, { cancelToken } = {}) => {
  const { id } = payload
  const { data = {} } = await HttpClient.get(`/genericCellPlans/${id}`, { cancelToken })
  return data
}

const createGenericCellPlan = async ({ values, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(
    `/genericCellPlans${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  return data
}

const editGenericCellPlan = async ({ id, values, odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(
    `/genericCellPlans/${id}${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

const deleteGenericCellPlan = async ({ id }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(`/genericCellPlans/${id}`, {
    cancelToken,
  })

  return data
}

const isUniqueCellPlanName = async (payload, { cancelToken } = {}) => {
  const { name } = payload
  let { exclude = [] } = payload

  exclude = exclude.filter((ex) => !IsNullOrUndefined(ex))

  const { status, data = [] } = await HttpClient.get(
    `/genericCellPlans${buildQuery({
      filter: {
        name,
      },
    })}`,
    { cancelToken },
  )

  if (status !== 200) return Promise.reject(data)

  if (exclude.length) {
    return data.value.every(({ id }) => exclude.includes(id))
  }

  return data.value.length === 0
}

const GenericCellPlanService = {
  getNonGenericCellPlans,
  getGenericCellPlans,
  getGenericCellPlan,
  createGenericCellPlan,
  editGenericCellPlan,
  deleteGenericCellPlan,
  isUniqueCellPlanName,
}

export default GenericCellPlanService
