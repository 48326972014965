import { ROUTER_PERMS } from 'router'
import getSetupPageMenu from 'services/setupPageMenu'
import getReportsMenu from 'services/reportsMenu'

export async function getLeftMenuData() {
  return [
    {
      title: 'Contacts',
      key: 'contacts',
      url: '/contacts',
      icon: 'icmn icmn-user-tie',
      permissions: ['Contacts.Read'],
    },
    {
      title: 'Inventory',
      key: 'inventory',
      icon: 'icmn icmn-shield',
      permissions: [
        'InventoryItemList.Read',
        'WarehouseDetails.Read',
        'ReceiveEquipment.Read',
        'InventoryTransfer.Read',
      ],
      children: [
        {
          title: 'Items',
          key: 'inventory',
          url: '/inventory',
          icon: 'icmn icmn-plus',
          permissions: ['InventoryItemList.Read'],
        },
        {
          title: 'Receiving',
          key: 'receive-inventory',
          url: '/inventory/receive',
          icon: 'icmn icmn-box-add',
          permissions: ['ReceiveEquipment.Read'],
        },
        {
          title: 'Transfers',
          key: 'Transfers',
          url: '/inventory/transfers',
          icon: 'icmn icmn-box-add',
          permissions: ['InventoryTransfer.Read'],
        },
        {
          title: 'Items in Stock',
          key: 'warehouse-details',
          url: '/warehouses/inventory',
          regexp: /\/warehouses\/\d+\/inventory\/?\d*$/,
          icon: 'icmn icmn-database',
          permissions: ['WarehouseDetails.Read'],
        },
      ],
    },
    {
      title: 'Work Orders',
      key: 'workorders',
      url: '/workorders',
      icon: 'icmn icmn-user-tie',
      permissions: ['WorkOrders.Read'],
    },
    {
      title: 'Invoices',
      key: 'invoices',
      url: '/invoices',
      icon: 'icmn icmn-file-text',
      permissions: ['InvoicePermission.Read'],
    },
    {
      title: 'Appointments',
      key: 'appointments',
      url: '/appointments',
      icon: 'icmn icmn-user-tie',
      permissions: ['SchedulingAppointments.Read'],
    },
    {
      title: 'Setup',
      key: 'setup',
      icon: 'icmn icmn-shield',
      permissions: [ROUTER_PERMS.SETUP],
      children: getSetupPageMenu('/setup'),
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'icmn icmn-stats-dots',
      permissions: ['Reports.Read'],
      children: getReportsMenu(),
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Contacts',
      key: 'contacts',
      url: '/contacts',
      icon: 'icmn icmn-user-tie',
      permissions: ['Contacts.Read'],
    },
    {
      title: 'Inventory',
      key: 'inventory',
      icon: 'icmn icmn-shield',
      permissions: ['InventoryItemList.Read', 'WarehouseDetails.Read', 'ReceiveEquipment.Read'],
      children: [
        {
          title: 'Items',
          key: 'inventory',
          url: '/inventory',
          icon: 'icmn icmn-cog',
          permissions: ['InventoryItemList.Read'],
        },
        {
          title: 'Receiving',
          key: 'receive-inventory',
          url: '/inventory/receive',
          icon: 'icmn icmn-box-add',
          permissions: ['ReceiveEquipment.Read'],
        },
        {
          title: 'Transfers',
          key: 'Transfers',
          url: '/inventory/transfers',
          icon: 'icmn icmn-box-add',
          permissions: [],
        },
        {
          title: 'Items in Stock',
          key: 'warehouse-details',
          url: '/warehouses/inventory',
          regexp: /\/warehouses\/\d+\/inventory\/?\d*$/,
          icon: 'icmn icmn-database',
          permissions: ['WarehouseDetails.Read'],
        },
      ],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'icmn icmn-users',
      permissions: ['UserManagement.Read', 'RightManagement.Read'],
      children: [
        {
          title: 'User List',
          key: 'users',
          url: '/setup/users/user-list',
          icon: 'icmn icmn-users',
          permissions: ['UserManagement.Read'],
        },
        {
          title: 'User Rights',
          key: 'user-rigths',
          url: '/setup/users/roles',
          icon: 'icmn icmn-lock',
          permissions: ['RightManagement.Read'],
        },
      ],
    },
    {
      title: 'Work Orders',
      key: 'workorders',
      url: '/workorders',
      icon: 'icmn icmn-briefcase',
      permissions: ['WorkOrder.Read'],
    },
    {
      title: 'Invoices',
      key: 'invoices',
      url: '/invoices',
      icon: 'icmn icmn-file-text',
      permissions: ['InvoicePermission.Read'],
    },
    {
      title: 'Appointments',
      key: 'appointments',
      url: '/appointments',
      icon: 'icmn icmn-hammer',
      permissions: ['SchedulingAppointments.Read'],
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'icmn icmn-stats-dots',
      permissions: ['Reports.Read'],
      children: getReportsMenu(),
    },
  ]
}
