import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { mergeActionTypes } from 'services/utils'
import MonitoringService from 'services/monitoring'
import MonitoringAccountActionsService from 'services/monitoringAccountActions'
import { withThunkApi } from 'services/axios.utils'

export const getDeviceTypesThunk = createAsyncThunk(
  'hive/monitoring/deviceTypes/list',
  withThunkApi(MonitoringService.getDeviceTypes),
)

export const syncDeviceTypesThunk = createAsyncThunk(
  'hive/monitoring/deviceTypes/sync',
  withThunkApi(MonitoringAccountActionsService.syncDeviceTypes),
)

export const updateDeviceTypeThunk = createAsyncThunk(
  'hive/monitoring/deviceTypes/update',
  withThunkApi(MonitoringService.updateDeviceType),
)

const deviceTypesAdapter = createEntityAdapter()

const deviceTypesSlice = createSlice({
  name: 'hive/deviceTypes',
  initialState: deviceTypesAdapter.getInitialState({
    loading: 'idle',
    total: 0,
  }),
  extraReducers: {
    ...mergeActionTypes(
      [getDeviceTypesThunk.pending, updateDeviceTypeThunk.pending, syncDeviceTypesThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),

    [getDeviceTypesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.total = action.payload['@odata.count']
        deviceTypesAdapter.setAll(state, action.payload.value)
      }
    },

    [syncDeviceTypesThunk.fulfilled]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },

    [updateDeviceTypeThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, isActive, modifiedAt } = action.payload
        deviceTypesAdapter.updateOne(state, {
          id,
          changes: { isActive, modifiedAt },
        })
      }
    },

    ...mergeActionTypes(
      [getDeviceTypesThunk.rejected, updateDeviceTypeThunk.rejected, syncDeviceTypesThunk.rejected],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
  },
})

export const deviceTypeSelectors = deviceTypesAdapter.getSelectors()

export default deviceTypesSlice.reducer
