import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import { getWorkflowTypes } from 'services/workflowScheme'
import { withThunkApi } from 'services/axios.utils'

export const fetchWorkflowTypesThunk = createAsyncThunk(
  'hive/workflowTypes',
  withThunkApi(getWorkflowTypes),
)

const workflowTypesAdapter = createEntityAdapter()

const workflowTypesSlice = createSlice({
  name: 'hive/workflowTypes',
  initialState: workflowTypesAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [fetchWorkflowTypesThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [fetchWorkflowTypesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        workflowTypesAdapter.setAll(state, action.payload)
      }
    },
    [fetchWorkflowTypesThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
  },
})

export const workflowTypeSelectors = workflowTypesAdapter.getSelectors()

export default workflowTypesSlice.reducer
