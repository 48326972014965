import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const fetchQuotes = async ({ customerId, odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `customers/${customerId}/quotes${buildQuery(odataParams)}`,
    { cancelToken },
  )
  return data.value
}

const fetchQuote = async ({ customerId, quoteId, odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `customers/${customerId}/quotes/${quoteId}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data
}

const createQuote = async (payload, { cancelToken } = {}) => {
  const { _sagas_, customerId, ...values } = payload
  const { data = {} } = await HttpClient.post(`/customers/${customerId}/quotes`, values, {
    cancelToken,
  })
  return data
}

const updateQuote = async (payload, { cancelToken } = {}) => {
  const { _sagas_, customerId, id, ...values } = payload
  const { data = {} } = await HttpClient.patch(`/customers/${customerId}/quotes/${id}`, values, {
    cancelToken,
  })
  return data
}

const deleteQuote = async ({ id, customerId }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(`/customers/${customerId}/quotes/${id}`, {
    cancelToken,
  })
  return data
}

const addQuoteService = async (payload, { cancelToken } = {}) => {
  const { quoteId, values, odataParams } = payload

  const { data = {} } = await HttpClient.post(
    `/quotes/${quoteId}/serviceItems${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  delete data['@odata.context']
  return data
}

const addQuoteEquipment = async (payload, { cancelToken } = {}) => {
  const { quoteId, values, odataParams } = payload
  const { data = {} } = await HttpClient.post(
    `quotes/${quoteId}/equipment${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  delete data['@odata.context']
  return data
}

const editQuoteService = async (payload, { cancelToken } = {}) => {
  const { quoteId, id, values, odataParams } = payload
  const { data = {} } = await HttpClient.patch(
    `/quotes/${quoteId}/serviceItems/${id}${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  delete data['@odata.context']
  return data
}

const editQuoteEquipment = async (payload, { cancelToken } = {}) => {
  const { quoteId, id, values, odataParams } = payload
  const { data = {} } = await HttpClient.patch(
    `/quotes/${quoteId}/equipment/${id}${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  delete data['@odata.context']
  return data
}

const deleteQuoteService = async ({ id, quoteId }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(`/quotes/${quoteId}/serviceItems/${id}`, {
    cancelToken,
  })
  return data
}

const deleteQuoteEquipment = async ({ id, quoteId }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(`/quotes/${quoteId}/equipment/${id}`, {
    cancelToken,
  })
  return data
}

const fetchQuoteStatuses = async ({ odataParams }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/quoteStatuses${buildQuery(odataParams)}`, {
    cancelToken,
  })
  delete data['@odata.context']
  return data.value
}

export default {
  fetchQuotes,
  fetchQuote,
  createQuote,
  updateQuote,
  deleteQuote,
  addQuoteEquipment,
  editQuoteEquipment,
  deleteQuoteEquipment,
  addQuoteService,
  editQuoteService,
  deleteQuoteService,
  fetchQuoteStatuses,
}
