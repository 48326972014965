import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import DistributorService from 'services/distributor'
import { withThunkApi } from 'services/axios.utils'
import { mergeActionTypes } from 'services/utils'

export const fetchDistributorsThunk = createAsyncThunk(
  'hive/distributors/list',
  withThunkApi(DistributorService.getDistributors),
)

export const fetchDistributorThunk = createAsyncThunk(
  'hive/distributors/get',
  withThunkApi(DistributorService.getDistributor),
)

export const createDistributorThunk = createAsyncThunk(
  'hive/distributors/create',
  withThunkApi(DistributorService.createDistributor),
)

export const updateDistributorThunk = createAsyncThunk(
  'hive/distributors/update',
  withThunkApi(DistributorService.updateDistributor),
)

const distributorsAdapter = createEntityAdapter()

const distributorsSlice = createSlice({
  name: 'hive/distributors',
  initialState: distributorsAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    clenupLatestDistributor: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchDistributorsThunk.pending,
        fetchDistributorThunk.pending,
        createDistributorThunk.pending,
        updateDistributorThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    [fetchDistributorsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        distributorsAdapter.setAll(state, action.payload.value)
      }
    },
    ...mergeActionTypes(
      [
        createDistributorThunk.fulfilled,
        updateDistributorThunk.fulfilled,
        fetchDistributorThunk.fulfilled,
      ],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          distributorsAdapter.upsertOne(state, action.payload)
        }
      },
    ),
    ...mergeActionTypes(
      [
        fetchDistributorsThunk.rejected,
        fetchDistributorThunk.rejected,
        createDistributorThunk.rejected,
        updateDistributorThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
  },
})

export const distributorsSelectors = distributorsAdapter.getSelectors()

export const { clenupLatestDistributor } = distributorsSlice.actions

export default distributorsSlice.reducer
