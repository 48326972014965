import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import AppointmentService from 'services/appointment'
import { withThunkApi } from 'services/axios.utils'

export const getAppointmentStatusListThunk = createAsyncThunk(
  'hive/appointments/status/list',
  withThunkApi(AppointmentService.fetchAppointmentStatuses),
)

const appointmentStatusAdapter = createEntityAdapter()

const appointmentStatusSlice = createSlice({
  name: 'hive/appointments/status',
  initialState: appointmentStatusAdapter.getInitialState({
    loading: 'idle',
    error: false,
    latest: null,
  }),
  extraReducers: {
    [getAppointmentStatusListThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getAppointmentStatusListThunk.fulfilled]: (state, { payload }) => {
      if (state.loading === 'pending' && payload) {
        state.loading = 'idle'
        appointmentStatusAdapter.upsertMany(state, payload)
      }
    },
  },
})

export const appointmentStatusSelectors = appointmentStatusAdapter.getSelectors()

export default appointmentStatusSlice.reducer
