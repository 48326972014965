import { put, call, all, takeLatest } from 'redux-saga/effects'
import HttpClient from 'services/api'
import buildQuery from 'odata-query'
import { parseResponse } from 'services/odata'
import {
  ActionTypes,
  getContactsSuccess,
  getContactsFailure,
  getContactSuccess,
  getContactFailure,
  createContactSuccess,
  createContactFailure,
  updateContactSuccess,
  updateContactFailure,
  removeContactSuccess,
  removeContactFailure,
} from './actions'

export function* getAllContacts({ payload }) {
  try {
    const { data } = yield call(HttpClient.get, `/customers${buildQuery(payload)}`)
    yield put(
      getContactsSuccess({
        items: data.value,
        odata: parseResponse(data).odata,
      }),
    )
  } catch (error) {
    yield put(getContactsFailure(error))
  }
}

export function* getContact({ id }) {
  try {
    const { data } = yield call(
      HttpClient.get,
      `/customers/${id}${buildQuery({
        expand: { contacts: { expand: 'phone' }, addresses: {}, customFields: {} },
      })}`,
    )
    yield put(
      getContactSuccess({
        items: [data],
      }),
    )
  } catch (error) {
    yield put(getContactFailure(error))
  }
}

export function* createContact({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.post, '/customers', payload)
    yield put(createContactSuccess(data))
    callback()
  } catch (error) {
    yield put(createContactFailure(error))
  }
}

export function* updateContact({ id, payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.patch, `/customers/${id}`, payload)
    yield put(updateContactSuccess(data))
    callback()
  } catch (error) {
    yield put(updateContactFailure(error))
  }
}

export function* removeContact({ payload, callback = () => {} }) {
  try {
    const { data } = yield call(HttpClient.put, `/customers/${payload.id}`)
    yield put(removeContactSuccess(data))
    callback()
  } catch (error) {
    yield put(removeContactFailure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.CONTACTS_REQUEST, getAllContacts),
    takeLatest(ActionTypes.READ_CONTACT_REQUEST, getContact),
    takeLatest(ActionTypes.ADD_CONTACT_REQUEST, createContact),
    takeLatest(ActionTypes.UPDATE_CONTACT_REQUEST, updateContact),
    takeLatest(ActionTypes.DELETE_CONTACT_REQUEST, removeContact),
  ])
}
