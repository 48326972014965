import React from 'react'
import { Button, Result } from 'antd'
import { createHashHistory } from 'history'

const history = createHashHistory()

const Exception500 = () => (
  <Result
    status="500"
    title="500"
    subTitle="Sorry, the server is reporting an error."
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        Back Home
      </Button>
    }
  />
)

export default Exception500
