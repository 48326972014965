import React, { useEffect, useRef, useState } from 'react'
import { Button, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useClickAway, useKeyPress, useRequest } from 'ahooks'
import SearchService from 'services/search'
import { useAxiosCancellation } from 'hooks'
import SearchResult from './SearchResult'
import SearchContext from './SearchContext'

import styles from './style.module.scss'

export default function HiveSearch() {
  const [showSearch, setShowSearch] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [executed, setExecuted] = useState(false)
  const [data, setData] = useState({})

  const searchInputRef = useRef(null)

  const containerRef = useRef(null)

  const { cancelToken, abort, reset } = useAxiosCancellation()

  const {
    loading,
    run: search,
    cancel,
    fetches,
  } = useRequest((q) => SearchService.search(q, { cancelToken }), {
    manual: true,
    fetchKey: () => 'search',
    onSuccess: (res) => {
      setExecuted(true)
      setData(res)
    },
    onError: () => {
      setExecuted(true)
    },
  })

  useKeyPress('Escape', () => {
    if (showSearch) {
      hideLiveSearch()
    }
  })

  useKeyPress('Enter', () => {
    if (showSearch) {
      search({
        query: searchText,
      })
    }
  })

  useClickAway(() => {
    if (showSearch) {
      hideLiveSearch()
    }
  }, containerRef)

  useEffect(() => {
    if (showSearch) {
      document.querySelector('body').classList.add(styles.overflowHidden)

      if (searchInputRef.current) {
        setTimeout(() => {
          searchInputRef.current.focus()
        }, 200)
      }
    }

    return () => {
      if (showSearch) {
        document.querySelector('body').classList.remove(styles.overflowHidden)

        if (fetches?.search?.loading) {
          abort()
          cancel()
          reset()
        }
      }
    }
  }, [showSearch])

  const showLiveSearch = () => {
    setShowSearch(true)
  }

  const changeSearchText = (e) => {
    setSearchText(e.target.value)
  }

  const hideLiveSearch = () => {
    searchInputRef.current.blur()
    setShowSearch(false)
    setSearchText('')
    setData({})
    setExecuted(false)
  }

  return (
    <div ref={containerRef}>
      {/* I NEED THIS COMMENTED CODE */}
      {/* <Input
        className={styles.extInput}
        value={searchText}
        onChange={changeSearchText}
        // placeholder={formatMessage({ id: 'topBar.typeToSearch' })}
        prefix={<SearchOutlined />}
        ref={searchInputRef}
      /> */}
      <Button
        size="small"
        type="text"
        style={{ padding: 0 }}
        onClick={showLiveSearch}
        icon={<SearchOutlined />}
      />
      <div
        className={`${
          showSearch ? `${styles.livesearch} ${styles.livesearchVisible}` : styles.livesearch
        }`}
        id="livesearch"
      >
        <button className={`outline-none ${styles.close}`} type="button" onClick={hideLiveSearch}>
          <span className="hidden">Close</span>
          <i className="icmn-cross" />
        </button>
        <div className="container-fluid">
          <div className={styles.wrapper}>
            <input
              autoComplete="off"
              type="search"
              className={`outline-none ${styles.searchInput}`}
              value={searchText}
              onChange={changeSearchText}
              id="livesearchInput"
              placeholder="Type to search..."
              ref={searchInputRef}
            />
            <ul className={styles.options}>
              <li className={styles.option}>Press enter to search</li>
            </ul>
            <div className={styles.results}>
              <Spin spinning={loading} tip="Searching...">
                <SearchContext.Provider value={{ searchText }}>
                  <SearchResult data={data} executed={executed} onItemClick={hideLiveSearch} />
                </SearchContext.Provider>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
