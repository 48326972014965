import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import { getRoles } from 'services/role'

export const getRolesThunk = createAsyncThunk('hive/roles', withThunkApi(getRoles))

const rolesAdapter = createEntityAdapter()

const rolesSlice = createSlice({
  name: 'hive/roles',
  initialState: rolesAdapter.getInitialState({
    loading: 'idle',
    error: null,
    recentlyAdded: null,
  }),
  extraReducers: {
    [getRolesThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getRolesThunk.fulfilled]: (state, action) => {
      state.entity = action.payload
      state.loading = 'idle'
      rolesAdapter.setAll(state, action.payload.value)
    },
    [getRolesThunk.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = action.payload
      }
    },
  },
})

export const rolesSelector = rolesAdapter.getSelectors()

export default rolesSlice.reducer
