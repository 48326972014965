import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import ReceiverNumberService from 'services/receiverNumbers'
import { mergeActionTypes } from 'services/utils'

export const getReceiverNumbersThunk = createAsyncThunk(
  'hive/receiverNumbers/list',
  withThunkApi(ReceiverNumberService.getReceiverNumbers),
)

export const createReceiverNumbersThunk = createAsyncThunk(
  'hive/receiverNumbers/create',
  withThunkApi(ReceiverNumberService.createReceiverNumber),
)

export const updateReceiverNumbersThunk = createAsyncThunk(
  'hive/receiverNumbers/update',
  withThunkApi(ReceiverNumberService.updateReceiverNumber),
)

export const replenishReceiverNumberThunk = createAsyncThunk(
  'hive/receiverNumbers/replenish',
  withThunkApi(ReceiverNumberService.replenishReceiverNumber),
)

const receiverNumbersAdapter = createEntityAdapter()

const receiverNumbersSlice = createSlice({
  name: 'hive/receiverNumbers',
  initialState: receiverNumbersAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupReceiverNumbers: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getReceiverNumbersThunk.pending,
        createReceiverNumbersThunk.pending,
        updateReceiverNumbersThunk.pending,
        replenishReceiverNumberThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getReceiverNumbersThunk.rejected,
        createReceiverNumbersThunk.rejected,
        updateReceiverNumbersThunk.rejected,
        replenishReceiverNumberThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getReceiverNumbersThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      receiverNumbersAdapter.setAll(state, action.payload.value)
    },
    [createReceiverNumbersThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      receiverNumbersAdapter.addOne(state, action.payload)
    },
    [updateReceiverNumbersThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      receiverNumbersAdapter.updateOne(state, action.payload)
    },
  },
})

export const receiverNumbersActions = receiverNumbersSlice.actions
export const receiverNumbersSelectors = receiverNumbersAdapter.getSelectors()

export default receiverNumbersSlice.reducer
