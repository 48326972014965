import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import DocumentTemplateService from 'services/documentTemplate'
import { mergeActionTypes } from 'services/utils'

export const getDocumentTemplatesThunk = createAsyncThunk(
  'hive/documentTemplate/list',
  withThunkApi(DocumentTemplateService.getDocumentTemplates),
)

export const getDocumentTemplateThunk = createAsyncThunk(
  'hive/documentTemplate/get',
  withThunkApi(DocumentTemplateService.getDocumentTemplate),
)

export const createDocumentTemplateThunk = createAsyncThunk(
  'hive/documentTemplate/create',
  withThunkApi(DocumentTemplateService.createDocumentTemplate),
)

export const updateDocumentTemplateThunk = createAsyncThunk(
  'hive/documentTemplate/update',
  withThunkApi(DocumentTemplateService.updateDocumentTemplate),
)

const documentTemplateAdapter = createEntityAdapter()

const documentTemplateSlice = createSlice({
  name: 'hive/documentTemplate',
  initialState: documentTemplateAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupDocumentTemplate: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getDocumentTemplatesThunk.pending,
        getDocumentTemplateThunk.pending,
        createDocumentTemplateThunk.pending,
        updateDocumentTemplateThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getDocumentTemplatesThunk.rejected,
        getDocumentTemplateThunk.rejected,
        createDocumentTemplateThunk.rejected,
        updateDocumentTemplateThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getDocumentTemplatesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      documentTemplateAdapter.setAll(state, action.payload.value)
    },
    [getDocumentTemplateThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      documentTemplateAdapter.upsertOne(state, action.payload)
    },
    [createDocumentTemplateThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      documentTemplateAdapter.addOne(state, action.payload)
    },
    [updateDocumentTemplateThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      documentTemplateAdapter.updateOne(state, action.payload)
    },
  },
})

export const { cleanupDocumentTemplate } = documentTemplateSlice.actions
export const documentTemplateSelectors = documentTemplateAdapter.getSelectors()

export default documentTemplateSlice.reducer
