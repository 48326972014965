import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { mergeActionTypes, normalizeObjectProps } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'
import MonitoringService from 'services/monitoring'
import MonitoringAccountActionsService from 'services/monitoringAccountActions'

export const getMonitoringAccountsThunk = createAsyncThunk(
  'hive/monitoringAccounts/get',
  withThunkApi(MonitoringService.getMonitoringAccounts),
)

export const importMonitoringAccountThunk = createAsyncThunk(
  'hive/monitoringAccounts/import',
  withThunkApi(MonitoringAccountActionsService.importAccount),
)

export const createMonitoringAccountsThunk = createAsyncThunk(
  'hive/monitoringAccounts/create',
  withThunkApi(MonitoringService.createMonitoringAccount),
)

export const updateMonitoringAccountsThunk = createAsyncThunk(
  'hive/monitoringAccounts/update',
  withThunkApi(MonitoringService.updateMonitoringAccount),
)

export const activateMonitoringAccountsThunk = createAsyncThunk(
  'hive/monitoringAccounts/activate',
  withThunkApi(MonitoringAccountActionsService.activateAccount),
)

const monitoringAccountsAdapter = createEntityAdapter({
  selectId: (ac) => ac.monitoringAccountId,
  sortComparer: (p, n) => n.monitoringAccountId - p.monitoringAccountId,
})

const monitoringAccountsSlice = createSlice({
  name: 'hive/monitoringAccounts',
  initialState: monitoringAccountsAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        getMonitoringAccountsThunk.pending,
        createMonitoringAccountsThunk.pending,
        updateMonitoringAccountsThunk.pending,
        activateMonitoringAccountsThunk.pending,
        importMonitoringAccountThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getMonitoringAccountsThunk.rejected,
        createMonitoringAccountsThunk.rejected,
        updateMonitoringAccountsThunk.rejected,
        activateMonitoringAccountsThunk.rejected,
        importMonitoringAccountThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getMonitoringAccountsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }

      // doing this normalizeObjectProps to make all props as camelCase
      // will remove as we do this on server-side
      monitoringAccountsAdapter.setAll(state, normalizeObjectProps(action.payload))
    },
    [createMonitoringAccountsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.latest = action.payload
        monitoringAccountsAdapter.upsertOne(state, action.payload)
      }
    },
    [updateMonitoringAccountsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        monitoringAccountsAdapter.upsertOne(state, action.payload)
      }
    },
    [activateMonitoringAccountsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.entities[action.payload.id].monitoringAccount = normalizeObjectProps(action.payload)
      }
    },
    [importMonitoringAccountThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        monitoringAccountsAdapter.upsertOne(state, normalizeObjectProps(action.payload))
      }
    },
  },
})

export const monitoringAccountsSelectors = monitoringAccountsAdapter.getSelectors()

export default monitoringAccountsSlice.reducer
