import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getAccountNumbers = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/monitoringAccountNumbers${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

export const getReceiverNumberAccountNumber = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(
    `receiverNumbers/${payload.id}/availableAccountNumber${buildQuery(payload)}`,
    {
      cancelToken,
    },
  )
  return data
}

const toggleAccountNumbers = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(
    `/monitoringAccountNumbers/isActive?ids=${payload.ids}`,
    payload.isActive,
    {
      cancelToken,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return data
}

const refreshCsStatus = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(
    `/monitoringAccountNumbers/UpdateStatus?ids=${payload.ids}`,
    {
      cancelToken,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return data
}

const AccountNumberService = {
  getAccountNumbers,
  toggleAccountNumbers,
  getReceiverNumberAccountNumber,
  refreshCsStatus,
}

export default AccountNumberService
