import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getContactStatuses = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`contactStatuses${buildQuery(payload)}`, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}

const getContactStatus = async (payload, { cancelToken } = {}) => {
  const { id, odataParams } = payload

  const { data = [] } = await HttpClient.get(`contactStatuses/${id}${buildQuery(odataParams)}`, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}

const createContactStatus = async ({ values, odataParams }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.post(`contactStatuses${buildQuery(odataParams)}`, values, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}

const updateContactStatus = async ({ id, values, odataParams }, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.patch(
    `contactStatuses/${id}${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

const ContactStatusesService = {
  getContactStatuses,
  getContactStatus,
  createContactStatus,
  updateContactStatus,
}

export default ContactStatusesService
