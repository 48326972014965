import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { getSecurityLocations, createSecurityLocation } from 'services/securityZone'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getSecurityLocationsThunk = createAsyncThunk(
  'hive/securityLocations/get',
  withThunkApi(getSecurityLocations),
)

export const createSecurityLocationsThunk = createAsyncThunk(
  'hive/securityLocations/create',
  withThunkApi(createSecurityLocation),
)

const securityLocationsAdapter = createEntityAdapter()

const securityLocationsSlice = createSlice({
  name: 'hive/securityLocations',
  initialState: securityLocationsAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [getSecurityLocationsThunk.pending, createSecurityLocationsThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    [getSecurityLocationsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      securityLocationsAdapter.setAll(state, action.payload.value)
    },
    [getSecurityLocationsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
    [createSecurityLocationsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.latest = action.payload
        securityLocationsAdapter.upsertOne(state, action.payload)
      }
    },
  },
})

export const securityLocationsSelectors = securityLocationsAdapter.getSelectors()
export const securityLocationsActions = securityLocationsSlice.actions

export default securityLocationsSlice.reducer
