import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import { mergeActionTypes } from 'services/utils'
import ContactStatusesService from 'services/contactStatuses'

export const getContactStatusesThunk = createAsyncThunk(
  'hive/contactStatus/list',
  withThunkApi(ContactStatusesService.getContactStatuses),
)

export const createContactStatusThunk = createAsyncThunk(
  'hive/contactStatus/create',
  withThunkApi(ContactStatusesService.createContactStatus),
)

export const updateContactStatusThunk = createAsyncThunk(
  'hive/contactStatus/update',
  withThunkApi(ContactStatusesService.updateContactStatus),
)

const contactStatusesAdapter = createEntityAdapter({ sortComparer: (p, n) => n.id - p.id })

const contactStatusesSlice = createSlice({
  name: 'hive/contactStatus',
  initialState: contactStatusesAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        getContactStatusesThunk.pending,
        createContactStatusThunk.pending,
        updateContactStatusThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getContactStatusesThunk.rejected,
        createContactStatusThunk.rejected,
        updateContactStatusThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),

    [getContactStatusesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        contactStatusesAdapter.setAll(state, action.payload.value)
      }
    },
    [createContactStatusThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        contactStatusesAdapter.upsertOne(state, action.payload)
      }
    },
    [updateContactStatusThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        contactStatusesAdapter.upsertOne(state, action.payload)
      }
    },
  },
})

export const contactStatusesSelectors = contactStatusesAdapter.getSelectors()

export default contactStatusesSlice.reducer
