import { takeEvery, put, call, all } from 'redux-saga/effects'
import HttpClient from 'services/api'
import actions from './actions'

export function* LOAD_STATE_NAMES({ payload }) {
  yield put({
    type: 'address/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { items = [], error } = yield call(async () => {
    const countryQry = payload ? `?countryCode=${payload}` : ''
    const { data } = await HttpClient.get(`/address/states${countryQry}`)
    data.forEach((element) => {
      element.label = `${element.country2Code} - ${element.name}`
      element.value = element.isoCode
      delete element.name
    })
    return { items: data, error: null }
  })

  if (!error) {
    yield put({
      type: 'address/SET_STATE',
      payload: {
        loading: false,
        fetched: true,
        items,
      },
    })
  }
}

export function* LOAD_COUNTRY_LIST() {
  yield put({
    type: 'address/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { items = [], error } = yield call(async () => {
    const { data } = await HttpClient.get('/address/country')
    return { items: data, error: null }
  })

  if (!error) {
    yield put({
      type: 'address/SET_STATE',
      payload: {
        loading: false,
        fetched: true,
        countryList: items,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USER_LIST, LOAD_STATE_NAMES),
    takeEvery(actions.LOAD_COUNTRY_LIST, LOAD_COUNTRY_LIST),
  ])
}
