import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getStateProvinces = async ({ odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/stateProvinces${buildQuery(odataParams)}`, {
    cancelToken,
  })
  return data
}

const getStateProvinceById = async (
  { stateProvinceId, odataParams = {} },
  { cancelToken } = {},
) => {
  const { data = {} } = await HttpClient.get(
    `/stateProvinces/${stateProvinceId}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data
}

const StateProvincesService = {
  getStateProvinces,
  getStateProvinceById,
}

export default StateProvincesService
