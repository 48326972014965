import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import GenericCellPlanService from 'services/cellPlan'

import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getGenericCellPlansThunk = createAsyncThunk(
  'hive/genericCellPlans/list',
  withThunkApi(GenericCellPlanService.getGenericCellPlans),
)

export const getGenericCellPlanThunk = createAsyncThunk(
  'hive/genericCellPlans/get',
  withThunkApi(GenericCellPlanService.getGenericCellPlan),
)

export const createGenericCellPlanThunk = createAsyncThunk(
  'hive/genericCellPlans/create',
  withThunkApi(GenericCellPlanService.createGenericCellPlan),
)

export const updateGenericCellPlanThunk = createAsyncThunk(
  'hive/genericCellPlans/update',
  withThunkApi(GenericCellPlanService.editGenericCellPlan),
)

export const removeGenericCellPlanThunk = createAsyncThunk(
  'hive/genericCellPlans/remove',
  withThunkApi(GenericCellPlanService.deleteGenericCellPlan),
)

const genericCellPlanAdapter = createEntityAdapter()

const genericCellPlanSlice = createSlice({
  name: 'hive/genericCellPlans',
  initialState: genericCellPlanAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        getGenericCellPlansThunk.pending,
        getGenericCellPlanThunk.pending,
        createGenericCellPlanThunk.pending,
        removeGenericCellPlanThunk.pending,
        updateGenericCellPlanThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getGenericCellPlansThunk.rejected,
        getGenericCellPlanThunk.rejected,
        createGenericCellPlanThunk.rejected,
        removeGenericCellPlanThunk.rejected,
        updateGenericCellPlanThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getGenericCellPlansThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      genericCellPlanAdapter.setAll(state, action.payload.value)
    },
    [createGenericCellPlanThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      state.latest = action.payload
      genericCellPlanAdapter.upsertOne(state, action.payload)
    },
    ...mergeActionTypes(
      [getGenericCellPlanThunk.fulfilled, updateGenericCellPlanThunk.fulfilled],
      (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
        genericCellPlanAdapter.upsertOne(state, action.payload)
      },
    ),
    [removeGenericCellPlanThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        genericCellPlanAdapter.removeOne(state, action.meta.arg.zoneId)
      }
    },
  },
})

export const genericCellPlansActions = genericCellPlanSlice.actions
export const genericCellPlansSelectors = genericCellPlanAdapter.getSelectors()

export default genericCellPlanSlice.reducer
