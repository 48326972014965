export const ActionTypes = {
  CONTACTS_REQUEST: 'hive/contact/list/request',
  CONTACTS_SUCCESS: 'hive/contact/list/success',
  CONTACTS_FAILURE: 'hive/contact/list/failure',
  READ_CONTACT_REQUEST: 'hive/contact/get/request',
  READ_CONTACT_SUCCESS: 'hive/contact/get/success',
  READ_CONTACT_FAILURE: 'hive/contact/get/failure',
  ADD_CONTACT_REQUEST: 'hive/contact/add/request',
  ADD_CONTACT_SUCCESS: 'hive/contact/add/success',
  ADD_CONTACT_FAILURE: 'hive/contact/add/failure',
  UPDATE_CONTACT_REQUEST: 'hive/contact/update/request',
  UPDATE_CONTACT_SUCCESS: 'hive/contact/update/success',
  UPDATE_CONTACT_FAILURE: 'hive/contact/update/failure',
  DELETE_CONTACT_REQUEST: 'hive/contact/delete/request',
  DELETE_CONTACT_SUCCESS: 'hive/contact/delete/success',
  DELETE_CONTACT_FAILURE: 'hive/contact/delete/failure',
}

export const getContacts = (payload) => ({ type: ActionTypes.CONTACTS_REQUEST, payload })
export const getContactsSuccess = (response) => ({
  type: ActionTypes.CONTACTS_SUCCESS,
  payload: response,
})
export const getContactsFailure = (error) => ({ type: ActionTypes.CONTACTS_FAILURE, error })

export const getContact = (id) => ({ type: ActionTypes.READ_CONTACT_REQUEST, id })
export const getContactSuccess = (response) => ({
  type: ActionTypes.READ_CONTACT_SUCCESS,
  payload: response,
})
export const getContactFailure = (error) => ({ type: ActionTypes.READ_CONTACT_FAILURE, error })

export const createContact = (data, callback) => ({
  type: ActionTypes.ADD_CONTACT_REQUEST,
  payload: data,
  callback,
})
export const createContactSuccess = (response) => ({
  type: ActionTypes.ADD_CONTACT_SUCCESS,
  payload: response,
})
export const createContactFailure = (error) => ({ type: ActionTypes.ADD_CONTACT_FAILURE, error })

export const removeContact = (id, callback) => ({
  type: ActionTypes.DELETE_CONTACT_REQUEST,
  id,
  callback,
})
export const removeContactSuccess = (response) => ({
  type: ActionTypes.DELETE_CONTACT_SUCCESS,
  payload: response,
})
export const removeContactFailure = (error) => ({ type: ActionTypes.DELETE_CONTACT_FAILURE, error })

export const updateContact = (id, data, callback) => ({
  type: ActionTypes.UPDATE_CONTACT_REQUEST,
  id,
  payload: data,
  callback,
})
export const updateContactSuccess = (response) => ({
  type: ActionTypes.UPDATE_CONTACT_SUCCESS,
  payload: response,
})
export const updateContactFailure = (error) => ({ type: ActionTypes.UPDATE_CONTACT_FAILURE, error })
