import axios from 'axios'
import { store as reduxStore } from 'index'
import { notification } from 'antd'
import { authHeaderRequestInterceptor } from './interceptors'

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

HttpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response = {} } = error
    if (response.status === 401) {
      notification.error({
        message: 'Authentication',
        description: 'Access token has expired',
      })

      const { dispatch } = reduxStore

      dispatch({
        type: 'user/LOGOUT',
      })
    }

    return Promise.reject(error)
  },
)

HttpClient.interceptors.request.use(authHeaderRequestInterceptor)

export default HttpClient
