import axios from 'axios'

export const withThunkApi = (service) => async (payload, thunkAPI) => {
  const { signal, rejectWithValue } = thunkAPI || {}

  try {
    return await service(payload, { cancelToken: cancellation(signal) }, thunkAPI)
  } catch (error) {
    console.error(error.isAxiosError ? error.response : error)

    if (!error.response || !rejectWithValue) {
      throw error
    }

    return rejectWithValue(error.response.data)
  }
}

export const cancellation = (signal) => {
  if (!signal) return undefined

  const source = axios.CancelToken.source()
  signal.addEventListener('abort', () => {
    source.cancel()
  })
  return source.token
}

export const handleErrorMessage = (error) =>
  error?.response?.data?.message ||
  error?.response?.data?.title ||
  error?.response?.data ||
  error?.message ||
  error?.title ||
  'An error occured while handling your request'
