import WorkflowHttpClient from 'services/api/workflow'

export const getFields = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await WorkflowHttpClient.get(`/fields`, { cancelToken })
  return data
}

export const getFieldsByTemplateType = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await WorkflowHttpClient.get(`/fields/${payload}/type`, { cancelToken })
  return data
}

export const createTemplateField = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.post('/fields', payload, { cancelToken })
  return data
}

export const editTemplateField = async (field, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.put(`/fields/${field.name}`, field, {
    cancelToken,
  })
  return data
}
