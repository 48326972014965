import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getMerchant, getMerchantLogo } from 'services/merchants'
import { withThunkApi } from 'services/axios.utils'
import { mergeActionTypes } from 'services/utils'

export const getMerchantsThunk = createAsyncThunk('hive/merchant', withThunkApi(getMerchant))
export const getMerchantsLogoThunk = createAsyncThunk(
  'hive/merchant/logo',
  withThunkApi(getMerchantLogo),
)

const merchantsSlice = createSlice({
  name: 'hive/merchants',
  initialState: {
    loading: 'idle',
    entity: {},
  },
  extraReducers: {
    ...mergeActionTypes([getMerchantsThunk.pending, getMerchantsLogoThunk.pending], (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    }),
    ...mergeActionTypes(
      [getMerchantsThunk.fulfilled, getMerchantsLogoThunk.fulfilled],
      (state, action) => {
        state.entity = action.payload
        state.loading = 'idle'
      },
    ),
    ...mergeActionTypes(
      [getMerchantsThunk.rejected, getMerchantsLogoThunk.rejected],
      (state, action) => {
        state.loading = 'idle'
        state.error = action.payload
      },
    ),
  },
})

export default merchantsSlice.reducer
