import { store as reduxStore } from 'index'
import AuthService from 'services/authentication'

export const authHeaderRequestInterceptor = async (config) => {
  const state = reduxStore.getState()

  config.headers.Prefer = 'return=representation'

  if (AuthService.accessToken && AuthService.state === 'idle') {
    if (!AuthService.isAccessTokenValid()) {
      await AuthService.refreshToken()
    }

    config.headers.Authorization = `Bearer ${AuthService.accessToken}`
  }

  config.headers[process.env.REACT_APP_TENANT_HTTP_HEADER] =
    state?.app?.tenant || process.env.REACT_APP_DEFAULT_TENANT
  return config
}

export default {
  authHeaderRequestInterceptor,
}
