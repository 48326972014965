import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from './app/reducers'
import user from './user/reducers'
import users from './users/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import address from './address/reducers'

import locations from './location/reducers'
import salesPersons from './salesPerson/slice'
import roles from './roles/reducers'
import xroles from './roles/slice'
import inventory from './inventory/slice'
import distributors from './distributor/slice'
import receivedEquimpents from './receivedEquimpent/reducers'
import stockItemGroups from './stockItemGroup/slice'
import stockitems from './stockitem/reducers'
import techPersons from './techPerson/slice'
import contacts from './contact/reducers'
import layoutTemplates from './layoutTemplate/slice'
import workflowSchemas from './workflowSchema/slice'
import workflowInstances from './workflowInstance/default'
import workflowInstanceRender from './workflowInstanceRender/default'
import workOrderInstances from './workflowInstance/workorder'
import workOrderInstanceRender from './workflowInstanceRender/workorder'
import workorders from './workorders/slice'
import workflowSchemeRender from './workflowSchemeRender/slice'
import templateFields from './templateFields/slice'
import customer from './customer/slice'
import customerNotes from './customerNotes/slice'
import merchant from './merchant/slice'
import workOrderStatus from './workOrderStatus/slice'
import woServices from './woServices/slice'
import paymentProfiles from './paymentProfiles/slice'
import paymentTypes from './paymentTypes/slice'
import paymentTransactions from './paymentTransactions/slice'
import invoices from './invoices/slice'
import workflowTypes from './workflowType/slice'
import appointment from './appointment/slice'
import appointmentStatus from './appointmentStatus/slice'
import appointmentHistory from './appointmentHistory/slice'
import alarmComCellPlans from './alarmComCellPlan/slice'
import alarmComMonitoringAccount from './alarmComMonitoringAccount/slice'
import salesOrders from './salesOrders/slice'
import xlocations from './location/slice'
import emergencyContacts from './emergencyContacts/slice'
import monitoringAccounts from './monitoringAccounts/slice'
import monitoringProviders from './monitoringProviders/slice'
import monitoringStations from './monitoringStations/slice'
import deviceTypes from './avantGuardDeviceType/slice'
import quote from './quote/slice'
import securityLocations from './securityLocations/slice'
import recurringBillings from './recurringBillings/slice'
import recurringPayments from './recurringPayments/slice'
import securityZones from './securityZones/slice'
import recurringTypes from './recurringTypes/slice'
import eventCodes from './eventCodes/slice'
import quoteStatuses from './quoteStatuses/slice'
import contactStatuses from './contactStatus/slice'
import genericCellPlans from './genericCellPlan/slice'
import panelTypes from './panelTypes/slice'
import inventoryLocations from './inventoryLocation/slice'
import receiverNumbers from './receiverNumbers/slice'
import accountNumbers from './accountNumbers/slice'
import stateProvinces from './stateProvinces/slice'
import attachmentType from './attachmentType/slice'
import mergeField from './mergeField/slice'
import documentTemplate from './documentTemplate/slice'

// @plop-reducer-import

export default (history) =>
  combineReducers({
    address,
    app,
    menu,
    router: connectRouter(history),
    settings,
    user,
    users,
    locations,
    // we will rename it to "locations" when moved from saga
    xlocations,
    roles,
    // we will rename it to "roles" when moved from saga
    xroles,
    inventory,
    distributors,
    receivedEquimpents,
    stockItemGroups,
    stockitems,
    contactStatuses,
    salesPersons,
    techPersons,
    contacts,
    layoutTemplates,
    workflowSchemas,
    workflowInstances,
    workflowInstanceRender,
    workOrderInstances,
    workOrderInstanceRender,
    workflowTypes,
    templateFields,
    workorders,
    customer,
    customerNotes,
    workflowSchemeRender,
    merchant,
    workOrderStatus,
    woServices,
    paymentProfiles,
    paymentTypes,
    paymentTransactions,
    invoices,
    appointment,
    appointmentStatus,
    appointmentHistory,
    salesOrders,
    quote,
    emergencyContacts,
    monitoringAccounts,
    monitoringProviders,
    monitoringStations,
    deviceTypes,
    recurringTypes,
    securityLocations,
    securityZones,
    eventCodes,
    quoteStatuses,
    recurringBillings,
    recurringPayments,
    genericCellPlans,
    panelTypes,
    inventoryLocations,
    receiverNumbers,
    accountNumbers,
    stateProvinces,
    alarmComCellPlans,
    alarmComMonitoringAccount,
    attachmentType,
    mergeField,
    documentTemplate,
    // @plop-reducer-combine
  })
