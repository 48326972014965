export const ActionTypes = {
  STOCKITEMS_REQUEST: 'hive/stockitem/list/request',
  STOCKITEMS_SUCCESS: 'hive/stockitem/list/success',
  STOCKITEMS_FAILURE: 'hive/stockitem/list/failure',
  READ_STOCKITEM_REQUEST: 'hive/stockitem/get/request',
  READ_STOCKITEM_SUCCESS: 'hive/stockitem/get/success',
  READ_STOCKITEM_FAILURE: 'hive/stockitem/get/failure',
}

export const getStockitems = (payload) => ({ type: ActionTypes.STOCKITEMS_REQUEST, payload })
export const getStockitemsSuccess = (response) => ({
  type: ActionTypes.STOCKITEMS_SUCCESS,
  payload: response,
})
export const getStockitemsFailure = (error) => ({ type: ActionTypes.STOCKITEMS_FAILURE, error })

export const getStockitem = (id) => ({ type: ActionTypes.READ_STOCKITEM_REQUEST, id })
export const getStockitemSuccess = (response) => ({
  type: ActionTypes.READ_STOCKITEM_SUCCESS,
  payload: response,
})
export const getStockitemFailure = (error) => ({ type: ActionTypes.READ_STOCKITEM_FAILURE, error })
