import SearchHttpClient from './api/search'

const search = async (payload, { cancelToken } = {}) => {
  const { index = '*', ...values } = payload
  const { data } = await SearchHttpClient.post(`/indexes/${index}/search`, values, {
    cancelToken,
  })
  return data
}

const SearchService = {
  search,
}

export default SearchService
