import React, { useEffect, useMemo } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Helmet } from 'react-helmet'
import store from 'store'
import { SpinLoader } from 'components/LayoutComponents/Loader'
import IdleTimer from 'components/AlarmHiveComponents/IdleTimer/IdleTimer'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import FullLayout from './Full'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  full: FullLayout,
}

function IndexLayout({ children }) {
  const location = useLocation()
  const { pathname } = location

  const user = useSelector((state) => state.user)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }

    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'login'
    }

    if (/^\/contacts\/(\d+|add)/i.test(pathname)) {
      return 'full'
    }

    return 'main'
  }

  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading

  const layout = useMemo(() => {
    const Container = Layouts[getLayout()]
    const isLoginLayout = getLayout() === 'login'

    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <SpinLoader />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isUserAuthorized) {
      return <Redirect to="/user/login" />
    }
    // redirect to main dashboard when user on login page and authorized
    if (isLoginLayout && isUserAuthorized) {
      const path = store.get('returnUrl', null) || '/dashboard'

      return <Redirect to={path} />
    }

    // in other case render previously set layout
    return (
      <Container>
        {!isLoginLayout ? <IdleTimer /> : null}
        {children}
      </Container>
    )
  }, [isUserLoading, isUserAuthorized, getLayout()])

  return (
    <>
      <Helmet titleTemplate="AlarmHive | %s" title="AlarmHive" />
      {layout}
    </>
  )
}

export default IndexLayout
