export const ActionTypes = {
  RECEIVED_EQUIMPENTS_REQUEST: 'hive/received-equimpent/list/request',
  RECEIVED_EQUIMPENTS_SUCCESS: 'hive/received-equimpent/list/success',
  RECEIVED_EQUIMPENTS_FAILURE: 'hive/received-equimpent/list/failure',
  READ_RECEIVED_EQUIMPENT_REQUEST: 'hive/received-equimpent/get/request',
  READ_RECEIVED_EQUIMPENT_SUCCESS: 'hive/received-equimpent/get/success',
  READ_RECEIVED_EQUIMPENT_FAILURE: 'hive/received-equimpent/get/failure',
  ADD_RECEIVED_EQUIMPENT_REQUEST: 'hive/received-equimpent/add/request',
  ADD_RECEIVED_EQUIMPENT_SUCCESS: 'hive/received-equimpent/add/success',
  ADD_RECEIVED_EQUIMPENT_FAILURE: 'hive/received-equimpent/add/failure',
  UPDATE_RECEIVED_EQUIMPENT_REQUEST: 'hive/received-equimpent/update/request',
  UPDATE_RECEIVED_EQUIMPENT_SUCCESS: 'hive/received-equimpent/update/success',
  UPDATE_RECEIVED_EQUIMPENT_FAILURE: 'hive/received-equimpent/update/failure',
  DELETE_RECEIVED_EQUIMPENT_REQUEST: 'hive/received-equimpent/delete/request',
  DELETE_RECEIVED_EQUIMPENT_SUCCESS: 'hive/received-equimpent/delete/success',
  DELETE_RECEIVED_EQUIMPENT_FAILURE: 'hive/received-equimpent/delete/failure',
}

export const getReceivedEquimpents = (payload) => ({
  type: ActionTypes.RECEIVED_EQUIMPENTS_REQUEST,
  payload,
})
export const getReceivedEquimpentsSuccess = (response) => ({
  type: ActionTypes.RECEIVED_EQUIMPENTS_SUCCESS,
  payload: response,
})
export const getReceivedEquimpentsFailure = (error) => ({
  type: ActionTypes.RECEIVED_EQUIMPENTS_FAILURE,
  error,
})

export const getReceivedEquimpent = (id) => ({
  type: ActionTypes.READ_RECEIVED_EQUIMPENT_REQUEST,
  id,
})
export const getReceivedEquimpentSuccess = (response) => ({
  type: ActionTypes.READ_RECEIVED_EQUIMPENT_SUCCESS,
  payload: response,
})
export const getReceivedEquimpentFailure = (error) => ({
  type: ActionTypes.READ_RECEIVED_EQUIMPENT_FAILURE,
  error,
})

export const createReceivedEquimpent = (data, callback) => ({
  type: ActionTypes.ADD_RECEIVED_EQUIMPENT_REQUEST,
  payload: data,
  callback,
})
export const createReceivedEquimpentSuccess = (response) => ({
  type: ActionTypes.ADD_RECEIVED_EQUIMPENT_SUCCESS,
  payload: response,
})
export const createReceivedEquimpentFailure = (error) => ({
  type: ActionTypes.ADD_RECEIVED_EQUIMPENT_FAILURE,
  error,
})

export const removeReceivedEquimpent = (id, callback) => ({
  type: ActionTypes.DELETE_RECEIVED_EQUIMPENT_REQUEST,
  id,
  callback,
})
export const removeReceivedEquimpentSuccess = (response) => ({
  type: ActionTypes.DELETE_RECEIVED_EQUIMPENT_SUCCESS,
  payload: response,
})
export const removeReceivedEquimpentFailure = (error) => ({
  type: ActionTypes.DELETE_RECEIVED_EQUIMPENT_FAILURE,
  error,
})

export const updateReceivedEquimpent = (id, data, callback) => ({
  type: ActionTypes.UPDATE_RECEIVED_EQUIMPENT_REQUEST,
  id,
  payload: data,
  callback,
})
export const updateReceivedEquimpentSuccess = (response) => ({
  type: ActionTypes.UPDATE_RECEIVED_EQUIMPENT_SUCCESS,
  payload: response,
})
export const updateReceivedEquimpentFailure = (error) => ({
  type: ActionTypes.UPDATE_RECEIVED_EQUIMPENT_FAILURE,
  error,
})
