import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { hasPermission, IsNullOrUndefined, IsValidJSONString, getArrayKey } from 'services/utils'
import { useAntdTable, useRequest } from 'ahooks'
import axios from 'axios'
import store from 'store'
import { pdf } from '@react-pdf/renderer'
import useAHookUrlState from '@ahooksjs/use-url-state'

const defaultCurrentPage = 1
const defaultPageSize = 10

export function usePagination() {
  const location = useLocation()
  const history = useHistory()
  const search = new window.URLSearchParams(location.search)

  const sPage = +search.get('page')
  const sPageSize = +search.get('pageSize')
  const sSort = search.get('sort')
  const sOrder = search.get('order')

  const [current, setCurrent] = useState(defaultCurrentPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sort, setSort] = useState(sSort)
  const [order, setOrder] = useState(sOrder)

  useEffect(() => {
    setCurrent(sPage || defaultCurrentPage)
    setPageSize(sPageSize || defaultPageSize)
    setSort(sSort)
    setOrder(sOrder)
  }, [sPage, sPageSize, sSort, sOrder])

  function setPagination(pagination = {}, sorter = {}) {
    if (pagination.current && Number(pagination.current) > 0) {
      search.set('page', pagination.current)
    }

    if (pagination.pageSize && Number(pagination.pageSize) > 0) {
      search.set('pageSize', pagination.pageSize)
    }

    if (sorter.columnKey) {
      if (!sorter.order) {
        search.delete('order')
        search.delete('sort')
      } else {
        search.set('sort', sorter.columnKey)
        search.set('order', sorter.order)
      }
    }

    history.replace({
      search: `?${search}`,
    })
  }

  return [
    {
      page: {
        current,
        pageSize,
      },
      sorter: {
        sortField: sort,
        sortOrder: order,
      },
    },
    setPagination,
  ]
}

export const TABLE_STORAGE = {
  URL: 'url',
  LOCAL: 'localstogare',
}

export const useTableData = (name, { columns = [], callback, options = {} } = {}) => {
  const {
    pagination,
    storage = TABLE_STORAGE.URL,
    formatSearch,
    filterResult,
    ...hookOptions
  } = options

  const { field, order } = options.sorter || {}
  const location = useLocation()
  const history = useHistory()

  const search = new window.URLSearchParams(
    storage === TABLE_STORAGE.URL ? location.search : store.get(`arv.table.${name}.state`),
  )

  const sPage = +search.get('page')
  const sPageSize = +search.get('pageSize')
  const sSort = field || search.get('sort')
  const sOrder = order || search.get('order')

  const defaultSorter = {
    columnKey: sSort,
    field: sSort,
    order: sOrder,
  }

  function handleSearchParams(pagination$ = {}, ...rest) {
    const {
      current = defaultCurrentPage,
      pageSize = defaultPageSize,
      sorter = defaultSorter,
    } = pagination$
    if (current && Number(current) > 0) {
      search.set('page', current)
    }

    if (pageSize && Number(pageSize) > 0) {
      search.set('pageSize', pageSize)
    }

    let orderBy = ''

    if (sorter.columnKey || sorter.field) {
      if (!sorter.order) {
        search.delete('order')
        search.delete('sort')
      } else {
        search.set('sort', sorter.columnKey || sorter.field)
        search.set('order', sorter.order)
        orderBy = `${sorter.columnKey || sorter.field} ${/asc/.test(sorter.order) ? 'asc' : 'desc'}`
      }
    }

    if (storage === TABLE_STORAGE.URL) {
      history.replace({
        search: `?${search}`,
      })
    } else {
      store.set(`arv.table.${name}.state`, `${search}`)
    }

    if (typeof formatSearch === 'function') {
      const [fromData] = rest
      rest = [formatSearch(fromData), ...rest]
    }

    return callback({ current, pageSize, sorter, orderBy }, ...rest)
  }

  if (hookOptions.formatResult && typeof filterResult === 'function') {
    const { formatResult: orig } = hookOptions
    hookOptions.formatResult = (data) => orig(filterResult(data))
  }

  const antdTableHookProps = useAntdTable(handleSearchParams, {
    defaultPageSize,
    ...hookOptions,
    defaultParams: [
      {
        current: sPage || defaultCurrentPage,
        pageSize: sPageSize || defaultPageSize,
        sorter: defaultSorter,
      },
    ],
  })

  const {
    params: [{ sorter = {} } = {}],
    tableProps,
  } = antdTableHookProps

  if (options.sorter) {
    columns = columns.map((col) => {
      if ((col.key || col.dataIndex) === sorter.field && sorter.order) {
        col.sortOrder = sorter.order
      }

      return col
    })
  }

  return {
    ...antdTableHookProps,
    tableProps: {
      ...tableProps,
      pagination: {
        ...tableProps.pagination,
        showSizeChanger: true,
        ...pagination,
      },
    },
    columns,
  }
}

export function usePermissions(permissions) {
  const userPermissions = useSelector((state) => state.user.permissions) || []
  return hasPermission(permissions, userPermissions)
}

export function useUserType(permissions) {
  const types = useSelector((state) => state.user.userTypes) || []
  return hasPermission(permissions, types)
}

export function useUrlState(initialState, options = { navigateMode: 'replace' }) {
  const [urlState, setUrlState] = useAHookUrlState(initialState, options)

  const params = Object.entries(urlState)

  const searchParams = {}

  params.forEach(([key, value]) => {
    searchParams[key] = IsValidJSONString(value) ? JSON.parse(value) : value
  })

  const setUrlState$ = (nextState = {}) => {
    const nextParams = Object.entries(nextState)

    if (nextParams.length) {
      const next = {}

      nextParams.forEach(([key, value]) => {
        if (typeof value === 'undefined' || value === null) {
          next[key] = undefined
        } else {
          value = typeof value === 'object' || Array.isArray(value) ? JSON.stringify(value) : value
          next[key] = value
        }
      })

      setUrlState(next)
    }
  }

  return [searchParams, setUrlState$]
}

export const useQueryParams = (params) => {
  const location = useLocation()
  const queryParams = new window.URLSearchParams(location.search)

  return params.map((p) => queryParams.get(p))
}

export function useAxiosCancellation() {
  const [source, newSource] = useState(() => axios.CancelToken.source())
  const reset = () => newSource(axios.CancelToken.source())
  return { cancelToken: source.token, abort: source.cancel, reset }
}

export function usePrintPdfDocument() {
  const print = async (pdfDoc, fileName) => {
    if (!pdfDoc) {
      console.warn('Not able to print empty pdf document')
      return Promise.reject()
    }

    const blob = await pdf({
      initialValue: pdfDoc,
    }).toBlob()

    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.setAttribute('rel', 'nofollow')

    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)

    return Promise.resolve()
  }

  return [print]
}

export const useGoBack = () => {
  const history = useHistory()

  return (defaultTarget) => {
    if (!IsNullOrUndefined(defaultTarget) && defaultTarget.length && history.length <= 2) {
      history.push(defaultTarget)
    } else {
      history.goBack()
    }
  }
}

export const useFetchInactive = (action, options) => {
  const { formatResult, activeArray, inactiveValue, filterInactive } = options

  const hashActiveArray = getArrayKey(activeArray)

  const { data = [], run, loading, reset } = useRequest(action, {
    manual: true,
    formatResult,
  })

  useEffect(() => {
    if (inactiveValue) {
      let val = inactiveValue
      if (!Array.isArray(val)) {
        val = [val]
      }

      const missing = val.filter((v, arr, idx) => filterInactive(v, arr, idx, { activeArray }))

      if (missing.length) {
        run(missing)
      } else {
        reset()
      }
    }
  }, [Array.isArray(inactiveValue) ? inactiveValue.join('_') : inactiveValue, hashActiveArray])

  return { data, loading }
}

export const useDropdownLoader = (selector, storeName, [text1, text2]) => {
  const isLoading = useSelector(
    (state) => state[storeName].loading === 'pending',
  ) || false

  const tmpoptions = useSelector((state) =>
    selector.selectAll(state[storeName]),
  ) || []

  let options = []
  if (tmpoptions.length > 0) {
    options = tmpoptions.map(({
      id,
      [text1]: label1,
      [text2]: label2 }) => {

      const label = `${label1} ${(label2 !== undefined ? label2 : "")}`

      return {
        value: id,
        label: label.trim()
      }
    })
  }

  return [options, isLoading]
}

export default {
  usePagination,
  usePermissions,
  useUserType,
  useFetchInactive,
}
