import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getMergeFields = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/mergeFields${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const MergeFieldService = {
  getMergeFields,
}

export default MergeFieldService
