import PaymentHttpClient from 'services/api/payment'

export const getPaymentProfileByCustomerId = async (id, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.get(`/profiles/?customerId=${id}`, { cancelToken })
  return data
}

export const createPaymentProfile = async (values, { cancelToken } = {}) => {
  const { data } = await PaymentHttpClient.post(`/profiles`, values, { cancelToken })
  return data
}

export const updatePaymentProfile = async ({ id, values }, { cancelToken } = {}) => {
  const { data } = await PaymentHttpClient.patch(`/profiles/${id}`, values, { cancelToken })
  return data
}

export const deletePaymentProfile = async (id, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.delete(`/profiles/${id}`, { cancelToken })
  return data
}
