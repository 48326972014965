import { omit, reject } from 'lodash'
import { ActionTypes } from './actions'

export const initialState = { byId: {}, byType: [], raw: [], ids: [], info: {}, fetching: false }

export default (state = initialState, action) => {
  const { type, error, payload = {} } = action

  switch (type) {
    case ActionTypes.LOCATION_REQUEST:
    case ActionTypes.READ_LOCATION_REQUEST:
    case ActionTypes.ADD_LOCATION_REQUEST:
    case ActionTypes.UPDATE_LOCATION_REQUEST:
    case ActionTypes.DELETE_LOCATION_REQUEST:
      return { ...state, fetching: true }
    case ActionTypes.LOCATIONS_SUCCESS:
    case ActionTypes.READ_LOCATION_SUCCESS: {
      const { items, odata: info } = payload

      let ids = []
      let byId = {}

      items.forEach((item) => {
        ids = [...ids, item.id]
        byId = { ...byId, [item.id]: item }
      })

      return {
        ...state,
        byId,
        raw: items,
        info: { ...state.info, ...info },
        fetching: false,
      }
    }
    case ActionTypes.ADD_LOCATION_SUCCESS:
    case ActionTypes.UPDATE_LOCATION_SUCCESS: {
      const { id } = payload

      return {
        ...state,
        byId: { ...state.byId, [id]: payload },
        raw: [...reject(state.raw, (item) => item.id === id), payload],
        ids: [...reject(state.ids, (item) => item === id), id],
        fetching: false,
      }
    }
    case ActionTypes.DELETE_LOCATION_SUCCESS: {
      const { id, ...rest } = payload

      return {
        byId: { ...omit(state.byId, [id]), [id]: rest },
        raw: reject(state.raw, (item) => item.id === id),
        ids: reject(state.ids, (item) => item === id),
        fetching: false,
      }
    }
    case ActionTypes.ADD_LOCATION_FAILURE:
    case ActionTypes.UPDATE_LOCATION_FAILURE:
    case ActionTypes.DELETE_LOCATION_FAILURE: {
      return { ...state, fetching: false, error }
    }

    default:
      return state
  }
}
