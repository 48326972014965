// import store from 'store'

export default function getReportsMenu() {
  // Remove report menu building logic as it's not fully functional yet
  // const menu = store.get('reports_menu') || []

  // const reportsMenu = menu?.map((subMenuItem) => ({
  //   title: subMenuItem.name,
  //   key: subMenuItem.name,
  //   icon: 'icmn icmn-stats-dots',
  //   permissions: ['Reports.Read'],
  //   children: subMenuItem?.children?.map((menuItem) => ({
  //     title: menuItem.title,
  //     key: menuItem.id,
  //     url: `/reports/${menuItem.id}`,
  //     icon: 'icmn icmn-stats-dots',
  //     permissions: ['Reports.Read'],
  //   })),
  // }))

  return [
    // ...reportsMenu,
    {
      divider: true,
      permissions: ['Reports.Update'],
    },
    {
      title: 'Reports Designer',
      key: 'reports-designer',
      url: '/reports/designer',
      icon: 'icmn icmn-equalizer2',
      permissions: ['Reports.Update'],
    },
  ]
}
