import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import LocationService from 'services/location'
import { withThunkApi } from 'services/axios.utils'

export const fetchInventoryLocationsThunk = createAsyncThunk(
  'hive/inventoryLocations/list',
  withThunkApi(LocationService.fetchInventoryLocations),
  {
    condition: (payload, { getState }) => {
      const { inventoryLocations } = getState()
      return JSON.stringify(payload) !== inventoryLocations.arg
    },
  },
)

const inventoryLocationsAdapter = createEntityAdapter()

const inventoryLocationsSlice = createSlice({
  name: 'hive/inventoryLocations',
  initialState: inventoryLocationsAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [fetchInventoryLocationsThunk.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'

        if (meta.arg) {
          state.arg = JSON.stringify(meta.arg)
        }
      }
    },
    [fetchInventoryLocationsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.arg = null
        state.loading = 'idle'
        inventoryLocationsAdapter.setAll(state, action.payload.value)
      }
    },
    [fetchInventoryLocationsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.arg = null
        state.loading = 'idle'
      }
    },
  },
})

export const inventoryLocationsSelectors = inventoryLocationsAdapter.getSelectors()

export default inventoryLocationsSlice.reducer
