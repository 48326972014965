import WorkflowHttpClient from 'services/api/workflow'

export const getWorkflowSchemaRender = async ({ schemeCode, state }, { cancelToken } = {}) => {
  const { data } = await WorkflowHttpClient.get(
    `/schemas/${schemeCode}/states/${state}/rendering`,
    {
      cancelToken,
    },
  )
  return data
}

export default getWorkflowSchemaRender
