import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import StateProvincesService from 'services/stateProvinces'
import { mergeActionTypes } from 'services/utils'

export const getStateProvincesThunk = createAsyncThunk(
  'hive/stateProvinces/list',
  withThunkApi(StateProvincesService.getStateProvinces),
)

export const getStateProvinceByIdThunk = createAsyncThunk(
  'hive/stateProvinces/get',
  withThunkApi(StateProvincesService.getStateProvinceById),
)

const stateProvincesAdapter = createEntityAdapter()

const stateProvincesSlice = createSlice({
  name: 'hive/stateProvinces',
  initialState: stateProvincesAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    cleanupStateProvinces: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [getStateProvincesThunk.pending, getStateProvinceByIdThunk.pending],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [getStateProvincesThunk.rejected, getStateProvinceByIdThunk.rejected],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),
    [getStateProvincesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      stateProvincesAdapter.setAll(state, action.payload.value)
    },
    [getStateProvinceByIdThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      stateProvincesAdapter.upsertOne(state, action.payload.value)
    },
  },
})

const selectStateProvincesByCountry = createSelector(
  (state) => state.stateProvinces,
  (_, country) => country,
  (state, country) => {
    if (!country) return undefined
    const stateProvinces = stateProvincesSelectors.selectAll(state)
    return stateProvinces.filter((item) => item.country === country)
  },
)

export const stateProvincesActions = stateProvincesSlice.actions
export const stateProvincesSelectors = {
  ...stateProvincesAdapter.getSelectors(),
  selectStateProvincesByCountry,
}

export default stateProvincesSlice.reducer
