import { all, takeEvery, put } from 'redux-saga/effects'
import store from 'store'
// import qs from 'qs'
import { /* history, */ store as reduxStore } from 'index'
import actions from './actions'

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SETUP() {
  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 1024

    if (global.UI_DEBUG) console.log('Viewport size: ', global.window.innerWidth)

    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      })
    }
  }
  yield isMobileView(true)
  yield window.addEventListener('resize', () => {
    isMobileView()
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    SETUP(), // run once on app load to init listeners
  ])
}
