import 'rc-drawer/assets/index.css'
import React, { useCallback, useMemo } from 'react'
import DrawerMenu from 'rc-drawer'
import { useDispatch, useSelector } from 'react-redux'

import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'

export default function AppMenu() {
  const isMenuTop = useSelector((state) => state.settings.isMenuTop)
  const isMobileMenuOpen = useSelector((state) => state.settings.isMobileMenuOpen)
  const isMobileView = useSelector((state) => state.settings.isMobileView)
  const isLightTheme = useSelector((state) => state.settings.isLightTheme)

  const dispatch = useDispatch()

  const toggleOpen = useCallback(() => {
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }, [isMobileMenuOpen])

  const menu = useMemo(() => {
    if (isMobileView) {
      return (
        <DrawerMenu
          getContainer={null}
          level={null}
          open={isMobileMenuOpen}
          maskClosable
          onClose={toggleOpen}
          onHandleClick={toggleOpen}
          className={isLightTheme ? 'drawer-light' : ''}
        >
          <MenuLeft />
        </DrawerMenu>
      )
    }

    if (isMenuTop) {
      return <MenuTop />
    }

    return <MenuLeft />
  }, [isMobileView, isMenuTop, isMobileMenuOpen, isLightTheme])

  return menu
}
