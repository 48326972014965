import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import attachmentTypeService from 'services/attachmentType'

import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const getAttachmentTypeThunk = createAsyncThunk(
  'hive/attachmentType/get',
  withThunkApi(attachmentTypeService.getAttachmentTypes),
)

export const updateAttachmentTypeThunk = createAsyncThunk(
  'hive/attachmentType/update',
  withThunkApi(attachmentTypeService.editAttachmentType),
)

const attachmentTypeAdapter = createEntityAdapter()

const attachmentTypeSlice = createSlice({
  name: 'hive/attachmentType',
  initialState: attachmentTypeAdapter.getInitialState({
    loading: 'idle',
    latest: null,
  }),
  reducers: {
    cleanupLatest: (state) => {
      state.latest = null
      state.entities = {}
    },
  },
  extraReducers: {
    ...mergeActionTypes([
      getAttachmentTypeThunk.pending,
      updateAttachmentTypeThunk.pending
    ], (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    }),
    ...mergeActionTypes([
      getAttachmentTypeThunk.rejected,
      updateAttachmentTypeThunk.rejected
    ], (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    }),
    [getAttachmentTypeThunk.fulfilled]: (state, action) => {
     
      if (state.loading === 'pending') {
        state.loading = 'idle'
        attachmentTypeAdapter.setAll(state, action.payload)
      }
    },
    ...mergeActionTypes([
      getAttachmentTypeThunk.fulfilled,
    ], (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.entities = action.payload
      }
    }),

    ...mergeActionTypes([
      updateAttachmentTypeThunk.fulfilled
    ], (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    })
  },
})

export const { cleanupLatest } = attachmentTypeSlice.actions
export const attachmentTypeSelectors = attachmentTypeAdapter.getSelectors()

export default attachmentTypeSlice.reducer
