import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { getSalesPersons } from 'services/salesPerson'
import { withThunkApi } from 'services/axios.utils'

export const getSalesPersonsThunk = createAsyncThunk(
  'hive/salesPersons/get',
  withThunkApi(getSalesPersons),
)

const salesPersonsAdapter = createEntityAdapter()

const salesPersonsSlice = createSlice({
  name: 'hive/salesPersons',
  initialState: salesPersonsAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    [getSalesPersonsThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getSalesPersonsThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      salesPersonsAdapter.setAll(state, action.payload)
    },
    [getSalesPersonsThunk.rejected]: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    },
  },
})

export const salesPersonsSelectors = salesPersonsAdapter.getSelectors()

export default salesPersonsSlice.reducer
