import { all, takeLatest, put } from 'redux-saga/effects'
import { fetchQuotesThunk, createQuoteThunk, updateQuoteThunk } from './slice'

function* reloadWorkOrder(action) {
  const {
    meta: { arg = {} },
    payload,
  } = action

  const { customerId } = payload
  const { _sagas_ } = arg

  if (_sagas_.reload) {
    yield put(
      fetchQuotesThunk({
        customerId,
        odataParams: {
          expand: [
            { salesLocation: { select: ['id', 'name'] } },
            { salesPerson: { select: ['id', 'firstName', 'lastName'] } },
            { creator: { select: ['id', 'firstName', 'lastName'] } },
            {
              installLocation: {
                select: [
                  'id',
                  'type',
                  'buildingName',
                  'street',
                  'apartment',
                  'city',
                  'state',
                  'county',
                  'zipCode',
                ],
              },
            },
            { equipment: { expand: 'inventoryItem' } },
            { serviceItems: { expand: 'serviceItem' } },
          ],
          select: [
            'id',
            'customerId',
            'salesLocation',
            'salesPerson',
            'creator',
            'date',
            'installLocation',
            'installLocationId',
            'salesPersonId',
            'description',
            'salesLocationId',
            'serviceItems',
            'equipment',
            'subTotal',
            'salesTax',
            'total',
            'statusId',
          ],
        },
      }),
    )
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(createQuoteThunk.fulfilled, reloadWorkOrder),
    takeLatest(updateQuoteThunk.fulfilled, reloadWorkOrder),
  ])
}
