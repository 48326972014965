export const ActionTypes = {
  ROLES_REQUEST: 'hive/roles/list/request',
  ROLES_SUCCESS: 'hive/roles/list/success',
  ROLES_FAILURE: 'hive/roles/list/failure',
  READ_ROLE_REQUEST: 'hive/role/get/request',
  READ_ROLE_SUCCESS: 'hive/role/get/success',
  READ_ROLE_FAILURE: 'hive/role/get/failure',
  ADD_ROLE_REQUEST: 'hive/role/add/request',
  ADD_ROLE_SUCCESS: 'hive/role/add/success',
  ADD_ROLE_FAILURE: 'hive/role/add/failure',
  UPDATE_ROLE_REQUEST: 'hive/role/update/request',
  UPDATE_ROLE_SUCCESS: 'hive/role/update/success',
  UPDATE_ROLE_FAILURE: 'hive/role/update/failure',
  DELETE_ROLE_REQUEST: 'hive/role/delete/request',
  DELETE_ROLE_SUCCESS: 'hive/role/delete/success',
  DELETE_ROLE_FAILURE: 'hive/role/delete/failure',
}

export const getRoles = (payload) => ({ type: ActionTypes.ROLES_REQUEST, payload })
export const getRolesSuccess = (response) => ({
  type: ActionTypes.ROLES_SUCCESS,
  payload: response,
})
export const getRolesFailure = (error) => ({ type: ActionTypes.ROLES_FAILURE, error })

export const getRole = (id) => ({ type: ActionTypes.READ_ROLE_REQUEST, id })
export const getRoleSuccess = (response) => ({
  type: ActionTypes.READ_ROLE_SUCCESS,
  payload: response,
})
export const getRoleFailure = (error) => ({ type: ActionTypes.READ_ROLE_FAILURE, error })

export const createRole = (data, callback) => ({
  type: ActionTypes.ADD_ROLE_REQUEST,
  payload: data,
  callback,
})
export const createRoleSuccess = (response) => ({
  type: ActionTypes.ADD_ROLE_SUCCESS,
  payload: response,
})
export const createRoleFailure = (error) => ({ type: ActionTypes.ADD_ROLE_FAILURE, error })

export const removeRole = (id, callback) => ({
  type: ActionTypes.DELETE_ROLE_REQUEST,
  id,
  callback,
})
export const removeRoleSuccess = (response) => ({
  type: ActionTypes.DELETE_ROLE_SUCCESS,
  payload: response,
})
export const removeRoleFailure = (error) => ({ type: ActionTypes.DELETE_ROLE_FAILURE, error })

export const updateRole = (id, data, callback) => ({
  type: ActionTypes.UPDATE_ROLE_REQUEST,
  id,
  payload: { data },
  callback,
})
export const updateRoleSuccess = (response) => ({
  type: ActionTypes.UPDATE_ROLE_SUCCESS,
  payload: response,
})
export const updateRoleFailure = (error) => ({ type: ActionTypes.UPDATE_ROLE_FAILURE, error })
