import { createSelector } from '@reduxjs/toolkit'
import get from 'get-value'

import WorkflowInstanceRenderProducer from '.'

const customerFlowRenderSlice = new WorkflowInstanceRenderProducer('hive/customer-flow-render')

const { workflowStateRenderSelectors: selectors } = customerFlowRenderSlice

const getInstanceRender = createSelector(
  (state) => state.workflowInstanceRender,
  (_, instanceId) => instanceId,
  (r, id) => get(selectors.selectById(r, id), 'components', []),
)

const getInstanceRenderTabs = createSelector(getInstanceRender, (sections) =>
  sections.filter(({ name }) => !~['contact', 'equipmentAndServices', 'appointment'].indexOf(name)),
)

export const workflowStateRenderSelectors = {
  getInstanceRenderTabs,
  getInstanceRender,
  ...selectors,
}

export const {
  getWorkflowInstanceRenderThunk,
  setActiveInstance,
  cleanupInstanceRenderEntities,
} = customerFlowRenderSlice

export default customerFlowRenderSlice.reducer
