import PaymentHttpClient from 'services/api/payment'
import buildQuery from 'odata-query'

export const getPaymentTransactions = async (payload, { cancelToken } = {}) => {
  const query = buildQuery(payload)
  const queryending = payload.includeInvoiceAmount
    ? `${query ? '&' : '?'}includeInvoiceAmount=true`
    : ''
  const { data = {} } = await PaymentHttpClient.get(`/paymentTransactions${query}${queryending}`, {
    cancelToken,
  })
  return data
}

export const createPaymentTransaction = async (payload, { cancelToken } = {}) => {
  const { data } = await PaymentHttpClient.post(`/transactions`, payload, { cancelToken })
  return data
}

// TODO: Remove references to this, swap to getSalesOrderBalances
export const getPaymentBalance = async (
  { customerId, paid = null, salesOrderId = null },
  { cancelToken } = {},
) => {
  const { data = {} } = await PaymentHttpClient.get(
    `/balance/${customerId}?paid=${paid !== null ? paid : ''}&salesOrderId=${
      salesOrderId !== null ? salesOrderId : ''
    }`,
    { cancelToken },
  )
  return data
}

export const getCustomerBalance = async ({ customerId, paid = null }, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.get(
    `/customerbalance/${customerId}?paid=${paid !== null ? paid : ''}`,
    { cancelToken },
  )
  return data
}

export const getSalesOrderBalances = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await PaymentHttpClient.get(`balances${buildQuery(payload.odataParams)}`, {
    cancelToken,
  })
  return data.value
}

export const getPaymentTransactionBySalesOrderId = async ({ id }, { cancelToken } = {}) => {
  const { data = {} } = await PaymentHttpClient.get(`/transactions?salesOrderId=${id}`, {
    cancelToken,
  })
  return data
}

export default {
  getPaymentTransactions,
  createPaymentTransaction,
  getPaymentBalance,
  getCustomerBalance,
  getPaymentTransactionBySalesOrderId,
  getSalesOrderBalances,
}
