import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const fetchLocations = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/locations${buildQuery(payload)}`, { cancelToken })
  return data
}

const fetchInventoryLocations = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/inventoryLocations${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const fetchTaxRatesByZipCode = async ({ zipCode }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`taxRates?ZipCode=${zipCode}`, { cancelToken })
  return data
}

const updateLocationTaxRate = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.put(`taxRates/${payload?.zipCode}`, payload, {
    cancelToken,
  })
  return data
}

const LocationService = {
  fetchLocations,
  fetchInventoryLocations,
  fetchTaxRatesByZipCode,
  updateLocationTaxRate,
}

export default LocationService
