import HttpClient from 'services/api'
import buildQuery from 'odata-query'

export const MONITORING_PROVIDER = {
  GENERIC: 'Generic',
  AVANTGUARD: 'AvantGuard',
  GENERIC_CELL_FORWARDING: 'GenericCF',
  AFFILIATED: 'Affiliated',
  DISPATCHCENTER: 'DispatchCenter',
  ALARMCOM: 'AlarmCom'
}

const createMonitoringAccount = async (payload, { cancelToken } = {}) => {
  const { odataParams, values } = payload
  const { data = {} } = await HttpClient.post(
    `/CustomerMonitoringAccounts${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  return data
}

const updateMonitoringAccount = async (payload, { cancelToken } = {}) => {
  const { odataParams, values } = payload

  const { data = {} } = await HttpClient.patch(
    `/CustomerMonitoringAccounts${buildQuery(odataParams)}`,
    values,
    {
      cancelToken,
    },
  )
  return data
}

const getMonitoringAccounts = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `/CustomerMonitoringAccounts${buildQuery(payload.odataParams)}`,
    { cancelToken },
  )
  return data
}

const getDeviceTypes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`deviceTypes${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const updateDeviceType = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`deviceTypes/${payload.id}`, payload, {
    cancelToken,
  })
  return data
}

export const fetchMonitoringProviders = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get('providers', { cancelToken })
  return data.value
}

const getEventCodes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/eventCodes${buildQuery(payload)}`, {
    cancelToken,
  })
  return data.value
}

const createEventCodes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/eventCodes`, payload, { cancelToken })
  return data
}

const MonitoringService = {
  createMonitoringAccount,
  updateMonitoringAccount,
  getMonitoringAccounts,
  updateDeviceType,
  fetchMonitoringProviders,
  getDeviceTypes,
  getEventCodes,
  createEventCodes,
}

export default MonitoringService
