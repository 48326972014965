import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import AppointmentService from 'services/appointment'
import { withThunkApi } from 'services/axios.utils'

export const getWorkOrderAppointmentHistoryThunk = createAsyncThunk(
  'hive/workorder/appointment/history',
  withThunkApi(AppointmentService.fetchWorkOrderAppointmentHistory),
)

const appointmentHistoryAdapter = createEntityAdapter()

const appointmentHistorySlice = createSlice({
  name: 'hive/appointment/history',
  initialState: appointmentHistoryAdapter.getInitialState({
    loading: 'idle',
    error: false,
  }),
  extraReducers: {
    [getWorkOrderAppointmentHistoryThunk.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    [getWorkOrderAppointmentHistoryThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
      appointmentHistoryAdapter.upsertOne(state, action.payload)
    },
  },
})

export const appointmentHistorySelectors = appointmentHistoryAdapter.getSelectors()

export default appointmentHistorySlice.reducer
