export function parseResponse(res) {
  return Object.keys(res).reduce((acc, next) => {
    if (next.startsWith('@odata')) {
      const [, key] = next.split('.')
      acc.odata = {
        ...acc.odata,
        [key]: res[next],
      }
      delete res[next]
      return acc
    }

    acc[next] = res[next]

    return acc
  }, {})
}

export default {
  parseResponse,
}
