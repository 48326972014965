import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { withThunkApi } from 'services/axios.utils'
import { mergeActionTypes } from 'services/utils'
import CustomerNotesService from 'services/customerNotes'

export const getCustomerNotesThunk = createAsyncThunk(
  'hive/customer/notes',
  withThunkApi(CustomerNotesService.getCustomerNotes),
)

export const getCustomerNoteByIdThunk = createAsyncThunk(
  'hive/customer/notes/byId',
  withThunkApi(CustomerNotesService.getCustomerNoteById),
)

export const createCustomerNoteThunk = createAsyncThunk(
  'hive/customer/notes/create',
  withThunkApi(CustomerNotesService.createCustomerNote),
)

export const updateCustomerNoteThunk = createAsyncThunk(
  'hive/customer/notes/update',
  withThunkApi(CustomerNotesService.updateCustomerNote),
)

export const deleteCustomerNoteThunk = createAsyncThunk(
  'hive/customer/notes/delete',
  withThunkApi(CustomerNotesService.deleteCustomerNote),
)

const customerNotesAdapter = createEntityAdapter({ sortComparer: (p, n) => n.id - p.id })

const customerNotesSlice = createSlice({
  name: 'hive/customer/notes',
  initialState: customerNotesAdapter.getInitialState({
    loading: 'idle',
  }),
  extraReducers: {
    ...mergeActionTypes(
      [
        getCustomerNotesThunk.pending,
        getCustomerNoteByIdThunk.pending,
        createCustomerNoteThunk.pending,
        updateCustomerNoteThunk.pending,
        deleteCustomerNoteThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      },
    ),
    ...mergeActionTypes(
      [
        getCustomerNotesThunk.rejected,
        getCustomerNoteByIdThunk.rejected,
        createCustomerNoteThunk.rejected,
        updateCustomerNoteThunk.rejected,
        deleteCustomerNoteThunk.rejected,
      ],
      (state) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
        }
      },
    ),

    [getCustomerNotesThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        customerNotesAdapter.setAll(state, action.payload)
      }
    },
    [getCustomerNoteByIdThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        customerNotesAdapter.addOne(state, action.payload)
      }
    },
    [createCustomerNoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        customerNotesAdapter.upsertOne(state, action.payload)
      }
    },

    [updateCustomerNoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        customerNotesAdapter.upsertOne(state, action.payload)
      }
    },
    [deleteCustomerNoteThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id = 0 } = action?.meta?.arg
        customerNotesAdapter.removeOne(state, id)
      }
    },
  },
})

export const customerNotesSelectors = customerNotesAdapter.getSelectors()

export default customerNotesSlice.reducer
