import WorkflowHttpClient from 'services/api/workflow'

export const updateWorkflowScheme = async ({ schemeCode, scheme }, { cancelToken } = {}) => {
  const { data } = await WorkflowHttpClient.patch(`/schemas/${schemeCode}`, scheme, { cancelToken })
  return data
}

export const getWorkflowSchemaStates = async (schemeCode, { cancelToken } = {}) => {
  const { data } = await WorkflowHttpClient.get(`/schemas/${schemeCode}/states`, { cancelToken })
  return data.value
}

export const getWorkflowSchemaByCode = async (schemeCode, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.get(`/schemas/${schemeCode}`, { cancelToken })

  delete data['@odata.context']

  data.meta = (data.tags || []).reduce((meta, tag) => {
    const [key, value] = tag.split(':')

    if (!meta[key]) {
      meta[key] = value
    }

    return meta
  }, {})

  return data
}

export const getWorkflowSchemas = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.get(`/schemas`, { cancelToken })

  data.value = data.value.map((item) => {
    item.meta = item.tags.reduce((meta, tag) => {
      const [key, value] = tag.split(':')

      if (!meta[key]) {
        meta[key] = value
      }

      return meta
    }, {})

    return item
  })

  return data.value
}

export const createWorkflowScheme = async ({ scheme }, { cancelToken } = {}) => {
  const { data } = await WorkflowHttpClient.post(`/schemas`, scheme, { cancelToken })
  return data
}

export const getWorkflowCommandsByType = async (typeId, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.get(`/types/${typeId}/commands`, { cancelToken })
  return data.value
}

export const getWorkflowStatesByType = async (typeId, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.get(`/types/${typeId}/states`, { cancelToken })
  return data.value
}

export const getWorkflowTypes = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await WorkflowHttpClient.get(`/types`, { cancelToken })
  return data.value
}
