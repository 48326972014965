import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import {
  createSalesOrder,
  getSalesOrders,
  addSalesOrderEquipment,
  addSalesOrderService,
  getSalesOrderServices,
  getSalesOrderEquipment,
  getSalesOrderById,
  deleteSalesOrderEquipment,
  deleteSalesOrderService,
  editSalesOrderEquipment,
  editSalesOrderService,
  updateSalesOrder,
  getSalesOrder,
} from 'services/salesOrder'
import { mergeActionTypes } from 'services/utils'
import { withThunkApi } from 'services/axios.utils'

export const createSalesOrderThunk = createAsyncThunk(
  'hive/salesOrders/create',
  withThunkApi(createSalesOrder),
)

export const updateSalesOrderThunk = createAsyncThunk(
  'hive/salesOrders/update',
  withThunkApi(updateSalesOrder),
)

export const fetchSalesOrdersThunk = createAsyncThunk(
  'hive/salesOrders/list',
  withThunkApi(getSalesOrders),
)

export const fetchSalesOrderByIdThunk = createAsyncThunk(
  'hive/salesOrder/getById',
  withThunkApi(getSalesOrderById),
)

export const fetchSalesOrderThunk = createAsyncThunk(
  'hive/salesOrder/get',
  withThunkApi(getSalesOrder),
)

export const addSalesOrderServiceThunk = createAsyncThunk(
  'hive/salesOrders/add/services',
  withThunkApi(addSalesOrderService),
)

export const addSalesOrderEquipmentThunk = createAsyncThunk(
  'hive/salesOrders/add/equipment',
  withThunkApi(addSalesOrderEquipment),
)

export const fetchSalesOrderServicesThunk = createAsyncThunk(
  'hive/salesOrders/get/services',
  withThunkApi(getSalesOrderServices),
)
export const fetchSalesOrderEquipmentThunk = createAsyncThunk(
  'hive/salesOrders/get/equipment',
  withThunkApi(getSalesOrderEquipment),
)

export const removeSalesOrderServiceThunk = createAsyncThunk(
  'hive/salesOrders/delete/services',
  withThunkApi(deleteSalesOrderService),
)

export const removeSalesOrderEquipmentThunk = createAsyncThunk(
  'hive/salesOrders/delete/equipment',
  withThunkApi(deleteSalesOrderEquipment),
)

export const editSalesOrderServiceThunk = createAsyncThunk(
  'hive/salesOrders/edit/service',
  withThunkApi(editSalesOrderService),
)

export const editSalesOrderEquipmentThunk = createAsyncThunk(
  'hive/salesOrders/edit/equipment',
  withThunkApi(editSalesOrderEquipment),
)

const salesOrdersAdapter = createEntityAdapter()

const salesOrdersSlice = createSlice({
  name: 'hive/salesOrders',
  initialState: salesOrdersAdapter.getInitialState({
    loading: 'idle',
    error: false,
    serviceItems: [],
    equipment: [],
  }),
  reducers: {
    cleanupSalesOrders: (state) => {
      state.entities = {}
      state.ids = []
    },
  },
  extraReducers: {
    ...mergeActionTypes(
      [
        fetchSalesOrdersThunk.pending,
        fetchSalesOrderByIdThunk.pending,
        createSalesOrderThunk.pending,
        addSalesOrderServiceThunk.pending,
        addSalesOrderEquipmentThunk.pending,
        fetchSalesOrderServicesThunk.pending,
        removeSalesOrderServiceThunk.pending,
        fetchSalesOrderEquipmentThunk.pending,
        removeSalesOrderEquipmentThunk.pending,
        editSalesOrderServiceThunk.pending,
        editSalesOrderEquipmentThunk.pending,
        updateSalesOrderThunk.pending,
        fetchSalesOrderThunk.pending,
      ],
      (state) => {
        if (state.loading === 'idle') state.loading = 'pending'
      },
    ),
    ...mergeActionTypes(
      [
        fetchSalesOrdersThunk.rejected,
        fetchSalesOrderByIdThunk.rejected,
        createSalesOrderThunk.rejected,
        addSalesOrderServiceThunk.rejected,
        addSalesOrderEquipmentThunk.rejected,
        fetchSalesOrderServicesThunk.rejected,
        removeSalesOrderServiceThunk.rejected,
        fetchSalesOrderEquipmentThunk.rejected,
        removeSalesOrderEquipmentThunk.rejected,
        editSalesOrderServiceThunk.rejected,
        editSalesOrderEquipmentThunk.rejected,
        updateSalesOrderThunk.rejected,
        fetchSalesOrderThunk.rejected,
      ],
      (state, action) => {
        state.loading = 'idle'
        if (action.payload) {
          state.error = action.payload.Message
        } else {
          state.error = action.error
        }
      },
    ),
    ...mergeActionTypes(
      [fetchSalesOrderServicesThunk.fulfilled, fetchSalesOrderEquipmentThunk.fulfilled],
      (state, action) => {
        state.loading = 'idle'
        state.services = action.payload
      },
    ),
    [fetchSalesOrdersThunk.fulfilled]: (state, action) => {
      state.loading = 'idle'
      salesOrdersAdapter.upsertMany(state, action.payload)
    },

    ...mergeActionTypes(
      [fetchSalesOrderByIdThunk.fulfilled, fetchSalesOrderThunk.fulfilled],
      (state, action) => {
        state.loading = 'idle'
        salesOrdersAdapter.upsertOne(state, action.payload)
      },
    ),

    [createSalesOrderThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        salesOrdersAdapter.addOne(state, action.payload)
      }
    },

    [addSalesOrderServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const id = action.payload.salesOrderId
        const { serviceItems = [] } = salesOrdersSelectors.selectById(state, id) || {}
        salesOrdersAdapter.updateOne(state, {
          id,
          changes: {
            serviceItems: [...serviceItems, action.payload],
          },
        })
      }
    },

    [addSalesOrderEquipmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const id = action.payload.salesOrderId
        const { equipment = [] } = salesOrdersSelectors.selectById(state, id) ?? {}
        salesOrdersAdapter.updateOne(state, {
          id,
          changes: {
            equipment: [...equipment, action.payload],
          },
        })
      }
    },
    [removeSalesOrderServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, salesOrderId } = action.meta.arg
        const { serviceItems = [] } = salesOrdersSelectors.selectById(state, salesOrderId) ?? {}
        const changes = {
          id: salesOrderId,
          changes: {
            serviceItems: serviceItems.filter((item) => item.id !== id),
          },
        }

        salesOrdersAdapter.updateOne(state, changes)
      }
    },

    [removeSalesOrderEquipmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, salesOrderId } = action.meta.arg
        const { equipment = [] } = salesOrdersSelectors.selectById(state, salesOrderId) ?? {}
        const changes = {
          id: salesOrderId,
          changes: {
            equipment: equipment.filter((item) => item.id !== id),
          },
        }

        salesOrdersAdapter.updateOne(state, changes)
      }
    },
    [editSalesOrderServiceThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, salesOrderId } = action.meta.arg
        let { serviceItems = [] } = salesOrdersSelectors.selectById(state, salesOrderId) ?? {}
        serviceItems = serviceItems.map((item) => (item.id === id ? action.payload : item))
        const changes = {
          id: salesOrderId,
          changes: {
            serviceItems,
          },
        }

        salesOrdersAdapter.updateOne(state, changes)
      }
    },

    [editSalesOrderEquipmentThunk.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        const { id, salesOrderId } = action.meta.arg
        let { equipment = [] } = salesOrdersSelectors.selectById(state, salesOrderId) ?? {}
        equipment = equipment.map((item) => (item.id === id ? action.payload : item))
        const changes = {
          id: salesOrderId,
          changes: {
            equipment,
          },
        }

        salesOrdersAdapter.updateOne(state, changes)
      }
    },
  },
})

const selectSalesOrderEquipment = createSelector(
  (state) => state.salesOrders,
  (_, props) => props,
  (state, { equipmentId, salesOrderId }) => {
    if (!equipmentId) return undefined
    const { equipment = [] } = salesOrdersSelectors.selectById(state, salesOrderId) ?? {}
    return equipment.find(({ id }) => id === equipmentId)
  },
)
const selectSalesOrderService = createSelector(
  (state) => state.salesOrders,
  (_, props) => props,
  (state, { serviceId, salesOrderId }) => {
    if (!serviceId) return undefined
    const { serviceItems = [] } = salesOrdersSelectors.selectById(state, salesOrderId) ?? {}
    return serviceItems.find(({ id }) => id === serviceId)
  },
)

const selectSalesOrdersByWorkOrderId = createSelector(
  (state) => state.salesOrders,
  (_, workOrderId) => workOrderId,
  (state, workOrderId) => {
    if (!workOrderId) return undefined
    const salesOrders = salesOrdersSelectors.selectAll(state)
    return salesOrders.filter((item) => item.workOrderId === workOrderId)
  },
)

const selectSalesOrdersByQuoteId = createSelector(
  (state) => state.salesOrders,
  (_, quoteId) => quoteId,
  (state, quoteId) => {
    if (!quoteId) return undefined
    const salesOrders = salesOrdersSelectors.selectAll(state)
    return salesOrders.filter((item) => item.quoteId === quoteId)
  },
)

export const { cleanupSalesOrders } = salesOrdersSlice.actions

export const salesOrdersSelectors = {
  ...salesOrdersAdapter.getSelectors(),
  selectSalesOrderEquipment,
  selectSalesOrderService,
  selectSalesOrdersByWorkOrderId,
  selectSalesOrdersByQuoteId,
}

export default salesOrdersSlice.reducer
