import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'
import WorkflowHttpClient from 'services/api/workflow'
import WorkflowService from 'services/workflow'
import { withThunkApi } from 'services/axios.utils'

export default function WorkflowInstanceProducer(name) {
  const workflowInstancesAdapter = createEntityAdapter()

  const createWorkflowInstanceThunk = createAsyncThunk(`${name}/create`, async (payload) => {
    const { data } = await WorkflowHttpClient.post('/instances', payload)
    return data
  })

  const getWorkflowInstanceThunk = createAsyncThunk(
    `${name}/getById`,
    async (id) => {
      const { data } = await WorkflowHttpClient.get(`/instances/${id}`)
      return data
    },
    {
      condition: (payload, { getState }) => {
        const { workflowInstances, workOrderInstances } = getState()
        const combinedRequests = { ...workflowInstances.requests, ...workOrderInstances.requests }
        return !combinedRequests[payload] === true
      },
    },
  )

  const executeCommandThunk = createAsyncThunk(
    `${name}/transition/execute`,
    withThunkApi(WorkflowService.executeCommand),
  )

  const workflowInstancesSlice = createSlice({
    name,
    initialState: workflowInstancesAdapter.getInitialState({
      loading: 'idle',
      error: false,
      requests: {},
    }),
    extraReducers: {
      [getWorkflowInstanceThunk.pending]: (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.requests[action.meta.arg] = true
        }
      },
      [createWorkflowInstanceThunk.pending]: (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.requests[action.meta.arg] = true
        }
      },
      [getWorkflowInstanceThunk.fulfilled]: (state, { payload, meta }) => {
        if (state.loading === 'pending' && payload) {
          state.loading = 'idle'
          state.requests[meta.arg] = false
          workflowInstancesAdapter.upsertOne(state, payload)
        }
      },
      [createWorkflowInstanceThunk.fulfilled]: (state, { payload, meta }) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'

          state.requests[meta.arg] = false
          workflowInstancesAdapter.addOne(state, payload)
        }
      },
      [getWorkflowInstanceThunk.rejected]: (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle'
          state.requests[action.meta.arg] = false
        }
      },
    },
  })

  const workflowInstancesSelectors = workflowInstancesAdapter.getSelectors()

  return {
    reducer: workflowInstancesSlice.reducer,
    workflowInstancesSelectors,
    executeCommandThunk,
    getWorkflowInstanceThunk,
    createWorkflowInstanceThunk,
  }
}
