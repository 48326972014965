import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const getReceiverNumbers = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`/receiverNumbers${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const createReceiverNumber = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/receiverNumbers`, payload, { cancelToken })
  return data
}

const updateReceiverNumber = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`/receiverNumbers/${payload.id}`, payload, {
    cancelToken,
  })
  return data
}

const replenishReceiverNumber = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`/receiverNumbers/${payload.id}/replenish`, {
    cancelToken,
  })
  return data
}

const ReceiverNumberService = {
  getReceiverNumbers,
  createReceiverNumber,
  updateReceiverNumber,
  replenishReceiverNumber,
}

export default ReceiverNumberService
