import HttpClient from 'services/api'
import buildQuery from 'odata-query'

const fetchMonitoringStations = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(`monitoringStations${buildQuery(payload)}`, {
    cancelToken,
  })
  return data
}

const fetchMonitoringStation = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `monitoringStations/${payload.id}${buildQuery(payload)}`,
    { cancelToken },
  )
  return data
}

const createMonitoringStation = async (payload, { cancelToken } = {}) => {
  const response = await HttpClient.post(`monitoringStations`, payload, { cancelToken })
  return response
}

const updateMonitoringStation = async (payload, { cancelToken } = {}) =>
  HttpClient.patch(`monitoringStations/${payload?.id}`, payload, { cancelToken })

const MonitoringStationsService = {
  fetchMonitoringStations,
  fetchMonitoringStation,
  createMonitoringStation,
  updateMonitoringStation,
}

export default MonitoringStationsService
