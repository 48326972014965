import buildQuery from 'odata-query'
import HttpClient from 'services/api'

export const getSalesOrders = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`salesorders${buildQuery(payload.odataParams)}`, {
    cancelToken,
  })
  return data.value
}

export const getSalesOrderList = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`salesorders${buildQuery(payload.odataParams)}`, {
    cancelToken,
  })
  return data
}

export const getSalesOrderById = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`salesorders/${payload.salesOrderId}`, {
    cancelToken,
  })
  return data.value
}

export const getSalesOrder = async ({ salesOrderId, odataParams = {} }, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.get(
    `salesorders/${salesOrderId}${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )

  return data
}

export const getSalesOrderServices = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`salesorders/${payload.salesOrderId}/serviceitems`, {
    cancelToken,
  })
  return data.value
}

export const getSalesOrderEquipment = async (payload, { cancelToken } = {}) => {
  const { data = [] } = await HttpClient.get(`salesorders/${payload.salesOrderId}/equipment`, {
    cancelToken,
  })
  return data.value
}

const setInstallEquipment = async (payload, { cancelToken } = {}) => {
  const { salesOrderId, salesOrderLineId, values } = payload
  const { data = [] } = await HttpClient.post(
    `salesorders/${salesOrderId}/equipment/${salesOrderLineId}/install`,
    values,
    {
      cancelToken,
    },
  )
  return data.value
}

export const getInstallEquipment = async (payload, { cancelToken } = {}) => {
  const { workOrderId, odataParams } = payload
  const { data = [] } = await HttpClient.get(
    `workorders/${workOrderId}/installedItems${buildQuery(odataParams)}`,
    {
      cancelToken,
    },
  )
  return data.value
}

export const createSalesOrder = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.post(`salesorders`, payload, { cancelToken })
  delete data['@odata.context']
  return data
}

export const updateSalesOrder = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.patch(`salesorders/${payload.id}`, payload, {
    cancelToken,
  })
  delete data['@odata.context']
  return data
}

export const addSalesOrderEquipment = async (payload, { cancelToken } = {}) => {
  const { salesOrderId, odataParams, values } = payload

  const { data = {} } = await HttpClient.post(
    `salesorders/${salesOrderId}/equipment${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

export const addSalesOrderService = async (payload, { cancelToken } = {}) => {
  const { salesOrderId, odataParams, values } = payload
  const { data = {} } = await HttpClient.post(
    `salesorders/${salesOrderId}/serviceitems${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

export const editSalesOrderEquipment = async (payload, { cancelToken } = {}) => {
  const { id, salesOrderId, odataParams, values } = payload

  const {
    data = {},
  } = await HttpClient.patch(
    `salesorders/${salesOrderId}/equipment/${id}${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

export const editSalesOrderService = async (payload, { cancelToken } = {}) => {
  const { id, salesOrderId, odataParams, values } = payload
  const {
    data = {},
  } = await HttpClient.patch(
    `salesorders/${salesOrderId}/serviceitems/${id}${buildQuery(odataParams)}`,
    values,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

export const deleteSalesOrderEquipment = async (payload, { cancelToken } = {}) => {
  const {
    data = {},
  } = await HttpClient.delete(
    `salesorders/${payload.salesOrderId}/equipment/${payload.id}`,
    payload,
    { cancelToken },
  )
  delete data['@odata.context']
  return data
}

export const deleteSalesOrderService = async (payload, { cancelToken } = {}) => {
  const { data = {} } = await HttpClient.delete(
    `salesorders/${payload.salesOrderId}/serviceitems/${payload.id}`,
    {
      cancelToken,
    },
  )
  delete data['@odata.context']
  return data
}

export const updateSerial = async (payload, { cancelToken } = {}) => {
  const { salesOrderId, salesOrderLineId, serialItemId, values } = payload
  const { data = {} } = await HttpClient.put(
    `salesorders/${salesOrderId}/equipment/${salesOrderLineId}/serials/${serialItemId}`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

export const setSerials = async (payload, { cancelToken } = {}) => {
  const { salesOrderId, salesOrderLineId, values } = payload
  const { data = {} } = await HttpClient.post(
    `salesorders/${salesOrderId}/equipment/${salesOrderLineId}/serials`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

export const returnEquipment = async (payload, { cancelToken } = {}) => {
  const { salesOrderId, salesOrderLineId, values } = payload
  const { data = {} } = await HttpClient.post(
    `salesorders/${salesOrderId}/equipment/${salesOrderLineId}/return`,
    values,
    {
      cancelToken,
    },
  )

  return data
}

const SalesOrderService = {
  setInstallEquipment,
  getInstallEquipment,
  updateSerial,
  setSerials,
  returnEquipment,
  getSalesOrders,
  getSalesOrderList,
}

export default SalesOrderService
